

















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Utils from '../utils'
import DateHelper from '../lib/DateHelper'
import {CheckInOut} from '@/lib/kepler/interfaces'
import {DateTimeBooking} from '@/lib/DateTimeBooking'
import Validations from '../lib/Validations'
import moment from 'moment'
import {State} from 'vuex-class'
import {AppConfigState} from '@/store/modules/configuration'

@Component({
  components: {
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
    InputTimePicker: Utils.loadComponent('proxy/Inputs/InputTimePicker'),
  },
  name: 'CheckInOutPicker',
})

export default class CheckInOutPicker extends Vue {
  @State('configuration') public configState!: AppConfigState
  @State((state) => state.profile.language) public lang!: string

  @Prop({
    default: null,
  }) public readonly editData!: CheckInOut | null

  public checkInOutData: CheckInOut = {
    checkInDate: '',
    checkOutDate: '',
    checkInTime: '',
    checkOutTime: '',
  }

  public valid: boolean = false
  public rules = Validations.rules
  public today: string = DateHelper.now()

  @Watch('checkInOutData', {immediate: true, deep: true})
  public isValid(data?: CheckInOut) {
    if (data) {
      const {checkInDate, checkInTime, checkOutDate, checkOutTime} = data
      if (moment(checkOutDate).isBefore(moment(this.minCheckoutDate))) {
        this.setCheckOutDate(this.minCheckoutDate)
      }
      const filled: boolean = checkInTime !== '' && checkOutTime !== ''
      if (filled) {
        this.valid = true
        this.$emit('valid', {
          start: moment(`${checkInDate} ${checkInTime}`, 'YYYY-MM-DD LT').unix(),
          end: moment(`${checkOutDate} ${data.checkOutTime}`, 'YYYY-MM-DD LT').unix(),
        })
      } else {
        this.valid = false
        this.$emit('invalid', {
          start: '',
          end: '',
        })
      }
    }
  }

  public get step() {
    const conf = this.configState.appConfig.time_slot_dimension
    return conf ? conf : 30
  }

  public allowedStep(m: number): boolean {
    return m % this.step === 0
  }

  public setCheckInDate(date: string) {
    this.$set(this.checkInOutData, 'checkInDate', date)
    if (this.minCheckinTime) {
      this.setCheckInTime(this.minCheckinTime)
    }
  }

  public setCheckInTime(time: string) {
    this.$set(this.checkInOutData, 'checkInTime', time)
    const checkOut = new DateTimeBooking()
    if (this.checkInOutData.checkInDate) {
      checkOut.setDate(`${this.checkInOutData.checkInDate} ${this.checkInOutData.checkInTime}`)
      checkOut.getDate().add(this.configState.appConfig.time_slot_dimension, 'minutes').format('LT')
      // fill checkout if empty
      if ((this.checkInOutData.checkOutDate === '' && this.checkInOutData.checkOutTime === '') || this.checkInOutData.checkInTime === '23:30') {
        this.setCheckOutDate(checkOut.getDate().format('YYYY-MM-DD'))
        this.setCheckOutTime(checkOut.getDate().format('LT'))
      }
    }
  }

  public setCheckOutDate(date: string) {
    Vue.set(this.checkInOutData, 'checkOutDate', date)
  }

  public setCheckOutTime(time: string) {
    Vue.set(this.checkInOutData, 'checkOutTime', time)
  }

  public get timeSlotsCheckIn() {
    const timeSlot = this.configState.appConfig.time_slot_dimension
    const arr: Array<{ text: string, value: string }> = [] = []
    const now = this.minCheckinTime ? moment(this.minCheckinTime, 'LT') : moment().startOf('day')
    const end = moment().endOf('day')
    const t = this.minCheckinTime ? moment(this.minCheckinTime, 'LT').startOf('hour') : moment().startOf('day')

    while (t.isBefore(end)) {
      if (moment.duration(now.diff(t)).asMinutes() > timeSlot) {
        t.add(timeSlot, 'm')
        continue
      }
      arr.push({
        text: t.format('LT'),
        value: t.format('HH:mm:ss'),
      })
      t.add(timeSlot, 'm')
    }
    return arr
  }

  public get timeSlotsCheckOut() {
    const timeSlot = this.configState.appConfig.time_slot_dimension
    const arr: Array<{ text: string, value: string }> = []
    const now = this.minCheckoutTime ? moment(this.minCheckoutTime, 'LT') : moment().startOf('day')
    const t = this.minCheckoutTime ? moment(this.minCheckoutTime, 'LT') : moment().startOf('day')
    const end = moment().endOf('day')

    while (t.isBefore(end)) {
      if (moment.duration(now.diff(t)).asMinutes() > timeSlot) {
        t.add(timeSlot, 'm')
        continue
      }
      arr.push({
        text: t.format('LT'),
        value: t.format('HH:mm:ss'),
      })
      t.add(timeSlot, 'm')
    }
    return arr
  }

  public get minCheckoutDate() {
    const lastSlot = moment('24:00', 'HH:mm').subtract(this.step, 'minutes')
    if (this.checkInOutData.checkInTime === lastSlot.format('LT')) {
      return moment(this.checkInOutData.checkInDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
    }
    return this.checkInOutData.checkInDate
  }

  public get minCheckinTime() {
    if (this.checkInOutData.checkInDate === this.today) {
      const n = moment()
      const m = n.minutes()
      const rest = m % this.step
      if (m < this.step) {
        return n.minutes(0).format('LT')
      } else {
        return n.subtract(rest, 'minutes').format('LT')
      }
    }
  }

  public get minCheckoutTime() {
    const {checkInTime, checkInDate, checkOutDate} = this.checkInOutData
    const t = moment(checkInTime, ['YYYY-MM-DD', 'LT'])
    if (checkInDate === checkOutDate) {
      return t.add(this.step, 'minutes').format('LT')
    }
  }

  protected created() {
    if (this.editData) {
      this.checkInOutData = structuredClone(Object.freeze(this.editData))
    } else {
      this.setCheckInTime(this.timeSlotsCheckIn[0].value)
      this.setCheckInDate(this.today)
      this.setCheckOutTime(this.timeSlotsCheckOut[0].value)
    }
  }
}
