





















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VTextField} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Locate from '@/lib/location'
import {Pos} from '@/lib/kepler/interfaces'
import NMapMarker from '@/lib/n-maps/src/NMapMarker.vue'
import {ProviderInstance} from 'vee-validate/dist/types/types'
import GradientCard from '@/components/GradientCard.vue'

@Component({
  components: {GradientCard, NMapMarker, VTextField, ValidationProvider},
  name: 'Location',
})
export default class TextFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | { [rule: string]: string }

  @Prop({
    type: Array,
    default: () => [],
  }) public readonly options!: string[]

  @Prop({
    type: Object || null,
    default: () => null,
  }) public readonly value!: Pos | null

  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  @Prop() public name?: string
  @Prop() public description?: string

  public innerValue: Pos = {lat: 0, lng: 0}

  protected get isZero() {
    return this.innerValue.lat + this.innerValue.lng === 0
  }

  protected get mapRef() {
    return this.$refs.map as any
  }

  @Watch('innerValue', {deep: true})
  public onInnerValueChange(newVal: Pos) {
    this.$emit('input', newVal.lat + newVal.lng ? newVal : null)
  }

  @Watch('value', {deep: true})
  public onValueChange(newVal: Pos | null) {
    this.filterAndSet(newVal)
  }

  protected filterAndSet(p: any) {
    this.$set(this.innerValue, 'lat', p?.lat || 0)
    this.$set(this.innerValue, 'lng', p?.lng || 0)
  }

  protected created() {
    if (this.value && typeof this.value === 'object') {
      this.filterAndSet(this.value)
    } else {
      Locate.please_locate((pos) => {
        if (pos) {
          this.filterAndSet(pos)
          this.panMap()
        }
      })
    }
  }

  protected setPosition(p: Pos) {
    const validator = this.$refs.validator as ProviderInstance | undefined
    validator?.validate(p).then(() => {
      this.filterAndSet(p)
      this.panMap(p)
    })
  }

  protected panMap(p?: Pos) {
    this.mapRef?.pan(p || this.innerValue)
  }

  protected reset() {
    this.filterAndSet(null)
  }

  protected hasOption(opt: string) {
    return !!this.options?.includes(opt)
  }
}
