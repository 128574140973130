










































import {Vue, Component} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Validations from '../lib/Validations'
import Button from './Button.vue'
import ConfirmDialogCallback from '../views/ConfirmDialogCallback.vue'
import {InviteRequest, Dialog} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Divider: Utils.loadComponent('proxy/Divider'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Img: Utils.loadComponent('proxy/Image'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'addDriver',
})
export default class AddDriver extends Vue {
  @Action('inviteDriver') protected inviteDriver: any
  @Action('openDialog') protected openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') protected closeDialog!: (index?: number) => void

  protected rules: any = Validations.rules
  protected valid: boolean = false
  protected QR: string | null = null
  protected helpOpen: null | 0 = null
  protected inviteRequest: InviteRequest = {
    name: '',
    surname: '',
    email: '',
  }
  protected qrloading: boolean = true
  protected loading: boolean = false

  protected created() {
    this.getQR().finally(() => {
      this.qrloading = false
    })
  }

  protected getToken(): Promise<string|null> {
    return sdk.profile.get_client_token().then((r) => r.data.client_token).catch(() => null)
  }

  protected async getQR() {
    const client_token = await this.getToken()
    if (client_token) {
      sdk.profile.get_qr({client_token}).then((rq) => {
        this.QR = 'data:image/svg+xml;base64,' + btoa(rq.data)
      })
    } else {
      this.closeDialog(0)
    }
  }

  protected save() {
    if (this.valid) {
      this.loading = true
      this.inviteDriver(this.inviteRequest).then(this.confirmInvite).finally(() => {
        this.loading = false
      })
    }
  }

  protected confirmInvite() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$t('common.great'),
      subtitle: this.$t('profile.invite_sent'),
      confirmText: this.$t('profile.wallet.addCard.confirmation.button'),
      imageState: 'icons/invitedriver.svg',
      singleAction: true,
      confirmCallback: this.closeDialog,
    }))
  }
}
