












import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'

import Container from '../components/proxy/Container.vue'
import Layout from '../components/proxy/Layout.vue'
import Flex from '../components/proxy/Flex.vue'
import Card from '../components/proxy/Card/Card.vue'
import Button from '../components/Button.vue'
import Img from '@/components/proxy/Image.vue'

@Component({
  name: 'ErrorDialog',
  components: {
    Img,
    Flex,
    Layout,
    Container,
    Card,
    Button,
  },
})
export default class ErrorView extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop({default: 'error.svg'}) private image?: string
  @Prop({default: '-TITLE-'}) private title!: string
  @Prop({default: '-SUBTITLE-'}) private subtitle!: string
}
