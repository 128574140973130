




































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'ConfirmDialogCallback',
})
export default class ConfirmDialogCallback extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  public loading: boolean = false

  @Prop({type: Function}) protected cancelCallback!: () => void
  @Prop({type: Function}) protected confirmCallback!: (r?: any) => void

  @Prop({type: Boolean, default: true}) private showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) private emitConfirm!: boolean
  @Prop({type: Boolean, default: true}) private closeOnCallback!: boolean

  @Prop({type: String, default: ''}) private imageState!: string
  @Prop({type: String, default: ''}) private code!: string
  @Prop({type: String, default: '-TITLE-'}) private title!: string
  @Prop({type: String, default: '-SUBTITLE-'}) private subtitle!: string

  @Prop({type: String}) private confirmText!: string
  @Prop({type: String}) private confirmLabel!: string
  @Prop({type: String, default: 'success'}) private confirmColor!: string
  @Prop({type: String}) private cancelText!: string
  @Prop({type: String}) private cancelLabel!: string
  @Prop({type: String, default: 'buttonDefaultColor'}) private cancelColor!: string

  @Prop({type: Boolean, default: false}) private bigassConfirm!: boolean

  public get imageType(): string {
    return this.imageState.startsWith('http') ? this.imageState : `img/${this.imageState}`
  }

  public callAndClose(callback?: () => void) {
    if (!this.loading) {
      this.loading = true
      if (this.closeOnCallback) {
        this.loading = false
        this.closeDialog()
      }
      if (callback !== undefined) {
        callback()
      }
    }
  }
}
