import DateHelper from '@/lib/DateHelper'
import moment from 'moment'


export class DateTimeBooking {
  private date!: moment.Moment

  public getDate() {
    return this.date
  }

  public setDate(date: string) {
    this.date = DateHelper.parse(date, ['YYYY-MM-DD HH:mm', 'L LT', 'L'])
  }

  public getDateTimeSlots(setNowTime: boolean = true): string[] {
    const slots: string[] = []

    if (!this.date) {
      return slots
    }

    const now = DateHelper.parse(DateHelper.now('YYYY-MM-DD HH:mm'))
    if (DateHelper.equalsToday(this.date) && setNowTime) {
      this.date.hours(now.hours())
      DateHelper.setToHalfPastOrToOClock(this.date)
    }

    for (let i: number = 0; i < 48; i++) {
      const minutesStart: string = ('00' + this.date.minutes()).substr(-2, 2)
      const hoursStart: string = ('00' + this.date.hours()).substr(-2, 2)

      const slot = `${hoursStart}:${minutesStart}`
      slots.push(slot)

      this.setNextSlot()

      if (slot === '23:30') {
        break
      }
    }
    return slots
  }

  public setNextSlot() {
    this.date.minutes(this.date.minutes() + 30)
  }

  public isSameOrAfter(date: string) {
    return this.date.isSameOrAfter(date)
  }

  public static toUnix(date: string) {
    return parseInt(DateHelper.formatDate(date, 'X'))
  }
}
