






































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'

@Component({
  components: {
    TextArea: Utils.loadComponent('proxy/Inputs/TextArea'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'TextareaDialog',
})
export default class TextareaDialog extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop({type: Function}) protected cancelCallback!: () => void
  @Prop({type: Function}) protected confirmCallback!: (text: string) => void

  @Prop({type: Boolean, default: true}) private showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) private closeOnCallback!: boolean

  @Prop({type: String, default: ''}) private image!: string
  @Prop({type: String, default: ''}) private code!: string
  @Prop({type: String, default: '-TITLE-'}) private title!: string
  @Prop({type: String, default: '-SUBTITLE-'}) private subtitle!: string

  @Prop({type: String}) private confirmText!: string
  @Prop({type: String}) private confirmLabel!: string
  @Prop({type: String}) private confirmColor!: string
  @Prop({type: String}) private cancelText!: string
  @Prop({type: String}) private cancelLabel!: string
  @Prop({type: String}) private cancelColor!: string

  @Prop({type: String, default: ''}) private placeholder!: string
  @Prop({type: Boolean, default: false}) private required!: boolean
  @Prop({type: Boolean, default: false}) private autofocus!: boolean

  @Prop({type: Boolean, default: false}) private bigassConfirm!: boolean

  private text: string = ''
  private loading: boolean = false

  public get imageType(): string {
    return this.image.startsWith('http') ? this.image : `img/${this.image}`
  }

  public callAndClose(callback?: (text?: string) => void) {
    this.loading = true
    if (this.closeOnCallback) {
      this.closeDialog()
    }
    if (callback !== undefined) {
      callback(this.text)
    }
  }
}
