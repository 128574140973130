










































import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {Invoice} from '@/lib/kepler/interfaces'
import CustomIcon from '@/components/CustomIcon.vue'
import DateHelper from '@/lib/DateHelper'

@Component({
  components: {
    CustomIcon,

    Button: Utils.loadComponent('Button'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Container: Utils.loadComponent('proxy/Container'),
  },
  name: 'InvoiceDetail',
})
export default class InvoiceDetail extends Vue {
  @Prop() public invoice!: any

  public openInvoice(invoiceItem: Invoice) {
    cordova.InAppBrowser.open(invoiceItem.url, '_system')
  }

  private dateFormat(date: string) {
    return DateHelper.formatDate(date, 'L')
  }
}
