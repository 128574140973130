






















import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action} from 'vuex-class'
import Validations from '@/lib/Validations'

import PhoneVerificationView from '@/views/PhoneVerification.vue'
import {Dialog, LoginByPhoneCodeRequest} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
  },
  name: 'LoginByPhone',
})
export default class LoginByPhone extends Vue {
  @Action('requestLoginByPhone') public requestLoginByPhone!: (payload: LoginByPhoneCodeRequest) => Promise<any>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  public rules = Validations.rules
  protected formValid: boolean = false
  protected loading: boolean = false
  protected phone: string = ''

  protected verifyPhone() {
    this.loading = true
    this.requestLoginByPhone({mobile_number: this.phone}).then(() => {
      this.openDialog(new Dialog(PhoneVerificationView, {mobileNumber: this.phone}))
      this.loading = false
    }, () => {
      this.loading = false
    })
  }
}
