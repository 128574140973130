



























































import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import {SubscriptionsState} from '@/store/modules/subscriptions'
import {Popup, Dialog, Rate, Subscription, SubscriptionPlan, FieldConfigs} from '@/lib/kepler/interfaces'
import collect from 'collect.js'
import sdk from '../lib/kepler/sdk'
import {ProfileState} from '@/store/modules/profile'

import ConfirmDialogCallback from './ConfirmDialogCallback.vue'
import PlanTermsOfServiceDialog from '@/components/subscriptions/PlanTermsOfService.vue'
import DateHelper from '../lib/DateHelper'
import VuetifyColorHelper, {PlanColorMixin} from '@/lib/vuetify/VuetifyColorHelper'
import moment from 'moment'
import Utils from '@/utils'
import ServiceMesh from '@/lib/serviceMesh'
import {AppConfigState} from '@/store/modules/configuration'

@Component({
  components: {
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Btn: Utils.loadComponent('proxy/Btn'),
    PlanDetailRates: Utils.loadView('PlanDetailRates'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Button: Utils.loadComponent('Button'),
    PlanTermsOfService: Utils.loadComponent('subscriptions/PlanTermsOfService'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  mixins: [PlanColorMixin],
})
export default class PlanDetail extends mixins<PlanColorMixin>(PlanColorMixin) {
  @State('subscriptions') public subscriptionsState!: SubscriptionsState
  @State('profile') public profileState!: ProfileState
  @State('configuration') public configState!: AppConfigState

  @Getter('isLogged') public isLogged!: boolean
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>
  @Action('getRates') public getRates: any
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>
  @Action('subscribe') public subscribe: any
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('autorenew') public autorenew: any

  @Prop() public planProp?: SubscriptionPlan
  @Prop({type: Boolean, default: false}) public hideCta!: boolean

  public planTocs: any = null
  public plan: SubscriptionPlan | null = null
  public alreadySubscribed: boolean = false
  public tocsApproved: boolean = false
  public selectedMode: number = 0
  protected planCSS: string | null = null

  public get durationRecursive() {
    const obj: { [key: number]: string } = {
      7: 'activities.plans.card.duration.weekly',
      30: 'activities.plans.card.duration.monthly',
      60: 'activities.plans.card.duration.two-monthly',
      90: 'activities.plans.card.duration.quarterly',
      365: 'activities.plans.card.duration.annual',
    }
    if (this.plan) {
      const d = this.plan.duration

      if (obj.hasOwnProperty(d)) {
        return this.$t(obj[d])
      } else {
        if (d > 3000) {
          return this.$t('activities.plans.card.duration.unlimited')
        } else if (!(d % 30)) {
          return this.$t('activities.plans.card.duration.months', {n: d / 30})
        } else {
          return this.humanizeDuration(this.plan.duration, 'days')
        }
      }
    }
  }

  public get rates(): Rate[] | null {
    return this.plan?.rates || null
  }

  public get duration() {
    const d = this.plan?.duration
    return d && d < 3000 ? this.humanizeDuration(d, 'days') : null
  }

  public get humanizedBillingCycle() {
    const cases: { [key: number]: string } = {
      1: this.$t('common.time_units.day'),
      7: this.$t('common.time_units.week'),
      30: this.$t('common.time_units.month'),
      60: this.$t('common.time_units.two-month'),
      90: this.$t('common.time_units.quarter'),
      365: this.$t('common.time_units.year'),
    }
    if (this.plan) {
      const d = this.plan.billing_cycle

      // noinspection IfStatementWithTooManyBranchesJS
      if (cases.hasOwnProperty(d)) {
        return cases[d]
      } else if (!(d % 30)) {
        return this.$t('common.time_units.months', {n: d / 30})
      } else if (!(d % 7)) {
        return this.$t('common.time_units.weeks', {n: d / 7})
      } else {
        return this.$t('common.time_units.days', {n: d})
      }
    }
  }

  public get payCycle() {
    switch (this.plan?.billing_cycle) {
      case 30:
        return this.$t('common.calendar.monthly')
      case 365:
        return this.$t('common.time_units.yearly')
      default:
        return ''
    }
  }

  public get planVehicleTypes() {
    if (this.rates) {
      const t: any[] = []
      for (const rate of this.rates) {
        const type = rate.vehicle_category.type
        if (!t.includes(type)) {
          t.push(type)
        }
      }
      return t
    }
    return null
  }

  public get isDefault() {
    return this.configState.appConfig?.default_plan === this.plan?.id
  }

  public get bookingModes() {
    const rates = this.plan?.rates || []
    const bmBag: string[] = []
    for (const r of rates) {
      const bmArr = r.booking_mode.split(',')
      bmArr.forEach((bm) => {
        if (!bmBag.includes(bm)) {
          bmBag.push(bm)
        }
      })
    }
    return bmBag.reverse()
  }

  public get planAlert() {
    const obj = {notice: this.plan?.notice, color: 'transparent'}
    if (obj.notice) {
      const arr = obj.notice.split('|')
      obj.notice = arr[0]
      if (arr[1]) {
        obj.color = VuetifyColorHelper.color(arr[1])
      }
    }
    return obj
  }

  protected get hideFree() {
    return !!this.fieldConfigs?.hide_free_plan_string
  }

  protected get planColors() {
    return this.plan ? this.getPlanColors(this.plan, this.isDefault) : null
  }

  public humanizeDuration(val: number, unit: any) {
    return DateHelper.humanizeDuration(val, unit)
  }

  public subscribePlan() {
    const confirmAndClose = (s?: Subscription, renew?: boolean) => {

      const changePage = () => {
        this.getSubscriptions().then(() => {
          this.$router.push({name: 'my subscriptions'})
        })
      }

      if (s && renew === false) {
        this.autorenew({subscription_id: s.id, status: renew}).then(changePage)
      } else {
        changePage()
      }
    }

    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$t('subscription.confirm.title'),
      subtitle: this.$t('subscription.confirm.subtitle', {
        name: this.plan ? this.plan.name : '',
        cost: this.plan ? this.$currency(this.plan.cost) : '',
        duration: this.duration ? this.duration : '',
        cycle: this.payCycle,
      }),
      confirmText: this.$t('subscription.confirm.button_affirmative'),
      cancelText: this.$t('subscription.confirm.button_negative'),
      imageState: this.$t('subscription.confirm.image_url'),
      singleAction: false,
      cancelCallback: () => {
        return 1
      },
      confirmCallback: () => {
        this.subscribe((this.plan as any).id).then((s: Subscription) => {
          this.closeDialog(0)
          if (s.plan.renewable === true || s.plan.renewable === undefined) {
            this.openDialog(new Dialog(ConfirmDialogCallback, {
              code: '',
              title: this.$t('subscription.confirmed.title'),
              subtitle: this.$t('subscription.confirmed.subtitle'),
              confirmText: this.$t('subscription.confirmed.button_affirmative'),
              imageState: this.$t('subscription.confirmed.image_url'),
              singleAction: true,
              emitConfirm: true,
              showCloseButton: false,
              confirmCallback: () => {
                this.openDialog(new Dialog(ConfirmDialogCallback, {
                  code: '',
                  title: this.$t('subscription.autorenew.title'),
                  subtitle: this.$t('subscription.autorenew.subtitle'),
                  confirmText: this.$t('subscription.autorenew.button_affirmative'),
                  cancelText: this.$t('subscription.autorenew.button_negative'),
                  imageState: this.$t('subscription.autorenew.image_url'),
                  singleAction: false,
                  emitConfirm: true,
                  showCloseButton: false,
                  confirmCallback: confirmAndClose,
                  cancelCallback: () => {
                    confirmAndClose(s, false)
                  },
                }))
              },
            }))
          } else {
            confirmAndClose()
          }
        })
      },
    }))
  }

  public checkPlanTos() {
    if (!this.tocsApproved) {
      this.openDialog(new Dialog(PlanTermsOfServiceDialog, {
        confirmCallback: () => {
          this.tocsApproved = true
          this.alreadyHasPlanCheck()
        },
        tocs: this.planTocs,
        plan: this.plan,
      }))
      return false
    }
    this.alreadyHasPlanCheck()
  }

  public alreadyHasPlanCheck() {
    if (this.alreadySubscribed) {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        code: '',
        title: this.$t('subscription.already_have_a_subscription_title'),
        subtitle: this.$t('subscription.already_have_a_subscription'),
        confirmText: '',
        imageState: null,
        confirmCallback: () => {
          this.subscribePlan()
        },
        cancelCallback: () => {
          return
        },
      }))
    } else {
      this.subscribePlan()
    }
  }

  public isSubscribed() {
    if (sdk.people.isLogged()) {
      (this.profileState as any).subscriptions.forEach((subscription: Subscription) => {
        const now = moment().unix()
        const planEnd = subscription.end_timestamp
        if (subscription.plan.id === this.$route.params.id && now < planEnd) {
          this.alreadySubscribed = true
        }
      })
    }
  }

  public logIn() {
    this.$router.push({name: 'login'})
  }

  public serviceColor(mode: string) {
    return ServiceMesh.colors[mode + 'CAR']
  }

  protected init(plans: SubscriptionPlan[]) {
    if ((this.profileState as any).subscriptions === null && sdk.people.isLogged()) {
      this.getSubscriptions()
    }
    this.isSubscribed()

    if (!this.planProp) {
      const c = collect(plans)
      const id = this.$route.params.id
      this.plan = c.firstWhere('id', id)
    } else {
      this.plan = this.planProp
    }

    if (this.plan?.toc_id && sdk.people.isLogged()) {
      sdk.subscription.planTocs(this.plan.toc_id).then((data) => {
        this.planTocs = data.data
      })
      this.$route.meta.topbar.title = this.plan.name
    }
  }

  protected created() {
    if (!this.subscriptionsState.plans?.length) {
      this.getPlans().then(this.init)
    } else {
      this.init(this.subscriptionsState.plans)
    }
  }

  protected mounted() {
    this.planCSS = this.getPlanCSS(this.isDefault)
  }
}
