








  import {Component, Prop, Vue} from 'vue-property-decorator'
  import {Action, State} from 'vuex-class'
  import {
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VContainer,
    VDialog,
    VDivider,
    VLayout,
    VTabItem,
    VTabsItems,
  } from 'vuetify/lib'
  import {Document, DocumentState, FlowInputField, FlowInputStep, Dialog} from '@/lib/kepler/interfaces'
  import {DriverLicense} from '@/lib/kepler/RegistrationRequest'
  import {FlowInputsState} from '@/store/modules/flowInputs'
  import Utils from '@/utils'
  import RegistrationDocumentAddDialog from '@/components/registration/RegistrationDocumentAddDialog.vue'

  @Component({
    components: {
      RegistrationDocumentAddComponent: Utils.loadComponent('registration/RegistrationDocumentAddComponent'),
      CustomIcon: Utils.loadComponent('CustomIcon'),
      VContainer,
      VDialog,
      VCardText,
      VCardTitle,
      VCard,
      VCardActions,
      VLayout,
      VDivider,
      VTabsItems,
      VTabItem,
      VBtn,
    },
    name: 'RegistrationDocumentsComponent',
  })
  export default class RegistrationDocumentsComponent extends Vue {
    @State('flowInputs') public flowInputs!: FlowInputsState
    @State('flowOutputs') public flowOutputs!: { [k: string]: any }
    @State((state) => state.profile?.documents) public documentState?: Document[]

    @Action('deleteDocument') public deleteDocument!: (id: string) => void
    @Action('deleteFlowOutputProp') public deleteFlowOutputProp!: (path: string[]) => void
    @Action('addDocument') public addDocument!: (id: DriverLicense) => Promise<Document>
    @Action('openDialog') public openDialog!: (dialog: Dialog) => void
@Action('closeDialog') public closeDialog!: (index?: number) => void

    @Prop({}) public field!: FlowInputField
    @Prop({type: String || null}) public flowName!: string | null

    protected docs: FlowInputStep[] = []
    protected completedDocs: string[] = []
    protected validDocs: string[] = []

    protected openAddDialog(doc: FlowInputStep) {
      const flowName = this.flowName
      this.openDialog(new Dialog(RegistrationDocumentAddDialog, {
        doc,
        flowName,
        cancelCallback: () => {
          this.closeAndDeleteDoc(doc.id)
        },
        successCallback: () => {
          this.closeAndCompleteDoc(doc.id)
        },
      }))
    }

    protected created() {
      this.filterDocs()
      this.docs = this.flowInputs.documents?.steps
      const docState = this.documentState
      if (docState) {
        docState.forEach((doc) => {
          const typeId = doc.type.id
          if (doc.status === DocumentState.UNVERIFIED && !this.completedDocs.includes(typeId)) {
            this.completedDocs.push(typeId)
          } else if (doc.status === DocumentState.VALID && !this.validDocs.includes(typeId)) {
            this.validDocs.push(typeId)
          }
        })
      }
    }

    protected filterDocs() {
      let id

      if (this.field) {
        let r = this.field.validation

        const splitPipes = (s: string) => {
          const obj: { [k: string]: string | boolean } = {}
          s.split('|').forEach((v) => {
            const kv = v.split(':')
            obj[kv[0]] = kv[1] || true
          })
          return obj
        }

        if (r === null) {
          return null
        }

        if (typeof r === 'string') {
          r = splitPipes(r)
        }

        if (typeof r === 'object') {
          if (r.hasOwnProperty('id')) {
            id = r.id
          }
        }

        if (id) {
          const ids = id.split(',')
          this.docs = this.docs.filter((doc) => {
            return ids.includes(doc.id)
          })
        }

        return id ? id.split(',') : null
      }
    }

    protected closeAndDeleteDoc(id: string) {
      this.closeDialog()
      if (this.flowName && this.flowOutputs[this.flowName!].documents?.[id]) {
        this.deleteFlowOutputProp([this.flowName, 'documents', id])
      }
      if (this.isComplete(id)) {
        Vue.set(this, 'completedDocs', this.completedDocs.filter((i) => i !== id))
      }
    }

    protected closeAndCompleteDoc(id: string) {
      if (!this.isComplete(id)) {
        // Cannot mutate token
        const ogDoc = this.flowOutputs[this.flowName!].documents[id]
        const document = JSON.parse(JSON.stringify(ogDoc))
        for (const prop in document.attachments) {
          if (document.attachments[prop].token) {
            document.attachments[prop] = document.attachments[prop].token
          }
        }
        document.document_type_id = id
        this.addDocument(document).then(() => {
          this.completedDocs.push(id)
          delete this.flowOutputs[this.flowName!].documents[id]
          this.closeDialog()
        })
      }
    }

    protected isComplete(id: string) {
      let existing
      if (this.flowName && this.documentState) {
        existing = !!this.documentState.find(
          (d) => d.type.id === id && (d.status === 'VALID' || d.status === 'UNVERIFIED'),
        )
      }

      const documentOutput = this.flowOutputs[this.flowName!]?.documents
      if (!documentOutput) {
        return false
      }
      const found = this.completedDocs.find((doc) => doc.toLowerCase() === id.toLowerCase())

      return (!!found && documentOutput[found]) || existing
    }
  }
