













import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import AddPacket from '@/views/AddPacket.vue'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
  },
  name: 'BuyPackets',
})

export default class BuyPackets extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Prop({type: String, default: () => 'packets'}) public theme!: string

  private openAddPacket() {
    this.openDialog(new Dialog(AddPacket, {}))
  }
}
