





























import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {Popup, Report, ReservationPaginatedResponse} from '@/lib/kepler/interfaces'
import VehicleReport from '../components/entities/vehicle/VehicleReport.vue'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import {BookingState} from '@/store/modules/booking'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    CardList: Utils.loadComponent('CardList'),
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  name: 'Reportings',
})
export default class Reportings extends Vue {
  @State((state) => state.profile.reports) public reports!: Report[]
  @State((state) => state.booking.reservations) public reservations!: BookingState // unused and wrong?

  @Action('openPopup') protected openPopup!: (popup: Popup) => void
  @Action('getReports') protected getreports!: () => Promise<Report[]>
  @Action('reservationHistory') protected reservationHistory!: () => Promise<ReservationPaginatedResponse>

  protected show: string[] = []
  protected hasReservations: boolean = false
  protected loading: boolean = false

  protected get filteredReports() {
    return this.reports.filter((r) => {
      if (this.show.includes(r.status)) {
        return r
      }
    })
  }

  protected get statuses() {
    const arr: string[] = []
    this.reports.forEach((r) => {
      const s = r.status
      if (!arr.includes(s)) {
        arr.push(s)
      }
    })
    return arr
  }

  protected openVehicleReport(report: any) {
    this.openPopup(new Popup(VehicleReport, {report}, this.$t('vehicle.report.diary_report')))
  }

  protected statusFormat(r: Report) {
    const s = r.status
    let color = 'accent'

    switch (s) {
      case 'CONFIRMED':
      case 'RESOLVED':
        color = 'success'
        break
      case 'PENDING_REVIEW':
        color = 'warning'
        break
      case 'VOIDED':
        color = 'grey'
    }

    return {color, text: this.$t(`vehicle.report.status.${s.toLowerCase()}`)}
  }

  protected addReport() {
    this.openPopup(new Popup(VehicleAddReport, {vehicle: null}, this.$t('vehicle.report.diary_report')))
  }

  protected created() {
    this.loading = true
    this.show = this.statuses
    this.getreports().then((reports) => {
      this.show.push(...this.statuses)
      if (!reports.length) {
        this.reservationHistory().then((h) => {
          this.hasReservations = !!h.page_count
          this.loading = false
        })
      } else {
        this.hasReservations = true
        this.loading = false
      }
    })

  }
}
