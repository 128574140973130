



























import {Component, Prop, Vue} from 'vue-property-decorator'
import {
  DamagePosition,
  ReportResponse,
  DamagePoints as Dmps, VehicleSlot, Popup,
} from '../../../lib/kepler/interfaces'
import Chip from '../../proxy/Chip.vue'
import Layout from '../../proxy/Layout.vue'
import Flex from '../../proxy/Flex.vue'
import {Action} from 'vuex-class'
import Container from '../../proxy/Container.vue'
import CardButton from '../../CardButton.vue'
import VehicleReport from './VehicleReport.vue'
import DamagePoints from '../../DamagePoints.vue'
import {EventBus} from '@/main'
import EmptyList from '../../EmptyList.vue'
import Btn from '@/components/proxy/Btn.vue'
import Icon from '@/components/proxy/Icon.vue'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import Card from '@/components/proxy/Card/Card.vue'
import Button from '@/components/Button.vue'
import moment from 'moment'

@Component({
  components: {
    Button,
    Card,
    Icon,
    Btn,
    EmptyList,
    DamagePoints,
    VehicleReport,
    CardButton,
    Container,
    Flex,
    Layout,
    Chip,
  },
  name: 'VehicleStatus',
})
export default class VehicleStatus extends Vue {
  @Prop() private vehicleSlot!: VehicleSlot
  @Prop({
    type: String,
    default: () => 'accent',
  }) private plusColor!: string
  @Action('diaryReports') private diaryReports!: any
  @Action('openPopup') private openPopup!: (popup: Popup) => void

  private reports: any[] = []
  private carOutline!: any
  private damagePoints: Dmps[] | undefined = []

  get imgOutline() {
    return this.vehicle.category.damage_picture
  }

  private get vehicle() {
    return this.vehicleSlot.vehicle
  }

  protected mounted() {
    EventBus.$on('reportAdded', this.loadReports)
    this.loadReports()
  }

  protected destroyed() {
    EventBus.$off('reportAdded', this.loadReports)
  }

  protected addReport() {
    this.openPopup(new Popup(VehicleAddReport,
      {
        vehicleSlot: this.vehicleSlot,
        vehicle: this.vehicle,
        reservationId: null,
      },
      this.$t('vehicle.report.diary_report')))
  }

  protected loadReports() {
    this.diaryReports(this.vehicle.id).then((r: any) => {
      this.reports = r.data
    })
  }

  protected imageLoaded() {
    this.carOutline = this.$refs.carimg
    this.getDamagePoints()
  }

  protected openVehicleReport(report: any) {
    this.openPopup(new Popup(VehicleReport, {report}, this.$t('vehicle.report.diary_report')))

  }

  protected format(time: string) {
    return moment(time).format('L LT')
  }

  private getDamagePoints() {
    const carImg = this.$refs.carimg as Element
    if (carImg) {
      this.reports.forEach((item: ReportResponse) => {
        if (item.damage_position_raw) {
          item.damage_position_raw.forEach((dmg: DamagePosition) => {
            this.damagePoints!.push({
              title: item.id,
              left: ((dmg.left) * carImg.clientWidth) / dmg.containerWidth,
              top: ((dmg.top) * carImg.clientHeight) / dmg.containerHeight,
            })
          })
        }
      })
    }
  }
}
