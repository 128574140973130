



































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Document, FieldConfigs, Popup} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import AddDocumentView from '@/components/registration/RegistrationDrivingLicenseStep.vue'
import DateHelper from '@/lib/DateHelper'

@Component({
  name: 'Documents',
  components: {
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Container: Utils.loadComponent('proxy/Container'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    CardGrid: Utils.loadComponent('CardGrid'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Ribbon: Utils.loadComponent('Ribbon'),
    Flex: Utils.loadComponent('proxy/Flex'),
  },
})
export default class Documents extends Vue {
  @State((state) => state.profile.documents) public documentsState!: Document[]
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Action('openPopup') private openPopup!: (popup: Popup) => void
  @Action('getDocuments') private getDocuments!: () => void
  @Action('getLicenseTypes') private getLicenseTypes!: () => Promise<void>
  @Prop({}) private documents!: Document[]

  private showActiveOnly: boolean = true
  private loading: boolean = false

  private get docs() {
    const d = this.documents ? this.documents : this.documentsState
    if (this.showActiveOnly) {
      return d.filter((doc) => {
        return doc.status === 'VALID'
      })
    }
    return d
  }

  private get hasInactiveDocs() {
    return Object.keys(this.docs.filter((doc) => {
      return doc.status === 'VALID'
    })) !== Object.keys(this.docs)
  }

  protected created() {
    this.getDocuments()
    if (!this.docs.length && this.documentsState.length) {
      this.showActiveOnly = false
    }
  }

  private fieldHidden(field: string) {
    if (!this.fieldConfigs) {
      return false
    }
    const fieldPropsString = Utils.getProp(this.fieldConfigs, field.split('.')) as string || ''
    return fieldPropsString.includes('hidden')
  }

  @Watch('docs', {immediate: true})
  private documentsPresence(val: Document[]) {
    this.$emit('noDocuments', !val.length)
  }

  private dateFormat(date: string) {
    return DateHelper.formatDate(date, 'L')
  }

  private addDocument() {
    this.loading = true
    this.getLicenseTypes().then(() => {
      this.loading = false
      this.openPopup(
        new Popup(AddDocumentView, null,
          this.$t('registration.documents')))
    })
  }

  private get documentMessages() {
    const obj: { [id: string]: Array<{ label: string, value: string }> } = {}
    this.docs.forEach((doc) => {
      obj[doc.id] = []

      const fields = [
        {
          name: 'driver_license.number',
          cond: doc.number,
          string: {label: this.$t('profile.documents.document_number'), value: doc.number || ''},
        },
        {
          name: 'driver_license.issued_by',
          cond: doc.issuer.by,
          string: {label: this.$t('profile.documents.issued_by'), value: doc.issuer.by},
        },
        {
          name: 'driver_license.issued_date',
          cond: doc.issuer.date,
          string: {label: this.$t('profile.documents.issuing_date'), value: this.dateFormat(doc.issuer.date)},
        },
        {
          name: 'driver_license.expiry',
          cond: doc.expiry,
          string: {label: this.$t('profile.documents.expiration'), value: this.dateFormat(doc.expiry)},
        },
      ]

      const arr = obj[doc.id]

      fields.forEach((field) => {
        if (!this.fieldHidden(field.name) && field.cond) {
          arr.push(field.string)
        }
      })
    })

    return obj
  }
}
