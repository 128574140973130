


































import {Vue, Component, Prop} from 'vue-property-decorator'
import {FieldConfigs, ReservationResponse} from '@/lib/kepler/interfaces'
import {Getter} from 'vuex-class'
import Utils from '@/utils'
import DateHelper from '@/lib/DateHelper'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
  },
  name: 'TripList',
})
export default class TripList extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Prop({type: Object, required: true}) protected reservation!: ReservationResponse

  protected get fuelLevelHidden() {
    return this.fieldConfigs?.trip?.fuelHidden
  }

  protected formattedTime(date: string) {
    return DateHelper.formatDate(date, 'L LT')
  }
}
