



















































































import {Component, Vue} from 'vue-property-decorator'
import ServiceMesh from '../lib/serviceMesh'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'

import FFVehicleBook from './Vehicle/FFVehicleBook.vue'
import {BookingMode, FieldConfigs, Popup, VehicleType} from '@/lib/kepler/interfaces'
import RTVehicleSearch from '@/views/VehicleSearch.vue'
import CorporateVehicleSearch from '@/views/CorporateVehicleSearch.vue'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    Container: Utils.loadComponent('proxy/Container'),
    CardList: Utils.loadComponent('CardList'),
    ListTileAvatar: Utils.loadComponent('proxy/List/ListTileAvatar'),
    FFVehicleBook,
  },
})

export default class BookingActivities extends Vue {
  @State((state) => state.booking.meshes) public meshes!: ServiceMesh[]
  @State((state) => state.profile.client?.client_type.type) public clientType!: string
  @State((state) => !!state.configuration.appConfig.enable_booking_request) public bookingRequestEnabled?: boolean
  @State((state) => !state.configuration.appConfig.disable_rent_flow) public rentEnabled?: boolean
  @State((state) => !!state.flowInputs?.driver_booking_request) public hasBookingRequestFlow!: boolean
  @State((state) => !!state.flowInputs?.rent) public hasRentFlow!: boolean

  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Action('openPopup') public openPopup!: (popup: Popup) => void

  protected filter: string = ''

  protected get mode() {
    return this.$route.params.mode
  }

  protected get type() {
    return this.$route.params.type
  }

  protected get bookingComponent() {
    if (this.$route.path.startsWith('/activities/booking')) {
      this.$route.meta.topbar = {
        title: 'activities.booking.route_title',
      }
    }
    if (this.mode && this.type) {
      this.$route.meta.topbar = {
        title: 'vehicle.search_for_vehicle',
      }
      const mode = this.mode.toUpperCase()
      switch (mode) {
        case 'RT':
          return RTVehicleSearch
        case 'CRT':
          return CorporateVehicleSearch
        case 'FF':
          return FFVehicleBook
        case 'OWFF':
          return FFVehicleBook
        default:
          return null
      }
    }
  }

  protected get vehicleTypeList() {
    return this.uniqueMeshMap((m) => m.vehicle_type.toLowerCase())
  }
  protected get bookingModeList() {
    return this.uniqueMeshMap((m) => m.booking_mode.toLowerCase())
  }

  protected get onlyOne() {
    const bm = this.bookingModeList.length
    const vt = this.vehicleTypeList.length
    return (bm + vt) <= 2
  }

  protected get filteredVehicleList() {
    let arr = Array.from(this.meshes).filter((m) => !this.isHidden(m))

    if (arr.length === 1) {
      return arr
    }
    if (this.type) {
      arr = arr.filter((m) => m.vehicle_type.toLowerCase() === (this.type || ''))
    }
    if (this.mode) {
      arr = arr.filter((m) => m.booking_mode.toLowerCase() === (this.mode || ''))
    }

    return this.mode || this.type ? arr : []
  }

  protected get header() {
    const getTypeHeader = (str: 'title' | 'subtitle') => {
      const typeTitle = this.$isAvailable(`activities.booking.${this.type}.${str}`)
      const typeTitleGeneric = this.$t(`activities.booking.type.${str}`)
      return typeTitle || typeTitleGeneric
    }
    if (this.type) {
      return {
        title: getTypeHeader('title'),
        description: getTypeHeader('subtitle'),
      }
    } else {
      return {
        title: this.$isAvailable('activities.booking.title'),
        description: this.$isAvailable('activities.booking.subtitle') || '',
      }
    }
  }

  protected get isMaintenance() {
    return this.clientType === 'MAINTENANCE'
  }

  protected vehicleModeString(mesh: ServiceMesh) {
    return (mesh.booking_mode + mesh.vehicle_type).toLowerCase()
  }

  protected action(mesh: ServiceMesh) {
    const type = mesh.vehicle_type.toLowerCase()
    const mode = mesh.booking_mode.toLowerCase()
    return this.$router.push({name: 'bookingMode', params: {type, mode}})
  }

  protected color(vehicleType: string) {
    return ServiceMesh.colors[vehicleType.toUpperCase()]
  }

  protected icon(bookingMode: BookingMode, vehicleType: VehicleType) {
    const serviceMesh: ServiceMesh = new ServiceMesh()
    return serviceMesh.getImage(bookingMode, vehicleType, 'OK')
  }

  protected text(mesh: ServiceMesh) {
    const bm = mesh.booking_mode.toLowerCase()
    const vt = mesh.vehicle_type.toLowerCase()
    return {
      action: this.$t(`activities.booking.${vt}.${bm}.action`),
      description: this.$isAvailable(`activities.booking.${vt}.${bm}.description`),
    }
  }

  protected checkIsLight(color: string) {
    return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(color))
  }

  protected uniqueMeshMap(fn: (m: ServiceMesh) => string) {
    return Array.from(new Set(this.meshes.filter((m) => !this.isHidden(m)).map(fn)))
  }

  protected isHidden(s: ServiceMesh) {
    const hiddenConfig = this.fieldConfigs.activities_hidden_booking ?? ''
    const hidden = hiddenConfig.split(',').map((h) => h.toLowerCase())
    return !!hidden?.includes((s.booking_mode + s.vehicle_type).toLowerCase())
  }
}
