















import {Vue, Component, Watch} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {ProfileState} from './store/modules/profile'
import {TimestampsState} from './store/modules/timestamps'
import {EventBus} from './main'
import Utils from './utils'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {
  AppConfig,
  HomeModeEnum,
  ReservationResponse,
  CheckpointRequest,
  CheckPointResponse,
  Popup,
  Dialog,
} from '@/lib/kepler/interfaces'

import {FirebaseHelper} from '@/lib/FirebaseHelper'
import sdk from '@/lib/kepler/sdk'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    AppModals: Utils.loadComponent('AppModals'),
    AppTopBar: Utils.loadComponent('AppTopBar'),
    OfflineAlert: Utils.loadComponent('OfflineAlert'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout: Utils.loadComponent('proxy/Layout'),
  },
})
export default class App extends Vue {
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.booking.activeReservations) public activeReservations!: ReservationResponse[]
  @State('profile') public profileState!: ProfileState
  @State('timestamps') public timestampsState!: TimestampsState

  @Getter('isLogged') public isLogged!: boolean
  @Getter('popupIsOpen') public popupIsOpen!: boolean
  @Getter('fullscreenPopup') public fullscreenPopup?: Popup

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Action('timestamps') public timestamps: any
  @Action('checkpoint') public checkpoint!: (payload: CheckpointRequest) => Promise<CheckPointResponse>

  public online: boolean = true
  protected prefersDark: boolean | null = null

  protected get topBarHidden() {
    return !!this.$route.meta?.topbar?.hidden
  }

  protected get statuses(): { [key: string]: string | undefined } {
    if (this.profileState.client && this.profileState.driver) {
      return {
        client: this.profileState.client.status,
        driver: this.profileState.driver.status,
      }
    } else {
      return {
        client: undefined,
        driver: undefined,
      }
    }
  }

  protected get needsNotchFix() {
    const currentDevice = (window as any).device as Device | undefined
    const deviceKey = currentDevice?.platform + '-' + currentDevice?.model

    // https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-device/
    // https://gist.github.com/adamawolf/3048717

    return [
      'iOS-iPhone10,3', // iPhone X Global
      'iOS-iPhone10,6', // iPhone X GSM
      'iOS-iPhone11,2', // iPhone XS
      'iOS-iPhone11,4', // iPhone XS Max
      'iOS-iPhone11,6', // iPhone XS Max Global
      'iOS-iPhone11,8', // iPhone XR
      'iOS-iPhone12,1', // iPhone 11
      'iOS-iPhone12,3', // iPhone 11 Pro
      'iOS-iPhone12,5', // iPhone 11 Pro Max
      'iOS-x86_64', // iPhone Emulator
    ].includes(deviceKey)
  }

  protected open(link: string) {
    window.open(link, '_self')
  }

  @Watch('statuses', {deep: true, immediate: true})
  protected onStatusChange(statuses: any, oldStatuses: any) {
    const changed = JSON.stringify(statuses) !== JSON.stringify(oldStatuses)
    if (oldStatuses && changed) {
      Object.keys(statuses).forEach((key) => {
        if (oldStatuses[key] !== statuses[key] && oldStatuses[key] && statuses[key]) {
          // console.log(key, oldStatuses[key], '=>', key, statuses[key])
          // TODO: get some nice images
          const status = statuses[key].replace(' ', '_')
          this.openDialog(new Dialog(ConfirmDialog, {
            imageState: status === 'ACTIVE' ? 'success.svg' : null,
            confirmText: this.$t('common.ok'),
            code: '',
            subtitle: this.$t(`profile.notification.${key}.${status}`),
            title: this.$t('profile.edit.profile_updated'),
            singleAction: true,
            emitConfirm: false,
          }))
        }
      })
    }
  }

  protected handleFirebase() {
    FirebaseHelper.run((token) => {
      sdk.profile.setFlag('firebase_token', token)
    }, (title: string, body: string, data: any) => {
      if (data.debug) {
        this.$log(JSON.stringify([title, body, data]), 2)
        alert(JSON.stringify([title, body, data]))
      }

      if (data.tap) {
        if (data.openUrl) {
          cordova.InAppBrowser.open(data.openUrl, '_system')
        }
        if (data.path) {
          this.$router.push({path: data.path})
        }
      }

      if (data.operatorPath) {
        const pushPath = () => this.$router.push({path: data.operatorPath})
        if (data.confirmRequest === false || data.confirmRequest === 'false') {
          pushPath()
        } else {
          this.openDialog(new Dialog(ConfirmDialogCallback, {
            code: '',
            title: this.$t('push.navigation_request.title'),
            subtitle: this.$t('push.navigation_request.subtitle'),
            confirmCallback: pushPath,
          }))
        }
      }
    })
  }

  protected created() {
    EventBus.$on('offline', () => {
      this.online = false
    })
    EventBus.$on('online', () => {
      this.online = true
    })
    if (this.profileState.language) {
      this.$langLoader(this.profileState.language)
    }

    // FEAR OF THE DARK, FEAR OF THE DAAAARK
    this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this.prefersDark = event.matches
      })

    EventBus.$on(['login', 'loginByPhone'], this.handleFirebase)

    if (this.isLogged) {
      // Firebase
      this.handleFirebase()

      // Checkpoint
      const checkpointRequest = {
        device: JSON.parse(JSON.stringify(device)), // device.platform for the device name only
        useragent: navigator.userAgent,
        release: this.$env.RELEASE,
        appversion: this.$env.APP_VERSION_NUMBER.toString(),
        dark: this.prefersDark,
      }
      this.checkpoint(checkpointRequest).then((r) => {
        if (r.status === 'needs-update' || r.status === 'suggest-update') {
          if (this.$route.name !== 'update') {
            this.$router.push({name: 'update', params: {status: r.status}})
          } else {
            this.$route.meta.status = r.status
          }
        }
      })
    }
  }

  protected mounted() {
    EventBus.$on('pinReminder', (pin: string) => {
      this.openDialog(new Dialog(ConfirmDialog, {
        imageState: null,
        code: '',
        title: this.$t('login.pin_reminder.title'),
        subtitle: this.$t('login.pin_reminder.subtitle', {pin}),
        confirmText: this.$t('common.continue'),
        singleAction: true,
      }, null, false, null))
    })

    this.timestamps()
  }

  protected destroyed() {
    EventBus.$off('login')
    EventBus.$off('loginByPhone')
    EventBus.$off('offline')
    EventBus.$off('online')
    window.matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', (event) => {
        this.prefersDark = event.matches
      })
  }
}
