
import {Component, Prop} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import BaseFlow from '@/views/Flows/BaseFlow.vue'
import {Dialog, Popup} from '@/lib/kepler/interfaces'
import FlowDialog from '@/views/Flows/FlowDialog.vue'

@Component({})
export default class FlowAutoload extends BaseFlow {
  @Action('updateEntity') public updateEntity!: (key: string) => Promise<void>
  @Action('updateProfilePicture') public updateProfilePicture!: (url?: string) => string

  @Getter('autoOpenQueue') public autoOpenQueue!: string[]

  @Prop({
    type: String,
  }) public flowName!: string

  public getName(): string {
    return this.flowName
  }

  public exitForward() {
    const goHome = (success: boolean) => {
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'})
      }
      this.closePopup()
      if (success && this.autoOpenQueue.length && this.currentFlow.auto_open > 1) {
        this.openFlow(this.autoOpenQueue[this.currentFlow.auto_open - 1 || 0])
      }
    }

    this.getFlows()
      .then(() => {
        goHome(true)
      })
      .catch(() => {
        goHome(false)
      })
      .finally(() => {
        this.updateEntity('client')
        this.updateEntity('driver')
        this.updateProfilePicture()
      })
    this.purgeFlow(this.flowName)
    this.getPersistent()
  }

  protected openFlow(flowName: string) {
    const flow = this.flowInputs[flowName]
    if (flow && flow.steps.length) {
      if (flow.dialog) {
        this.openDialog(new Dialog(FlowDialog, {flowName}, null, true))
      } else {
        this.openPopup(new Popup(FlowAutoload, {flowName}, null, true))
      }
    }
  }

  private get currentFlow() {
    return this.flowInputs[this.flowName]
  }
}
