














import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {Getter} from 'vuex-class'
import {FieldConfigs} from '@/lib/kepler/interfaces'
interface FilterMenuStyle {
  type: 'image' | 'labeled' | 'icon',
  val: Record<string, string>
}

@Component({
  name: 'MapFiltersButton',
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
})
export default class MapFiltersButton extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Prop({type: Boolean, default: false}) public showFilters?: boolean

  protected get filterMenuStyle(): FilterMenuStyle {
    const style = this.fieldConfigs?.filter_menu_style
    const styleSplit = (val: Record<string, string>) => {
      style?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          val[prop[0]] = prop[1]
        }
      })
      return val
    }

    if (style?.startsWith('image')) {
      return {
        type: 'image', val: styleSplit({
          src: 'img/icons/android-chrome-192x192.png',
          class: 'accent--text',
        }),
      }
    } else if (style?.startsWith('labeled')) {
      return {
        type: 'labeled', val: styleSplit({
          icon: 'mdi-filter-variant',
          label: this.$t('map.filters.title'),
        }),
      }
    } else {
      return {
        type: 'icon', val: styleSplit({
          icon: 'mdi-filter-variant',
        }),
      }
    }
  }

  protected get buttonClass() { // could probably merge with above
    const cls = `${this.filterMenuStyle.val?.class || ''}`
    switch (this.filterMenuStyle.type) {
      case 'image': {
        return (`image-button` + cls).trim()
      }
      case 'labeled': {
        return (`text-button` + cls).trim()
      }
      case 'icon': {
        return ('icon-button' + cls).trim()
      }
      default: {
        return cls.trim()
      }
    }
  }
}
