import {ActionTree, MutationTree} from 'vuex'
import {RootState} from '@/store'

export interface TopbarButton {
  [key: string]: any

  icon?: string,
  action?: () => any
  component?: any
  props?: any
  text?: string
}

export class TopbarState {
  public topbarActions: TopbarButton[] = []
}

const mutations: MutationTree<TopbarState> = {
  SET_ACTION_BUTTONS(actionButtons: TopbarState, payload: TopbarButton[]) {
    if (actionButtons.topbarActions === undefined) {
      Object.assign(TopbarState, {topbarActions: []})
    }
    payload.forEach((button) => {
      let duplicate: boolean = false
      if (actionButtons.topbarActions.length) {
        actionButtons.topbarActions.forEach((actionButton) => {
          const sameIcon = button.icon ? button.icon === actionButton.icon : false
          const sameComponent = button.component ? button.component === actionButton.component : false
          const sameText = button.text ? button.text === actionButton.text : false
          duplicate = sameIcon || sameComponent || sameText ? true : duplicate
        })
        if (!duplicate) {
          actionButtons.topbarActions.push(button)
        }
      } else {
        actionButtons.topbarActions.push(button)
      }
    })

  },
  FLUSH_ACTION_BUTTONS(actionButtons) {
    actionButtons.topbarActions = []
  },
}

const actions: ActionTree<TopbarState, RootState> = {
  setActionButtons({commit, dispatch}, payload: TopbarButton[]) {
    commit('SET_ACTION_BUTTONS', payload)
  },
  flushActionButtons({commit}) {
    commit('FLUSH_ACTION_BUTTONS')
  },
}

export default {
  state: new TopbarState(),
  mutations,
  actions,
}
