





























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {Dialog} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'

@Component({
  components: {
    Card,
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'ConfirmDialog',
})
export default class ConfirmDialog extends Vue {
  @State('dialogState') public dialogState!: Dialog[]
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  public loading: boolean = false

  @Prop() private singleAction!: boolean
  @Prop({type: Boolean, default: true}) private emitConfirm!: boolean
  @Prop({type: String,  default: ''}) private imageState!: string
  @Prop({type: String,  default: '-CODE-'}) private code!: string
  @Prop({type: String,  default: '-TITLE-'}) private title!: string
  @Prop({type: String,  default: '-SUBTITLE-'}) private subtitle!: string

  @Prop({type: String}) private confirmText!: string
  @Prop({type: String}) private confirmLabel!: string
  @Prop({type: String}) private confirmColor!: string
  @Prop({type: String}) private cancelText!: string
  @Prop({type: String}) private cancelLabel!: string
  @Prop({type: String}) private cancelColor!: string
  @Prop({type: Boolean, default: true}) private showCloseButton!: boolean

  protected confirm() {
    this.loading = true
    if (this.emitConfirm) {
      EventBus.$emit(this.dialogState[this.dialogState.length - 1].dialogConfirmedEvent)
    }
    this.closeDialog()
  }

  protected get imageType(): string {
    return `img/${this.imageState}`
  }

  protected get confirmButton() {
    return this.confirmText ? this.confirmText : this.$t('action.confirm')
  }

  protected get cancelButton() {
    return this.cancelText ? this.cancelText : this.$t('action.cancel')
  }

}
