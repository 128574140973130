





















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'

import ResetPasswordView from '@/views/ResetPassword.vue'
import LoginByPhone from '@/views/LoginByPhone.vue'
import {Popup} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'AlreadyHasAccount',
})
export default class AlreadyHasAccount extends Vue {
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('closeOverlays') public closeOverlays!: () => void

  @Prop() protected confirmCallback!: () => void

  public closeAndResetPassword() {
    this.closeOverlays()
    this.openPopup(new Popup(ResetPasswordView, null, this.$t('login.reset_your_password'), false))
  }

  public closeAndLoginBySMS() {
    this.closeOverlays()
    this.openPopup(new Popup(LoginByPhone, null, this.$t('login.login_by_phone'), false))
  }
}
