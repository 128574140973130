




































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {VIcon, VLayout, VTextField} from 'vuetify/lib'
import GradientCard from '@/components/GradientCard.vue'
import QrScanner from '@/components/QrScanner.vue'
import {Action} from 'vuex-class'
import Button from '@/components/Button.vue'
import {EventBus} from '@/main'
import TextFieldWithValidation from '@/components/fields/TextFieldWithValidation.vue'
import {Popup} from '@/lib/kepler/interfaces'

@Component({
  components: {
    TextFieldWithValidation,
    Button,
    GradientCard,
    ValidationProvider,
    VLayout,
    VIcon,
    VTextField,
    QrScanner,
  },
  name: 'QRCode',
})
export default class QRCode extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop({
    type: String,
    default: null,
  }) public readonly options!: string | null

  @Prop({
    type: Boolean,
    default: false,
  }) public readonly disabled!: boolean

  @Prop() public name?: string
  @Prop() public description?: string

  @Action('openPopup') public openPopup!: (popup: Popup) => void

  public innerValue: string | null = null

  public get opts() {
    return this.options?.split('|') || []
  }

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    if (newVal !== this.value) {
      this.$emit('input', newVal)
    }
  }

  @Watch('value', {immediate: true})
  public onValueChange(newVal: string) {
    this.innerValue = newVal
  }

  protected openQr() {
    EventBus.$once('qrScanned', (val: string) => {
      this.innerValue = val
    })
    this.openPopup(new Popup(QrScanner, {}, null, true))
  }

  protected reset() {
    this.innerValue = null
  }

  protected openOrReset() {
    this.innerValue ? this.reset() : this.openQr()
  }
}
