











































































  import {Component, Vue} from 'vue-property-decorator'
  import {DriverLicense} from '@/lib/kepler/RegistrationRequest'
  import {Action, State} from 'vuex-class'
  import moment from 'moment'
  import {Attachment, Dialog, LicenseType} from '@/lib/kepler/interfaces'
  import Utils from '@/utils'
  import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

  @Component({
    name: 'AddDocumentView',
    components: {
      FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
      Sheet: Utils.loadComponent('proxy/Sheet'),
      IsLogged: Utils.loadComponent('IsLogged'),
      Container: Utils.loadComponent('proxy/Container'),
      Layout: Utils.loadComponent('proxy/Layout'),
      Button: Utils.loadComponent('Button'),
      InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
      TextField: Utils.loadComponent('proxy/Inputs/TextField'),
      SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
      CountrySelector: Utils.loadComponent('CountrySelector'),
      CameraButtonCustom: Utils.loadComponent('uploader/CameraButtonCustom'),
      Icon: Utils.loadComponent('proxy/Icon'),
      Btn: Utils.loadComponent('proxy/Btn'),
      Img: Utils.loadComponent('proxy/Image'),
    },
  })
  export default class AddDocumentView extends Vue {
    @State((state) => state.licenseTypes.licenseTypes) public LicenseTypes!: LicenseType[]
    @Action('addDocument') public addDocument!: any
    @Action('openDialog') public openDialog!: (dialog: Dialog) => void
@Action('closePopup') public closePopup!: (index?: number) => void
    @Action('closeDialog') public closeDialog!: (index?: number) => void

    public document: DriverLicense = new DriverLicense()

    public rules: any = {
      required: (value: string) => !!value || 'Required.',
    }
    public currentDocumentType: LicenseType = {
      attachment_views: [],
      description: '',
      has_emission: false,
      has_expiry: false,
      has_issuer: false,
      has_country: false,
      has_number: false,
      id: '',
      name: '',
    }

    public attachmentUrls: { [view: string]: string } = {}

    public valid: boolean = false

    public get checkViews() {
      return this.currentDocumentType.attachment_views.every((view) => {
        return !!this.document.attachments && this.document.attachments.hasOwnProperty(view)
      })
    }

    protected get minIssueDate() {
      return moment().subtract(82, 'years').format('YYYY-MM-DD') // don't drive over 100, pls.
    }

    protected get maxIssueDate() {
      return moment().format('YYYY-MM-DD')
    }

    protected get minExpirationDate() {
      return moment().add(7, 'days').format('YYYY-MM-DD')
    }

    protected get maxExpirationDate() {
      return moment().add(12, 'years').format('YYYY-MM-DD')
    }

    public checkView(view: string) {
      return this.currentDocumentType.attachment_views.some(() => {
        return !!this.document.attachments && this.document.attachments.hasOwnProperty(view)
      })
    }

    protected setLicenseType(type: LicenseType) {
      this.$set(this, 'currentDocumentType', type)
      this.document.document_type_id = type.id
    }

    protected setExpirationDate(date: string) {
      this.document.expiry_date = date
    }

    protected setIssuedDate(date: string) {
      this.document.issue_date = date
    }

    protected uploaded(att: Attachment, view: string) {
      if (!this.document.attachments) {
        this.document.attachments = {}
      }

      this.$set(this.document.attachments, view, att.token)
      this.$set(this.attachmentUrls, view, att.url)
    }

    protected deleteAttachment(view: string) {
      if (this.document.attachments) {
        this.$delete(this.document.attachments, view)
      }
      this.$delete(this.attachmentUrls, view)
    }

    protected saveDocument() {
      const a = this.document.attachments
      if (a && Object.entries(a).length === 0 && a.constructor === Object) {
        delete this.document.attachments
      }
      this.addDocument(this.document).then(() => {
        this.openDialog(new Dialog(ConfirmDialogCallback, {
          code: '',
          title: this.$t('profile.documents.added'),
          subtitle: '',
          confirmColor: 'accent',
          imageState: 'icons/document.svg',
          showCloseButton: false,
          confirmCallback: () => {
            if (this.$route.name === 'add new document') {
              this.$router.push({name: 'manage profile', hash: '#documents'})
            } else {
              this.closePopup()
              this.closeDialog()
            }
          },
        }, null, true))
      })
    }
  }
