

















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'

interface DialogOption {
  text: string
  value: string | number
  color?: string
}

@Component({
  components: {
    Card,
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'MultipleDialogCallback',
})
export default class MultipleDialogCallback extends Vue {
  @Action('closeDialog') protected closeDialog!: (index?: number) => void

  @Prop({type: Boolean, default: true}) protected showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) protected closeOnCallback!: boolean

  @Prop({type: String, required: false}) protected imageState?: string
  @Prop({type: String, required: false}) protected title?: string
  @Prop({type: String, required: false}) protected subtitle?: string

  @Prop({
    type: Array,
    required: true,
    validator(options: any): boolean {
      return options.every((o: any) => {
        return typeof o.text === 'string' && (typeof o.value === 'string' || typeof o.value === 'number')
      })
    },
  }) protected options!: DialogOption[]

  @Prop({type: Function, required: true}) protected callback!: (option: string) => void
  @Prop({type: Function}) protected cancelCallback!: () => void

  protected loading: boolean = false

  protected get imageType(): string | null {
    if (this.imageState) {
      return this.imageState.startsWith('http') ? this.imageState : `img/${this.imageState}`
    }
    return null
  }

  protected callAndClose(option: string) {
    this.loading = true
    if (this.closeOnCallback) {
      this.closeDialog()
    }
    this.callback(option)
  }
}
