

























import {Component, Prop} from 'vue-property-decorator'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'
import {mixins} from 'vue-class-component'
import {
  VAvatar,
  VCard,
  VDivider,
  VIcon, VImg,
  VList, VListTile,
  VListTileAction,
  VListTileAvatar,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
} from 'vuetify/lib'

@Component({
  components: {
    VAvatar,
    VCard,
    VList,
    VListTile,
    VListTileAvatar,
    VIcon,
    VListTileContent,
    VListTileTitle,
    VListTileSubTitle,
    VListTileAction,
    VDivider,
    VImg,
  },
  mixins: [CustomColorableMixin],
})
export default class CardList extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  // chack: justify, align, wrap

  @Prop({
    type: String,
    default: 'transparent',
  }) public circleColor!: string
  @Prop({}) public icon?: string
  @Prop({}) public iconColor?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public tile?: boolean
  @Prop({

    type: Boolean,
    default: true,
  }) declare public ripple?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public divider?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public ellipsis?: boolean
  @Prop({type: String}) public action?: string
  @Prop({type: String}) public description?: string
  @Prop({type: String}) public background?: string
  @Prop({type: String}) public image?: string

  public get checkIsLight() {
    if (this.circleColor !== 'transparent') {
      return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.circleColor))
    }
    return !this.$vuetify.dark
  }
}
