import Vue from 'vue'
import Vuex, {ActionContext, Store} from 'vuex'
import storage from '@/lib/storage'
import sdk from '@/lib/kepler/sdk'
import {log} from '@/lib/plugins/logger'
import modules, {StoreModules} from '@/store/modules'
import {MapFiltersState} from '@/store/modules/map'

Vue.use(Vuex)

export class RootState {
  public userToken?: string
  public logs: string[] = []
  public version: string = ''
}

export type RS = RootState & StoreModules

const rootState: Store<RS> = new Vuex.Store({
  state: new RootState() as RS,
  mutations: {
    INIT_ROOT_STATE(state) {
      storage.restore('root', state)
      state.dialogState = []
      if (!state.userToken && state.booking.activeReservations.length) {
        state.booking.activeReservations = [] // to be really sure
      }
    },
    UPDATE_VERSION(state, v) {
      storage.save('version', v)
    },
    UPDATE_USER_TOKEN(state, payload) {
      Vue.set(state, 'userToken', payload)
    },
    LOG(state, payload: string) {
      const maxLength = 100 // adjust as needed
      state.logs.push(payload)
      while (state.logs.length > maxLength) {
        state.logs.shift()
      }
    },
    CLEAR_LOGS(state) {
      state.logs.splice(0)
    },
    SAVE_STORAGE(state) {
      const o = JSON.parse(JSON.stringify(state))
      o.popupState = []
      o.dialogState = []
      o.flowInputs = {}
      o.flowPersistent = {}
      o.booking.reservations = {
        current_page_count: 0,
        items_count: 0,
        page_count: 0,
        per_page_count: 0,
        current_page: -1,
        items: [],
      }
      o.profile.reports = []
      o.topbar = {topbarActions: []}
      o.subscriptions = {plans: []}
      Object.entries(o.filters as MapFiltersState).forEach(([k, f]) => {
        Object.entries(f).forEach(([kk, v]) => {
          const ff = o.filters[k][kk]
          if (typeof v === 'object' && v.data && typeof ff === 'object') {
            ff.data = [] // remove data from map filters
          }
        })
      })
      storage.save('root', o)
    },
    PURGE_STATE(state) {
      state = new RootState() as RS
    },
    PURGE_STORAGE() {
      storage.delete('root')
    },
  },
  actions: {
    initRoot({state, dispatch, commit}, version: string) {
      const storedVersion = storage.load('version')
      const token: string | undefined = storage.load('root')?.userToken
      if (storedVersion && version !== storedVersion) {
        return dispatch('purge').then(() => {
          commit('UPDATE_VERSION', version)
          commit('UPDATE_USER_TOKEN', token)
          dispatch('init')
          return true
        })
      } else {
        dispatch('init')
        return false
      }
    },
    init({commit}) {
      commit('INIT_ROOT_STATE')
    },
    sleep({rootGetters, commit}, caller?: string) {
      commit('SAVE_STORAGE')
      if (rootGetters.debugMode) {
        log(caller, 1)
      }
    },
    purge({commit}) {
      commit('PURGE_STATE')
      commit('PURGE_STORAGE')
    },
    updateUserToken(store: ActionContext<RS, any>, payload) {
      store.commit('UPDATE_USER_TOKEN', payload)
      store.dispatch('timestamps')
    },
    globalNotes() {
      return sdk.people.globalNotes().then(({data}) => data)
    },
    log(store: ActionContext<RS, any>, payload?: string) {
      store.commit('LOG', payload)
    },
    clearLogs(store: ActionContext<RS, any>) {
      store.commit('CLEAR_LOGS')
    },
  },
  getters: {
    isLogged: ({userToken}) => !!userToken,
  },
  modules,
})

export default rootState
