

































import {Component, Prop, Vue} from 'vue-property-decorator'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'

import ProgressCircular from '@/components/proxy/ProgressCircular.vue'

@Component({
  components: {
    ProgressCircular,
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Container: Utils.loadComponent('proxy/Container'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Digits: Utils.loadComponent('Digits'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    //                A E S T E T H I C S
  },
  name: 'PinRequest',
})
export default class PinRequest extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @State((state) => state.booking.selectedReservation) protected selectedReservation!: ReservationResponse
  @Prop() protected reservation?: ReservationResponse
  @Prop({default: true}) protected showCloseButton!: boolean
  @Prop({
    type: Number,
    default: 4,
  }) protected maxLength!: number

  protected loading: boolean = false
  protected disabled: boolean = false
  protected visibility: boolean = false
  protected confirmed: boolean = false
  protected digits: string = ''
  protected error: string = ''

  protected mounted() {
    EventBus.$on('pinRequestError', this.onError)
    EventBus.$on('pinRequestSuccess', this.onSuccess)
  }

  protected destroyed() {
    EventBus.$off('pinRequestError', this.onError)
    EventBus.$off('pinRequestSuccess', this.onSuccess)
  }

  protected sendPinRequest() {
    EventBus.$emit('pinRequest', this.digits)
    this.loading = true
  }

  protected onSuccess() {
    this.loading = false
    this.confirmed = true
    this.$nextTick(() => {
      const check = (this.$refs.pinRequestSuccess as Vue)
      if (check && check.$el) {
        check.$el.classList.remove('v-ripple__animation')
      }
      setTimeout(() => {
        this.closeDialog()
      }, 1000)
    })
  }

  protected onError(r: string) {
    this.loading = false
    this.confirmed = false
    this.error = r || ''
    this.digits = ''
  }

  protected get vehicleType() {
    if (this.reservation || this.selectedReservation) {
      return (this.reservation || this.selectedReservation).vehicle_slot.vehicle.category.type
    }
  }

  protected addDigit(val: string) {
    this.error = ''
    if (this.digits.length < this.maxLength) {
      this.digits += val
    }
  }

  protected removeDigit() {
    this.digits = this.digits.slice(0, -1)
  }

  protected get asterisks(): string {
    return '*'.repeat(this.digits.length)
  }

}
