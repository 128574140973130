
































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {VIcon, VLayout, VSlideXReverseTransition, VTextField} from 'vuetify/lib'
import GradientCard from '@/components/GradientCard.vue'
import QrScanner from '@/components/QrScanner.vue'
import {Action} from 'vuex-class'
import Button from '@/components/Button.vue'
import {EventBus} from '@/main'
import TextFieldWithValidation from '@/components/fields/TextFieldWithValidation.vue'
import Layout from '@/components/proxy/Layout.vue'
import sdk from '@/lib/kepler/sdk'
import {Popup} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout,
    TextFieldWithValidation,
    Button,
    GradientCard,
    ValidationProvider,
    VSlideXReverseTransition,
    VLayout,
    VIcon,
    VTextField,
    QrScanner,
  },
  name: 'Invite',
})
export default class Invite extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop({
    type: String,
    default: null,
  }) public readonly options!: string | null

  @Prop() public name?: string
  @Prop() public description?: string

  @Action('openPopup') public openPopup!: (popup: Popup) => void

  public innerValue: string | null = null
  public loading: boolean = false

  public get opts() {
    return this.options?.split('|') || []
  }

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    if (newVal !== this.value) {
      this.$emit('input', newVal)
    }
  }

  @Watch('value', {immediate: true})
  public onValueChange(newVal: string) {
    this.innerValue = newVal
  }

  protected openQr() {
    EventBus.$once('qrScanned', (val: string) => {
      this.loading = true
      sdk.checkToken(val)
        .then((r) => {
          if (r.data?.result_code === 'token-is-valid') {
            this.innerValue = val
          }
        })
        .catch()
        .finally(() => {
          this.loading = false
        })
    })
    this.openPopup(new Popup(QrScanner, {}, null, true))
  }
}
