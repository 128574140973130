





































import {Component, Prop} from 'vue-property-decorator'
import {VehicleSlot, Dialog} from '@/lib/kepler/interfaces'
import Utils from '../utils'

import {Action} from 'vuex-class'
import CardButton from './CardButton.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import ServiceMesh from '@/lib/serviceMesh'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    Plate: Utils.loadComponent('Plate'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Container: Utils.loadComponent('proxy/Container'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'CardVehicleList',
})
export default class CardVehicleList extends CardButton {
  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public estimate!: number
  @Prop() public estimateLoading!: boolean
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  public get typeKey() {
    return `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
  }

  public get parsedRate() {
    const r = this.vehicleSlot.base_rate
    const parsedistance = (val: string) => this.$distance(val && Number(val) !== 1 ? val : undefined)
    if (r) {
      const [timeCost, timeCycle] = r.info.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info.distance_rate?.split('|') ?? [null, null]
      return {
        time: {
          cost: timeCost !== null ? this.$currency(timeCost) : null,
          cycle: timeCycle !== null ? this.$parseMinutes(timeCycle) : null,
        },
        distance: {
          cost: distanceCost !== null ? this.$currency(distanceCost) : null,
          cycle: distanceCycle !== null ? parsedistance(distanceCycle) : null,
        },
      }
    }
  }

  protected get vehicleTypeColor() {
    const key = `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
    return {color: ServiceMesh.colors[key]}
  }

  protected canBeBooked(event: Event) {
    if (this.estimateLoading) {
      return
    }

    if (this.estimate !== null) {
      this.$emit('selectVehicle', event)
      return
    }

    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      // il tuo piano non ti permette di guidare questa categoria di veicolo
      title: this.$t('booking.your_plan_is_not_suitable'),
      subtitle: '',
      confirmText: '',
      imageState: 'warn.svg',
      confirmCallback: () => {
        return 1
      },
    }))
  }
}
