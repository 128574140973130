import {RootState} from '@/store'
import {ActionTree, MutationTree} from 'vuex'
import {Popup} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

export const PopupState: Popup[] = []

const mutations: MutationTree<typeof PopupState> = {
  INIT_POPUP_STATE(pState) {
    pState = []
  },
  OPEN_POPUP(pState, payload: Popup) {
    pState.push(payload)
  },
  CLOSE_POPUP(pState, index?: number) {
    if (index) {
      pState.splice(index, 1)
    } else {
      pState.pop()
    }
  },
  FLUSH_POPUP_QUEUE(pState) {
    Utils.flushArray(pState)
  },
}

const actions: ActionTree<typeof PopupState, RootState> = {
  init({commit}) {
    commit('INIT_POPUP_STATE')
  },
  openPopup({commit, dispatch}, popup: Popup) {
    commit('OPEN_POPUP', popup)
  },
  closePopup({commit}, index?: number) {
    commit('CLOSE_POPUP', index)
  },
  closeOverlays({commit}) {
    commit('FLUSH_POPUP_QUEUE')
  },
  flushPopups({commit}) {
    commit('FLUSH_POPUP_QUEUE')
  },
}

const getters = {
  popupIsOpen: (popupState: typeof PopupState) => !!popupState.length,
  fullscreenPopup: (popupState: typeof PopupState) => popupState.find((p) => p.fullScreen),
}

export default {
  state: PopupState,
  mutations,
  actions,
  getters,
}
