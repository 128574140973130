












































































import {Vue, Component} from 'vue-property-decorator'

import {State, Action, Getter} from 'vuex-class'
import {AccountsState} from '@/store/modules/accounts'
import {ProfileState} from '@/store/modules/profile'
import {WalletState} from '@/store/modules/wallet'

import Utils from '@/utils'

import {VListTileActionText} from 'vuetify/lib'

import {FieldConfigs, Packet, ProfileData, ProfileItem, Wallet, WalletMeta} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'

class ListHelperClass {
  public icon: string | null = null
  public routeName: string | null = null
  public severity: { color: string | null; text: string | null } = {
    color: null,
    text: null,
  }
}

class WalletStringClass {
  public text: string = ''
  public amount: string = ''
  public severity: string = ''
}

@Component({
  components: {
    CardList: Utils.loadComponent('CardList'),
    Alert: Utils.loadComponent('proxy/Alert'),
    BuyPackets: Utils.loadComponent('BuyPackets'),
    Flex: Utils.loadComponent('proxy/Flex'),
    AddCoupon: Utils.loadComponent('wallet/AddCoupon'),
    BuyTopUp: Utils.loadComponent('BuyTopUp'),
    ProfileSwitcher: Utils.loadView('ProfileSwitcher'),
    VListTileActionText,
    Icon: Utils.loadComponent('proxy/Icon'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileContent: Utils.loadComponent('proxy/List/ListTileContent'),
    ListTileSubTitle: Utils.loadComponent('proxy/List/ListTileSubTitle'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
    ListTileAction: Utils.loadComponent('proxy/List/ListTileAction'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    ClientDriverStatus: Utils.loadComponent('ClientDriverStatus'),
    ProfileWithImage: Utils.loadComponent('ProfileWithImage'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'Profile',
})
export default class Profile extends Vue {
  @State('profile') public profileState!: ProfileState
  @State('wallet') public walletState!: WalletState
  @State('accounts') public accounts!: AccountsState
  @State((state) => state.profile.profileData) public profileData!: ProfileData | null

  @Getter('mainDriver') public isMainDriver!: boolean
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('getProfile') public getProfile!: () => Promise<Profile>
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('getDrivers') public getDrivers!: () => Promise<void>
  @Action('updateEntity') public updateEntity!: (key: string) => Promise<void>
  @Action('getPackets') public getPackets!: () => Promise<Packet[]>

  protected loading: boolean = false
  protected showProfilePicker: boolean = false

  protected get profileCards() {
    const obj: ProfileData = {}
    if (this.profileData) {
      for (const [key, item] of Object.entries(this.profileData)) {
        if (item?.meta.format === 'card') {
          obj[key] = item
        }
      }
    }
    return obj
  }

  protected get profileList() {
    const obj: ProfileData = {}
    if (this.profileData) {
      for (const [key, item] of Object.entries(this.profileData)) {
        if (!item?.meta.format || item.meta.format === 'list') {
          obj[key] = item
        }
      }
    }
    return obj
  }

  protected get hasCoupons() {
    return !this.fieldConfigs?.coupon_disabled
  }

  protected get hasTopups() {
    return !!this.fieldConfigs?.topup_sizes
  }

  protected get hasPackets() {
    return !!this.fieldConfigs?.packets_enabled
  }

  protected toggleProfileSwitch() {
    if (this.$route.name === 'profile') {
      this.$router.push({name: 'profile-switch'})
    } else {
      this.$router.push({name: 'profile'})
    }
  }

  protected profileSwitched() {
    this.toggleProfileSwitch()
    this.init()
  }

  protected init() {
    this.loading = true
    const actions = [
      this.getProfile(),
      this.updateEntity('client'),
    ]
    this.getPackets()

    Promise.all(actions).finally(() => {
      this.loading = false
    })
    if (this.profileState && this.profileState.driver && this.isMainDriver) {
      if (this.walletState.wallets === null) {
        this.getWallets()
      }
      if (this.profileState.drivers === null) {
        this.getDrivers()
      }
    }
  }

  protected mounted() {
    this.init()
  }

  protected routerPush(key: string) {
    const name = this.listHelper(key).routeName
    if (name) {
      this.$router.push({name})
    }
  }

  protected getWalletStrings(item: ProfileItem) {
    const arr: WalletStringClass[] = []
    for (const [key, num] of Object.entries(item.meta as WalletMeta)) {
      if (key.startsWith('balance')) {
        const obj = new WalletStringClass()
        obj.text = this.$t(`wallet.${key}`)
        obj.amount = this.$currency(num)
        arr.push(obj)
      } else if (key.startsWith('packets')) {
        const obj = new WalletStringClass()
        obj.text = this.$t(`wallet.${key}`)
        if (key.includes('minutes')) {
          obj.amount = DateHelper.parseMinutes(this, num)
        } else if (key.includes('distance')) {
          obj.amount = this.$distance(num)
        } else {
          obj.amount = num
        }
        arr.push(obj)
      }
    }
    return arr
  }

  protected listHelper(key?: string, item?: ProfileItem) {
    const obj = new ListHelperClass()

    const list: { [key: string]: { icon: string | null, routeName: string } } = {
      wallet: {icon: 'mdi-wallet', routeName: 'manage-wallet'},
      history: {icon: 'mdi-calendar-clock', routeName: 'history'},
      subscriptions: {icon: 'mdi-cards-variant', routeName: 'my subscriptions'},
      drivers: {icon: 'mdi-account-group', routeName: 'drivers'},
      payment_methods: {icon: 'mdi-credit-card-multiple', routeName: 'payment methods'},
      documents: {icon: 'mdi-card-account-details', routeName: 'My documents'},
      reportings: {icon: 'mdi-message-alert', routeName: 'reportings'},
      tocs: {icon: 'mdi-file-document-edit', routeName: 'terms'},
      communications: {icon: 'mdi-forum', routeName: 'comms'},
      settings: {icon: 'mdi-cog', routeName: 'settings'},
      logout: {icon: 'mdi-logout', routeName: 'logout'},
    }

    const severityList: { [key: string]: { color: string, text: string } } = {
      FAIL: {color: 'error', text: 'error--text'},
      WARN: {color: 'warning', text: 'warning--text'},
    }

    if (key) {
      obj.icon = list[key]?.icon || null
      obj.routeName = list[key]?.routeName || null
    }
    if (item) {
      obj.severity = severityList[item.status] || obj.severity
    }

    return obj
  }
}
