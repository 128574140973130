











import {Component} from 'vue-property-decorator'
import {Action} from 'vuex-class'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import Flow from '@/components/flow/Flow.vue'
import {FlowInputStep, Popup, Dialog} from '@/lib/kepler/interfaces'
import {mixins} from 'vue-class-component'
import {FlowMixin} from '@/components/flow/FlowMixin'

@Component({
  components: {
    Flow,
  },
})
export default class BaseFlow extends mixins<FlowMixin>(FlowMixin) {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void

  public initialLoading: string | null = null

  public steps: FlowInputStep[] = []

  public getName() {
    return this.$attrs.flowName || 'base_flow'
  }

  public exitBack() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$isAvailable('flows.exit_back.title'),
      subtitle: this.$t('flows.exit_back.subtitle'),
      confirmText: this.$isAvailable('flows.exit_back.confirm'),
      cancelText: this.$isAvailable('flows.exit_back.cancel'),
      confirmColor: 'buttonDefaultColor',
      imageState: '',
      showCloseButton: false,
      confirmCallback: () => {
        this.getPersistent()
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
        this.closePopup()
      },
      cancelCallback: () => {
        return
      },
    }))
  }

  public exitForward() {
    this.onConfirmEnd()
    this.purgeFlow(this.getName())
    this.getPersistent()
  }

  public onConfirmEnd() {
    //
  }

  public onFlowLoad() {
    //
  }
}
