























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {blobToDataURL} from '@/lib/BlobHelper'
import sdk from '@/lib/kepler/sdk'
import Utils from '@/utils'
import {Attachment} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Img: Utils.loadComponent('proxy/Image'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
  name: 'ImageUploadDialog',
})
export default class ImageUploadDialog extends Vue {
  @Action('closeDialog') public closeDialog!: () => void

  @Prop({
    type: Blob,
    required: true,
    validator(value: any): boolean {
      return value instanceof Blob
    },
  }) protected blob!: Blob
  @Prop({
    type: String,
    required: false,
  }) protected filename?: string
  @Prop({
    type: Function,
  }) protected successCallback!: (r: Attachment) => void
  @Prop({
    type: Function,
  }) protected cancelCallback!: () => void

  protected loading = false
  protected done = false
  protected error = false
  protected imgSrc: null | string = null

  protected get mime() {
    const data = this.imgSrc?.split(';').find((segment) => {
      return segment.startsWith('data:')
    })
    if (data) {
      return data.split(':')[1]
    }
    return null
  }
  protected get isImage() {
    return this.mime?.startsWith('image/')
  }
  protected get isPdf() {
    return this.mime === 'application/pdf'
  }

  protected get fileName() {
    return this.blob
  }

  protected upload() {
    this.loading = true
    sdk.uploadBlob(this.blob)
      .then((t) => {
        this.successCallback(t.data)
        this.done = true
        setTimeout(this.closeDialog, 1000)
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.loading = false
      })
  }

  protected created() {
    if (!this.blob) {
      this.closeDialog()
    }
    return blobToDataURL(this.blob).then((r) => {
      this.imgSrc = r as string
    })
  }

}
