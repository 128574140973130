










import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import {CameraHelperSimple} from '@/lib/CameraHelperSimple'

@Component({
  components: {
    BrowserCamera: Utils.loadComponent('BrowserCamera'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'CameraDialog',
})
export default class CameraDialog extends Vue {
  @Action('closeDialog') public closeDialog!: () => void

  @Prop({type: Function}) public cancelCallback!: () => void
  @Prop({type: Function}) public successCallback!: (image: string) => void
  @Prop({type: Boolean, default: true}) public showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) public emitConfirm!: boolean
  @Prop({type: Boolean, default: true}) public closeOnCallback!: boolean
  @Prop({type: Object, required: true}) public cameraOptions!: CameraOptions

  protected loading: boolean = false
  protected resultImageUrl: string | null = null
  protected camera: CameraHelperSimple | null = null

  protected created() {
    this.camera = new CameraHelperSimple(this.cameraOptions)
  }

  protected initCamera(fallback?: boolean) {
    this.cameraOptions.sourceType = fallback ? 0 : 1
    return new CameraHelperSimple(this.cameraOptions)
  }

  protected fallbackNativeCamera() {
    return this.initCamera().getData().then((r) => {
      this.successCallback(URL.createObjectURL(this.dataURIToBlob(r)))
      this.closeDialog()
    })
  }

  private gotBrowserCameraOutput(r: string) {
    // const dataURIToBlob = this.dataURIToBlob(r)
    // const objectURL = URL.createObjectURL(dataURIToBlob)
    this.successCallback(r)
    this.closeDialog()
  }

  private dataURIToBlob(dataURI: string) {
    dataURI = dataURI.replace(/^data:/, '')

    const match = dataURI.match(/image\/[^;]+/)
    const type = match ? match.toString() : undefined
    const base64 = dataURI.replace(/^[^,]+,/, '')
    const arrayBuffer = new ArrayBuffer(base64.length)
    const typedArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < base64.length; i++) {
      typedArray[i] = base64.charCodeAt(i)
    }

    return new Blob([arrayBuffer], {type})
  }
}
