





























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import TextField from './TextField.vue'
import DatePicker from '../../DatePicker.vue'
import {State} from 'vuex-class'
import moment from 'moment'

@Component({
  name: 'InputDatePicker',
  components: {DatePicker, TextField},
})
export default class InputDatePicker extends Vue {

  public date?: string = ''
  public modal: boolean = false
  @State((state) => state.profile.language) public lang!: string

  @Prop() private label!: string
  @Prop() private closeOnContentClick!: boolean
  @Prop() private lazy!: boolean
  @Prop() private disabled?: boolean
  @Prop({type: Boolean, default: false}) private readonly?: boolean
  @Prop() private fullWidth!: boolean
  @Prop() private birthdate!: boolean
  @Prop() private min?: string
  @Prop() private max!: string
  @Prop() private rules!: any[]
  @Prop() private value?: string
  @Prop() private type?: string
  @Prop() private prependIcon?: string
  @Prop({}) private format?: (date: string) => string

  protected get dateFormatted() {
    if (this.date) {
      if (this.format !== undefined) {
        return this.format(this.date)
      }
      return moment(this.date).format('L')
    }
    return undefined
  }

  protected get hasError() {
    return this.value ? !moment(this.value).isBetween(this.min, this.max, 'day', '[]') : false
  }

  @Watch('value')
  protected valueChanged(): void {
    this.date = this.value
  }

  @Watch('min')
  @Watch('max')
  protected constraintsChanged(): void {
    if (this.hasError && this.value) {
      this.$emit('error')
    }
  }

  protected mounted() {
    this.date = this.value ? this.value : ''
  }

  protected save(date: string) {
    const dialog = (this.$refs.dialog as any)
    this.date = date
    dialog.save(date)
    this.$emit('dateSelected', date)
  }
}
