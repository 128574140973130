













import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Card from './proxy/Card/Card.vue'
import {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {Card},
  mixins: [CustomColorableMixin],
})
export default class GradientCard extends mixins<CustomColorableMixin>(CustomColorableMixin) {
}
