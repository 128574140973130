import {Vue, Component} from 'vue-property-decorator'
import {
  Popup,
  Dialog,
  BookRequest,
  ReservationResponse,
} from '@/lib/kepler/interfaces'
import LoaderDialog from '@/views/LoaderDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import {Action} from 'vuex-class'

@Component({})
export default class BookingMixin extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>

  protected bookRequest: BookRequest = {start: null, end: null, memo: null, plate: null}
  protected sendBooking(callback?: (r?: ReservationResponse) => void) {
    this.openDialog(new Dialog(LoaderDialog, {text: this.$t('common.loading')}))
    this.bookAction(this.bookRequest)
      .finally(() => {
        this.closeDialog(0)
      })
      .then((r) => {
        const confirmCallback = callback? () => callback(r) : undefined
        this.openPopup(new Popup(
          VehicleConfirmBooking, {
            vehicleSlot: r.vehicle_slot,
            confirmCallback,
          }, null, true))
      })
      .catch(() => '💩')
  }
}
