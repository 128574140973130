import {RootState} from '@/store'
import {ActionTree, MutationTree} from 'vuex'
import {Dialog} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

export const DialogState: Dialog[] = []

const mutations: MutationTree<typeof DialogState> = {
  INIT_DIALOG_STATE(state) {
    state = []
  },
  OPEN_DIALOG(state, payload: Dialog) {
    const lastIndex = state.length - 1
    // quick and dirty check if dialog is identical to last one
    if (JSON.stringify(payload) !== JSON.stringify(state[lastIndex])) {
      state.push(payload)
    }
  },
  CLOSE_DIALOG(state, index?: number) {
    if (typeof index === 'number') {
      state.splice(index, 1)
    } else {
      state.pop()
    }
  },
  FLUSH_DIALOG_QUEUE(state) {
    Utils.flushArray(state)
    state = []
  },
}

const actions: ActionTree<typeof DialogState, RootState> = {
  init({commit}) {
    commit('INIT_DIALOG_STATE')
  },
  openDialog({commit, dispatch}, dialog: Dialog) {
    commit('OPEN_DIALOG', dialog)
  },
  closeDialog({commit}, index?: number) {
    commit('CLOSE_DIALOG', index)
  },
  closeOverlays({commit}) {
    commit('FLUSH_DIALOG_QUEUE')
  },
  flushConnectionErrors({state, commit}) {
    // more than a bit hacky
    for (const [i, d] of state.entries()) {
      if (d.popupProps.title === 'common.you-are-offline') {
        commit('CLOSE_DIALOG', i)
      }
    }
  },
}

const getters = {
  dialogIsOpen: (dialogState: typeof DialogState) => !!dialogState.length,
}

export default {
  state: DialogState,
  mutations,
  actions,
  getters,
}
