










import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

interface GridItem {
  r: number
  c: string
}

@Component({
  name: 'LoopingBG',
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class LoopingBG extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) protected logo!: boolean

  protected gridCols = 10
  protected gridRows = 5
  protected grid: GridItem[] = []
  protected timer: NodeJS.Timeout | null = null
  protected colorMap = [
    '#27BCEA',
    '#38A1D9',
    '#9DC957',
    '#E1D21E',
    '#EA422D',
    '#EE6A29',
    '#eee',
  ]

  protected get gridStyle() {
    return {
      gridTemplateColumns: `repeat(${this.gridCols}, 1fr)`,
      gridTemplateRows: `repeat(${this.gridRows}, 1fr)`,
    }
  }

  protected rotate(index: number) {
    this.grid[index].r += 90
  }

  protected updateTimer() {
    this.calcRandRotation()
    this.calcRandColor()
    this.timer = setTimeout(this.updateTimer, 1000)
  }

  protected generateGrid() {
    this.grid = Array.from(Array(this.gridRows * this.gridCols)).map(() => {
      return {
        c: '#eee',
        r: this.getRandomInt(0, 4) * 90,
      }
    })
  }

  protected mounted() {
    this.generateGrid()
    this.grid[4] = {r: 270, c: this.colorMap[0]}
    this.grid[5] = {r: 0, c: this.colorMap[1]}
    this.grid[14] = {r: 180, c: this.colorMap[2]}
    this.grid[15] = {r: 0, c: this.colorMap[3]}
    this.grid[24] = {r: 180, c: this.colorMap[4]}
    this.grid[25] = {r: 90, c: this.colorMap[5]}
    setTimeout(this.updateTimer, 500)
  }

  protected beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  protected calcRandRotation() {
    const idx = this.getRandomInt(0, this.gridCols * this.gridRows)
    Vue.set(this.grid[idx], 'r', this.getRandomInt(0, 4) * 90)
  }

  protected calcRandColor() {
    const idx = this.getRandomInt(0, this.gridCols * this.gridRows)
    Vue.set(this.grid[idx], 'c', this.colorMap[this.getRandomInt(0, 6)])
  }

  protected getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min)
  }

  protected getBackground(fill: string) {
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.2 60.9'%3E%3Cpath fill='${encodeURIComponent(fill)}' d='M58.17 50.68a56.11 56.11 0 0 0-10.42-25.59l-.07-.1c-.46-.61-.93-1.22-1.41-1.82l-.35-.42c-.39-.48-.79-1-1.21-1.43-.19-.22-.4-.44-.6-.66-.34-.38-.68-.75-1-1.12l-.86-.86-.86-.86-1.12-1-.66-.6c-.47-.42-.95-.82-1.43-1.21l-.42-.35c-.6-.48-1.21-1-1.82-1.41l-.1-.07A56.24 56.24 0 0 0 10.22 2.72a10.52 10.52 0 0 1-7.8 7.4v40.35a10.77 10.77 0 0 1 6.91 5.22 10.26 10.26 0 0 1 1.09 2.79h40.35a10.55 10.55 0 0 1 7.4-7.8Z'/%3E%3C/svg%3E")`
  }

}
