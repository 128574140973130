









































import {Vue, Component, Prop, PropSync} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'
import {
  AvailabilityResponse,
  ReservationResponse,
  Subscription,
  UserMesh,
  VehicleSlot,
  Popup,
  BookingMode,
} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'BookingButtons',
})

export default class BookingButtons extends Vue {
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[]

  @Getter('mainDriver') public isMainDriver!: boolean
  @Getter('remindBookingModes') public remindBookingModes!: Partial<Record<keyof typeof BookingMode, boolean>>

  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void

  @Action('getUserMesh') public getUserMesh!: () => Promise<UserMesh[]>
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>

  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public currentTab!: number
  @Prop() public loadingProp!: boolean
  @PropSync('reminder', {type: Boolean}) public needsReminder!: boolean

  public loading: boolean = true
  public reservation?: ReservationResponse
  public availability: AvailabilityResponse[] = []
  public canBook: boolean = false

  public get isLogged() {
    return sdk.people.isLogged()
  }

  public get resType() {
    return this.vehicleSlot.reservation_type.toUpperCase()
  }

  public get vehicleTypeColor() {
    const slot = this.reservation?.vehicle_slot || this.vehicleSlot
    return `${slot.reservation_type}${slot.vehicle.category.type}`.toUpperCase()
  }

  public checkIfCanBook() {
    const vehicleInMesh = this.userMesh.find((usermesh: UserMesh) => {
      const rType = usermesh.booking_mode.toLowerCase() === this.resType.toLowerCase()
      const vType = usermesh.vehicle_type.toLowerCase() === this.vehicleSlot.vehicle.category.type.toLowerCase()
      return rType && vType
    })
    this.canBook = vehicleInMesh?.available || false
    this.needsReminder = this.remindBookingModes[vehicleInMesh?.booking_mode as keyof typeof BookingMode] || false
    this.loading = false
  }

  public mounted() {
    if (!this.isLogged) {
      return
    }

    this.getUserMesh().then(() => {
      if (this.isMainDriver) {
        this.getSubscriptions().then(this.checkIfCanBook)
      } else {
        this.checkIfCanBook()
      }
    })
  }

  public goTo(name: string) {
    this.closePopup()
    this.$router.push({name})
  }
}
