














import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Utils from '@/utils'
import {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardButton: Utils.loadComponent('CardButton'),
    Badge: Utils.loadComponent('proxy/Badge'),
  },
  mixins: [CustomColorableMixin],
})
export default class CardButtonNumeric extends mixins(CustomColorableMixin) {
  @Prop({type: String}) protected badgeColor?: string
  @Prop({type: String}) protected action?: string
  @Prop({type: Number}) protected value?: number
}
