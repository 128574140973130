
import {Component} from 'vue-property-decorator'
import BaseFlow from '@/views/Flows/BaseFlow.vue'

@Component({})
export default class FlowBooking extends BaseFlow {
  public exitBack() {
    // this.getPersistent()
    if (this.$route.name !== 'activities') {
      this.$router.replace({name: 'activities'})
    }
    this.closePopup()
  }

  public exitForward() {
    if (this.$route.name !== 'home') {
      this.$router.push({name: 'home'})
    }
    this.closePopup()
  }

  public getName(): string {
    return 'rent'
  }

  protected beforeDestroy() {
    this.purgeFlow('rent')
  }
}
