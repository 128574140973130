




























import {Component} from 'vue-property-decorator'
import {
  BookingMode,
  ReservationResponse,
  Vehicle,
  VehicleType,
  Popup,
  Dialog, FieldConfigs,
} from '@/lib/kepler/interfaces'
import {Action, Getter} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'

import VehicleBookingAddMemoDialog from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import QrScanner from '@/components/QrScanner.vue'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import BookingMixin from '@/lib/BookingMixin'
import {mixins} from 'vue-class-component'

@Component({
  components: {
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TopBar: Utils.loadComponent('TopBar'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Digits: Utils.loadComponent('Digits'),
    Button: Utils.loadComponent('Button'),
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'FFVehicleBook',
  mixins: [BookingMixin],
})
export default class FFVehicleBook extends mixins<BookingMixin>(BookingMixin) {
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Action('track') public track!: () => Promise<void>

  public digitsCompleted: boolean = false
  protected confirmed: boolean = false
  protected digits!: string

  protected get vehicle() {
    const v = new Vehicle()
    if (this.vehicleType) {
      v.category.type = this.vehicleType
    }
    return v
  }

  protected get vehicleType() {
    const type = this.$route.params.type?.toUpperCase()
    if (type) {
      const vehicleTypeCheck = Object.keys(VehicleType).includes(type)
      return vehicleTypeCheck ? type as VehicleType : null
    }
    return null
  }

  protected get bookingMode() {
    const mode = this.$route.params.mode?.toUpperCase()
    if (mode) {
      const bookingModeCheck = Object.keys(BookingMode).includes(mode)
      return bookingModeCheck ? mode as BookingMode : null
    }
    return null
  }

  protected get color() {
    const bm = this.bookingMode
    const vt = this.vehicleType
    if (bm && vt) {
      return VuetifyColorHelper.color(bm + vt)
    }
    return 'primary'
  }

  protected get hasExtra(): string | false {
    const vt = this.vehicleType
    if (vt) {
      return this.$isAvailable(`booking.enter_license_place_extra.${vt.toLowerCase()}`) || false
    }
    return false
  }

  protected mounted() {
    if (!this.vehicle || !this.vehicleType) {
      this.$router.back()
      return
    }
    EventBus.$on('qrScanned', (e: string) => {
      this.bookRequest.plate = e
      this.bookAndGo()
    })
  }

  protected destroyed() {
    EventBus.$off('qrScanned')
  }

  protected completed(val: string) {
    this.digitsCompleted = true
    this.bookRequest.plate = val
  }

  protected bookAndGo() {
    this.book((reservation) => {
      if (reservation) {
        this.$router.push({name: 'reservation', params: {id: reservation.id}})
      }
    })
  }

  protected book(callback?: (r?: ReservationResponse) => void) {
    return this.openDialog(new Dialog(VehicleBookingAddMemoDialog, {
      vehicle: this.vehicle,
      bookRequest: this.bookRequest,
      confirmCallback: () => {
        this.sendBooking(callback)
      },
    }))
  }

  protected openQr() {
    let qrImage

    switch (this.vehicle.category.type.toLowerCase()) {
      case 'car':
        qrImage = 'img/icons/carqr.svg'
        break
      case 'bike':
        qrImage = 'img/icons/bikeqr.svg'
        break
      case 'scooter':
        qrImage = 'img/icons/scooterqr.svg'
        break
      case 'kickscooter':
        qrImage = 'img/icons/kickscooterqr.svg'
        break
    }

    this.openPopup(new Popup(QrScanner, {qrImage}, null, true))
  }
}
