




































































































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import moment from 'moment'

import {Popup, ReservationResponse} from '@/lib/kepler/interfaces'
import {
  VBtn,
  VDialog,
  VProgressLinear,
  VSelect,
  VSpacer,
  VCalendar,
  VLayout,
  VFlex,
} from 'vuetify/lib'
import CardReservation from '@/components/entities/reservation/CardReservation.vue'
import LightModeCardReservation from '@/components/entities/reservation/LightModeCardReservation.vue'
import DateHelper from '@/lib/DateHelper'
import HistoryBookingDetail from '@/views/Booking/HistoryBookingDetail.vue'
import sdk from '@/lib/kepler/sdk'

interface EnrichedReservationResponse extends ReservationResponse {
  percentage: { start: number, end: number }
  color: string
}

@Component({
  components: {
    LightModeCardReservation,
    CardReservation,
    Icon: Utils.loadComponent('proxy/Icon'),
    GradientCard: Utils.loadComponent('GradientCard'),
    VLayout,
    VFlex,
    VBtn,
    VCalendar,
    VSelect,
    VSpacer,
    VDialog,
    VProgressLinear,
  },
})

export default class LightActivitiesCalendar extends Vue {
  @State((state) => state.booking.activeReservations) public active!: ReservationResponse[]
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('flushAndReloadHistory') public flushAndReloadHistory!: () => Promise<any>

  protected today = moment().format('YYYY-MM-DD')
  protected start = this.today
  protected loading: boolean = false

  protected calendarTypes = ['month', 'week'] // also 'day'
  protected intervalTypes = [
    {text: moment.duration(6, 'hours').humanize(), value: 4},
    {text: moment.duration(4, 'hours').humanize(), value: 6},
    {text: moment.duration(2, 'hours').humanize(), value: 12},
    {text: `1 ${DateHelper.parseMinutes(this, 60)}`, value: 24},
  ]

  protected test = `1 ${DateHelper.parseMinutes(this, 60)}`

  protected calendarType = 'month'
  protected calendarStart: string | null = null
  protected calendarEnd: string | null = null
  protected calendarMonth: string | null = null
  protected calRef: any = null
  protected dayIntervals: number = 4

  protected dialogRes: EnrichedReservationResponse[] = []
  protected reservations: { [month: string]: ReservationResponse[] } = {}

  protected get calendarizedReservations(): { [p: string]: EnrichedReservationResponse[] } {
    const obj: { [date: string]: EnrichedReservationResponse[] } = {}
    const curr = moment(this.calendarStart)
    const reservations = this.reservations[curr.format('YYYY-MM')] || []
    if (!reservations.length) {
      return obj
    }
    while (curr.isSameOrBefore(moment(this.calendarEnd))) {
      obj[curr.format('YYYY-MM-DD')] = reservations.filter((r) => {
        if (r.start_timestamp && r.end_timestamp) {
          const start = moment.unix(r.start_timestamp)
          const end = moment.unix(r.end_timestamp)
          return start.isSameOrBefore(curr, 'day') && end.isSameOrAfter(curr, 'day')
        }
      }).map((r) => {
        const percentage = this.reservationPercentageOfDay(r, curr.format('YYYY-MM-DD'))
        const match = r.memo?.match(/#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/gm)
        const color = match?.length ? match[0] : String(this.$branding.theme.accent)
        return {...r, percentage, color}
      }).sort((r1, r2) => {
        return (r1.start_timestamp || 0) - (r2.start_timestamp || 0)
      })
      curr.add(1, 'day')
    }
    return obj
  }

  protected get todayInRange() {
    const start = moment(this.calendarStart)
    const end = moment(this.calendarEnd)

    return moment().isBetween(start, end)
  }

  protected get humanizedCalendarMonth() {
    if (!this.calendarMonth) {
      return null
    }
    return moment().month(Number(this.calendarMonth) - 1).format('MMMM')
  }

  protected get humanizedCalendarMonths() {
    const arr = Array.from(Array(12).keys())
    return arr.map((month) => moment().month(month).format('MMMM'))
  }

  protected changeMonth(month: string) {
    if (month === 'today') {
      month = moment().month().toString()
    }
    const newDate = moment().month(month).startOf('month')
    const diff = newDate.diff(moment(this.calendarStart), 'months')
    this.calRef.move(diff)
  }

  protected getReservations() {
    this.loading = true
    if (this.calendarStart && this.calendarEnd) {
      sdk.reservation.get(this.calendarStart, this.calendarEnd).then((r) => {
        const items = r.data.items.filter((i) => i.status !== 'CANCELLED')
        const monthString = moment(this.calendarStart).format('YYYY-MM')
        Vue.set(this.reservations, monthString, items)
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected calendarChange(date: { start: any, end: any }) {
    this.calendarStart = date.start.date
    this.calendarEnd = date.end.date
    this.calendarMonth = date.start.month
    this.getReservations()
  }

  protected waitForRef(refName: string) {
    let count = 100
    return new Promise((resolve, reject) => {
      const timeout = () => {
        setTimeout(() => {
          if (this.$refs.hasOwnProperty(refName)) {
            resolve(this.$refs[refName])
          } else if (count <= 0) {
            reject()
          } else {
            count--
            timeout()
          }
        }, 10)
      }
      timeout()
    })
  }

  protected reservationPercentageOfDay(r: ReservationResponse, day: string) {
    const startOfDayTimestamp = moment(day).startOf('day').unix()
    const endOfDayTimestamp = moment(day).endOf('day').unix()
    const dayLength = endOfDayTimestamp - startOfDayTimestamp
    const startTimestamp = Math.max(r.start_timestamp || 0, startOfDayTimestamp)
    const endTimestamp = Math.min(r.end_timestamp || Infinity, endOfDayTimestamp)

    const duration = Math.round((endTimestamp - startTimestamp) / dayLength * 100)

    const start = Math.round((startTimestamp - startOfDayTimestamp) / dayLength * 100)
    const end = start + duration

    return {start, end}
  }

  protected openResDialog(ev: { date: string }) {
    this.dialogRes = this.calendarizedReservations[ev.date]
  }

  protected closeResDialog() {
    this.dialogRes = []
  }

  protected isFuture(r: ReservationResponse) {
    if (r.start_timestamp) {
      const start = moment(r.start_timestamp, 'X')
      return moment().isBefore(start)
    }
  }

  // burrumballa from FullListHistory, could probably be refactored

  protected openDetail(res: ReservationResponse) {
    this.closeResDialog()
    const now = DateHelper.getTimestamp()
    const start = res.start_timestamp
    const end = res.end_timestamp
    let current: boolean = false
    if (start && end) {
      current = now > start && end >= now
    }

    if (!this.isActive(res) || !current) {
      this.openPopup(new Popup(
          HistoryBookingDetail,
          {res},
          this.$t('booking.reservation', {number: res.number}),
        ),
      )
    } else {
      this.$router.push({name: 'reservation', params: {id: res.id}})
    }
  }

  protected isActive(reservation: ReservationResponse): boolean {
    const active = this.active.find((activeRes) => {
      return activeRes.id === reservation.id
    })

    // checks for closed FF reservations, pretty much redundant but meh.
    if (reservation.type === 'FF' && reservation.status === 'CONFIRMED' && !active) {
      if (reservation.end_timestamp && (DateHelper.getTimestamp() - reservation.end_timestamp) < 0) {
        this.getReservations()
      }
    }
    // reservation is in active reservations
    return !!active
  }

  // end of burrumballa

  protected mounted() {
    this.$nextTick(() => {
      this.waitForRef('calendar').then((ref) => {
        this.calRef = ref
        this.calRef.checkChange()
      })
    })
  }

}
