




















import {Component, Prop} from 'vue-property-decorator'
import Card from './proxy/Card/Card.vue'
import Container from './proxy/Container.vue'
import Layout from './proxy/Layout.vue'
import Flex from './proxy/Flex.vue'
import Avatar from './proxy/Avatar.vue'
import Icon from './proxy/Icon.vue'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'
import {mixins} from 'vue-class-component'

@Component({
  components: {
    Card,
    Container,
    Layout,
    Flex,
    Avatar,
    Icon,
  },
  mixins: [CustomColorableMixin],
})
export default class CardButton extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @Prop({}) public circleColor?: string
  @Prop({}) public icon?: string
  @Prop({}) public iconColor?: string
  @Prop({}) public tile?: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) declare public ripple?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public wrap?: boolean
  @Prop({
    type: String,
    default: () => 'start',
  }) public justify?: string
  @Prop({
    type: String,
    default: () => 'center',
  }) public align?: string
  @Prop({type: String}) public action?: string
  @Prop({type: String}) public description?: string
  @Prop({type: String}) public background?: string
  public themeClasses: any

  public get checkIsLight() {
    if (this.circleColor) {
      return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.circleColor))
    }
    return this.isLight
  }

  public get classes() {
    return ['justify-' + this.justify, 'align-' + this.align]
  }
}
