






import {Vue, Component, Watch} from 'vue-property-decorator'
import {EventBus} from '@/main'
import Utils from '@/utils'

@Component({
  components: {
    ConfigLoader: Utils.loadView('ConfigLoader'),
    TopBar: Utils.loadComponent('TopBar'),
    Btn: Utils.loadComponent('proxy/Btn'),
    BottomNav: Utils.loadComponent('proxy/BottomNav'),
  },
})
export default class Home extends Vue {
  protected isConfigLoaded: boolean = false

  @Watch('$route')
  protected onRouteUpdate() {
    if (this.$route.path === '/') {
      this.isConfigLoaded = false
    }
  }

  protected created() {
    EventBus.$on('login', () => {
      this.isConfigLoaded = false
    })
  }
}
