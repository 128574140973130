









import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {State} from 'vuex-class'
import {Popup} from '@/lib/kepler/interfaces'
import {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'

@Component({
  name: 'TopBarPopUp',
  components: {
    Toolbar: Utils.loadComponent('proxy/Toolbar'),
    ToolbarTitle: Utils.loadComponent('proxy/ToolbarTitle'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  mixins: [CustomColorableMixin],
})
export default class TopBarPopUp extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @State('popupState') public popupState!: Popup[]
  @State((state) => state.profile.language) public lang!: string
  @Prop() public topBarTitle!: string

  public get title() {
    if (this.popupState.length) {
      const title = this.popupState[this.popupState.length - 1].popupTitle
      const metaTitle = title ? title : ''
      return this.topBarTitle ? this.topBarTitle : metaTitle
    }
  }
}
