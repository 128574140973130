










import {Vue, Component, Prop} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {DialogState} from '@/store/modules/dialogs'
import {PopupState} from '@/store/modules/popups'
import Utils from '@/utils'

@Component({
  components: {
    OfflineAlert: Utils.loadComponent('OfflineAlert'),
    TopBarPopUp: Utils.loadComponent('TopBarPopUp'),
  },
})
export default class AppModals extends Vue {
  @State('popupState') public popupState!: typeof PopupState
  @State('dialogState') public dialogState!: typeof DialogState
  @Action('closeOverlays') public closeOverlays!: () => void
  @Getter('popupIsOpen') public popupIsOpen!: boolean
  @Getter('dialogIsOpen') public dialogIsOpen!: boolean
  @Prop({type: Boolean, default: true}) public online!: boolean

  public created() {
    this.closeOverlays()
  }
}
