
























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import moment from 'moment'
import {rangeListItem, RangeTuple} from '@/lib/kepler/interfaces'

@Component({
  name: 'RangeGrid',
  components: {
    RangeButton: Utils.loadComponent('rangeSelector/rangeButton'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class RangeGrid extends Vue {
  @Prop({type: Array, default: () => []}) public list!: rangeListItem[]
  @Prop({type: String, default: () => 'accent'}) public color!: string
  @Prop({type: Boolean, default: false}) public extend!: boolean

  protected rows: number = 0
  protected selectedRange: RangeTuple = [null, null]

  protected isStartOfDay(t: number) {
    return t === moment.unix(t).startOf('day').unix()
  }

  protected timeBreak(t: number) {
    return moment.unix(t).calendar(undefined, {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: `[${this.$t('booking.tomorrow')}]`,
      lastWeek: '[last] dddd',
      nextWeek: 'dddd',
      sameElse: 'L',
    })
  }

  protected label(t: number, d?: boolean) {
    return d ? moment.unix(t).format('L LT') : moment.unix(t).format('HH:mm')
  }

  protected selected(index: number) {
    if (this.extend) {
      if (index === this.selectedRange[1]) {
        return
      }
      this.$set(this, 'selectedRange', [0, index])
    } else {
      const newRange = [...this.selectedRange]
      if (newRange[1] !== null || newRange[0] === null) {
        newRange[0] = null
        this.$emit('rangeReset', index)
      }
      newRange.unshift(index)
      newRange.pop()
      this.$set(this, 'selectedRange', newRange)
    }
  }

  @Watch('selectedRange', {deep: true, immediate: false})
  protected onSelectionChange(range: RangeTuple) {
    const arr = [...range]
    if (range[0] !== null && range[1] !== null) {
      this.$emit('rangeSelected', arr.sort((a, b) => a! - b!))
    }
  }
}
