
import {Component} from 'vue-property-decorator'
import BaseFlow from '@/views/Flows/BaseFlow.vue'
import {Action} from 'vuex-class'

@Component({})
export default class FlowQuickRegistration extends BaseFlow {
  @Action('editField') public editField!: (payload: { path: Array<string | number>, value: any }) => void
  @Action('editInputFlow') public editInputFlow!: (payload: { path: Array<string | number>, value: any }) => void

  private get quickRegistrationSteps() {
    return this.flowInputs?.quick_registration?.steps
  }

  public exitForward() {
    if (this.$route.name !== 'home') {
      this.$router.push({name: 'home'})
    }
    this.closePopup()
    this.purgeFlow('quick_registration')
    this.getPersistent()
  }

  public getName(): string {
    return 'quick_registration'
  }

  public onFlowLoad() {
    this.preloadImages()
    this.handleClientToken()
  }

  public handleClientToken() {
    if (this.$route.query.hasOwnProperty('client_token')) {
      // set client_token
      this.editField({
        path: ['quick_registration', 'client_token'],
        value: this.$route.query.client_token,
      })
      // remove field from flow inputs
      this.quickRegistrationSteps?.forEach((step, stepIdx) => {
        if (step.fields) {
          const fieldIdx = step.fields?.findIndex((field) => field.type === 'invite')
          if (fieldIdx >= 0) {
            this.editInputFlow({
              path: ['quick_registration', 'steps', stepIdx, 'fields', fieldIdx, 'options'],
              value: 'hidden',
            })
          }
        }
      })

      // remove token from url
      this.$router.replace({query: {client_token: undefined}})
    }
  }

  public preloadImages() {
    const imageBag: HTMLImageElement[] = []
    this.quickRegistrationSteps?.forEach((s) => {
      if (s.image) {
        const image = new Image()
        image.setAttribute('rel', 'preload')
        image.src = s.image
        imageBag.push(image)
      }
    })
    imageBag.length = 0
  }
}
