






















import {Vue, Component} from 'vue-property-decorator'
import {Action, State, Getter} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {Driver, Dialog, SubscriptionPlan, Subscription} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import AddDriver from '@/components/AddDriver.vue'
import MyDriversProfile from './MyDriversProfile.vue'
import {VListTileActionText} from 'vuetify/lib'

@Component({
  components: {
    List: Utils.loadComponent('proxy/List/List'),
    CardList: Utils.loadComponent('CardList'),
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    Img: Utils.loadComponent('proxy/Image'),
    ProfileWithImage: Utils.loadComponent('ProfileWithImage'),
    VListTileActionText,
  },
  name: 'Drivers',
})

export default class Drivers extends Vue {
  @State('profile') public profileState!: ProfileState
  @Getter('driversByPlan') public driversByPlan!: Record<string, string[]>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: () => void
  @Action('getDrivers') public getDrivers!: () => Promise<void>
  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>

  protected loading: boolean = false

  protected created() {
    this.loading = true
    Promise.allSettled([this.getDrivers(), this.getPlans(), this.getSubscriptions()])
      .finally(() => {
        this.loading = false
      })
  }

  protected inviteDriver() {
    this.openDialog(new Dialog(AddDriver, {}))
  }

  protected openDriverDetail(driver: Driver) {
    this.openDialog(new Dialog(MyDriversProfile, {driver}, `Driver ${driver.id}`))
  }

  protected get unassignedDrivers() {
    const assigned = [...new Set(Object.values(this.driversByPlan).flat())]
    return this.profileState.drivers.filter((d) => !assigned.includes(d.id)).map((d1) => d1.id)
  }

  protected statusFormatted(status: string) {
    const str = status.replace(' ', '_').toLowerCase()
    return {
      status: this.$t(`profile.status.${str}`),
      color: (() => {
        switch (status) {
          case 'ACTIVE':
            return 'success'
          case 'SUSPENDED':
            return 'error'
          case 'PENDING REVIEW':
            return 'warning'
          default:
            return 'accent'
        }
      })(),
    }
  }
}
