













import {Vue, Component, Prop} from 'vue-property-decorator'
import {CheckItem, TerminateRequest} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'

interface Payload { [id: string]: boolean }

@Component({
  components: {
    ProgressCircular,
    ConfirmDialogCallback: Utils.loadView('ConfirmDialogCallback'),
    Checklist: Utils.loadComponent('Checklist'),
  },
})
export default class ForceTerminateChecklist extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Prop({required: true, type: Array, default: () => []}) private list!: CheckItem[]
  @Prop({required: false, type: String, default: () => ''}) private title!: string
  @Prop({required: false, type: String, default: () => ''}) private description!: string
  @Prop({required: true, type: Function}) private cb!: (payload: Partial<TerminateRequest>) => void

  private payload: Pick<TerminateRequest, 'user_declaration'> = {}
  private loading: boolean = false

  private setPayload(v: Array<{ [id: string]: boolean }>) {
    this.$set(this.payload, 'user_declaration', v)
  }
  private sendPayload() {
    this.loading = true
    this.cb(this.payload)
  }
}
