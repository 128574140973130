




import {Vue, Component, VModel} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {AppConfig, Popup} from '@/lib/kepler/interfaces'
import {FlowInputsState} from '@/store/modules/flowInputs'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import Loader from '@/components/Loader.vue'

@Component({
  components: {Loader},
  name: 'ConfigLoader',
})

export default class ConfigLoader extends Vue {
  @State((state) => state.profile.language) public lang!: string
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Action('loadConfig') public loadConfig!: () => Promise<AppConfig>
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('getFlows') public getFlows!: () => Promise<void>

  @Getter('autoOpenQueue') public autoOpenQueue!: string[]
  @Getter('popupIsOpen') public popupIsOpen!: number
  @Getter('dialogIsOpen') public dialogIsOpen!: number

  @VModel({type: Boolean}) public configLoaded!: boolean

  public spinnerUrl = 'img/loader.gif'
  public env = this.$env

  protected created() {
    if (this.$route.name === 'home') {
      this.loadConfig().then((r) => {
        if (r.default_language) {
          this.$langLoader(this.lang ? this.lang : r.default_language)
        }
        this.configLoaded = true
      })
      this.getFlows().then(() => {
        if (this.autoOpenQueue.length) {
          this.openFlow(this.autoOpenQueue[0])
        }
      })
    } else {
      this.configLoaded = true
    }
  }

  protected openFlow(flowName: string) {
    const flow = this.flowInputs[flowName]
    const flowHasSteps = () => {
      const allHidden = flow.steps.every((s) => s.skip)
      return !(!flow.steps?.length || allHidden)
    }
    const itsAGoodPlaceToOpenFlow = this.$route.name === 'home' && !this.popupIsOpen && !this.dialogIsOpen
    if (flow && flowHasSteps() && itsAGoodPlaceToOpenFlow) {
      this.openPopup(new Popup(FlowAutoload, {flowName}, null, true))
    }
  }
}
