








import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {VLayout, VFlex, VIcon} from 'vuetify/lib'

@Component({
  components: {
    VLayout,
    VFlex,
    VIcon,
  },
})
export default class CloseButton extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Prop({type: Boolean}) protected dialog!: boolean
  @Prop({type: Boolean, default: false}) protected absolute!: boolean

  @Prop({}) protected color!: string
  @Prop({default: false}) protected left!: boolean
  @Prop({type: String, default: () => 'mdi-close'}) protected icon!: string
  @Prop({type: Function}) protected closeCallback!: () => void
  @Prop({type: Boolean, default: false}) protected onlyCallback!: boolean

  protected closeAction() {
    if (this.closeCallback) {
      this.closeCallback()
    }
    if (this.onlyCallback) {
      return
    }
    if (this.dialog) {
      this.closeDialog()
    } else {
      this.closePopup()
    }
  }
}
