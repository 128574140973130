






















































































































































































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {
  Attachment,
  Popup,
  Dialog,
  SubscriptionPlan,
} from '@/lib/kepler/interfaces'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import {ProfileState} from '@/store/modules/profile'
import {EventBus} from '@/main'
import Utils from '@/utils'

import {FlowInputsState} from '@/store/modules/flowInputs'
import {VMenu, VSlider} from 'vuetify/lib'
import ChecklistItem from '@/components/ChecklistItem.vue'
import Checklist from '@/components/Checklist.vue'
import BrowserCamera from '@/components/BrowserCamera.vue'
import Img from '@/components/proxy/Image.vue'
import ImageCrop from '@/components/ImageCrop.vue'
import Flex from '@/components/proxy/Flex.vue'
import CameraDialog from '@/views/uploader_flow/CameraDialog.vue'
import DevCamera from '@/views/Dev/DevCamera.vue'
import CameraPreset from '@/lib/camera/cameraPresets'
import {blobToDataURL} from '@/lib/BlobHelper'
import Sheet from '@/components/proxy/Sheet.vue'
import FiltersLayout from '@/components/filters/FiltersLayout.vue'
import List from '@/components/proxy/List/List.vue'
import ListTile from '@/components/proxy/List/ListTile.vue'
import ListTileTitle from '@/components/proxy/List/ListTileTitle.vue'
import {LogColorByLevel} from '@/lib/plugins/logger'
import moment from 'moment'
import QrScanner from '@/components/QrScanner.vue'

@Component({
  components:
    {
      DevDialogs: Utils.loadView('Dev/DevDialogs'),
      Branding: Utils.loadComponent('dev/Branding'),
      VehicleIcon: Utils.loadComponent('VehicleIcon'),
      ListTileTitle,
      ListTile,
      List,
      FiltersLayout,
      Sheet,
      CameraButton: Utils.loadComponent('uploader/CameraButton'),
      DevCamera,
      CameraDialog,
      Flex,
      ImageCrop,
      Img,
      BrowserCamera,
      Checklist,
      ChecklistItem,
      VSlider,
      VMenu,
      AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
      CardSubscription: Utils.loadComponent('subscriptions/CardPlan'),
      Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
      TextField: Utils.loadComponent('proxy/Inputs/TextField'),
      SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
      PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
      CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
      CardWithReport: Utils.loadComponent('CardWithReport'),
      CardText: Utils.loadComponent('proxy/Card/CardText'),
      TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
      MonthSwitcher: Utils.loadComponent('MonthSwitcher'),
      TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
      Container: Utils.loadComponent('proxy/Container'),
      GradientCard: Utils.loadComponent('GradientCard'),
      StatusLabel: Utils.loadComponent('StatusLabel'),
      FlowTest: Utils.loadComponent('flow/FlowTest'),
      CustomIcon: Utils.loadComponent('CustomIcon'),
      Divider: Utils.loadComponent('proxy/Divider'),
      Card: Utils.loadComponent('proxy/Card/Card'),
      Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
      Layout: Utils.loadComponent('proxy/Layout'),
      LoopingBG: Utils.loadComponent('LoopingBG'),
      Avatar: Utils.loadComponent('proxy/Avatar'),
      Tab: Utils.loadComponent('proxy/Tabs/Tab'),
      Badge: Utils.loadComponent('proxy/Badge'),
      Chip: Utils.loadComponent('proxy/Chip'),
      Icon: Utils.loadComponent('proxy/Icon'),
      Btn: Utils.loadComponent('proxy/Btn'),
      TopBar: Utils.loadComponent('TopBar'),
      Button: Utils.loadComponent('Button'),
    },
})

export default class Dev extends Vue {
  @State('profile') public profileState!: ProfileState
  @State('termOfServices') public TermsOfServiceState!: TermOfServicesState
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State((state) => state.logs) public logs!: string[]

  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('setDebugMode') public setDebugMode!: (enabled: boolean) => void
  @Action('setTranslationMode') public setTranslationMode!: (enabled: boolean) => void
  @Action('setDebugTab') public setTab!: (tab: number) => void
  @Action('addProfilePicture') public addProfilePicture!: any
  @Action('clearLogs') public clearLogs!: () => void
  @Action('toggleDevFlow') public toggleDevFlow!: () => void
  @Action('switchProfile') public switchProfile!: (token: string) => Promise<void>

  @Getter('debugTab') public debugTab!: number
  @Getter('debugMode') public debugMode!: boolean
  @Getter('translationMode') public translationMode!: boolean
  public testBool1: boolean = false
  public reversedLogs: boolean = true
  public flowName: string = ''
  public flowPage: number = 0
  public cameraPresets = CameraPreset.prototype.getPresets()
  public cameraResult: string | null = null
  public cameraResultToken: string | null = null
  public uploadResultUrl: string | null = null
  public qrContent: string = ''
  public token: string = ''
  public tokenSwitching: boolean = false

  public tabs = [
    'misc',
    'camera',
    'dialogs/popups',
    'flow',
    'branding',
    'typography',
    'buttons',
    'animated background',
    'biometrics',
    'filters',
    'log',
    'token',
  ]

  private checkList = [
    {
      id: 'item1',
      title: 'ignition is off',
      text: 'I\'ve checked the control panel and there are no blinking lights.',
      icon: 'mdi-key-variant',
    },
    {
      id: 'item2',
      title: 'combobulator is off',
      description: 'I don\'t know what it is but I\'ve checked very carefully.',
      icon: 'mdi-lock-outline',
    },
    {
      id: 'item3',
      title: 'reaction control system is nominal',
      description: 'Also, I\'ve degaussed the flywheels thoroughly.',
      icon: 'mdi-flash-off',
    },
    {
      id: 'item4',
      title: 'I\'ve parked decently',
      description: 'I\'ve checked with mission control the stability of the orbit.',
      icon: 'mdi-parking',
    },
  ]
  private checkListValues: Record<string, boolean> = {}

  public get currentTab() {
    return !!this.debugTab ? Number(this.debugTab) : 0
  }

  public set currentTab(tab: number) {
    this.setTab(tab)
  }

  public get devModeOn() {
    return this.debugMode
  }

  public set devModeOn(enabled: boolean) {
    this.setDebugMode(enabled)
  }

  public get translationModeOn() {
    return this.translationMode
  }

  public set translationModeOn(enabled: boolean) {
    this.setTranslationMode(enabled)
  }

  public get testBool2() {
    return !this.testBool1
  }

  public set testBool2(val) {
    this.testBool1 = !val
  }

  public get flowNames() {
    const arr: string[] = []
    Object.keys(this.flowInputs).forEach((s) => {
      arr.push(s)
    })
    return arr
  }

  public get selectedFlow() {
    if (this.flowNames && this.flowName && this.flowNames.includes(this.flowName)) {
      return this.flowInputs[this.flowName]
    }
    return null
  }

  public get flowPages() {
    return this.selectedFlow ? this.selectedFlow.steps.length - 1 : null
  }

  public get formattedLogs() {
    const result = this.logs.map((l) => {
      let timestamp: string | null
      let text: string

      if (l.includes('>>')) {
        [timestamp, text] = l.split('>>')
      } else {
        text = l
        timestamp = null
      }
      return {
        text,
        time: timestamp ? moment(Number(timestamp)).calendar(null, {
          sameDay: 'LTS',
          lastDay: 'DD/MM LTS',
          lastWeek: 'DD/MM LTS',
          sameElse: 'DD/MM LTS',
        }) : null,
        color: this.logColor(text),
      }
    })
    if (this.reversedLogs) {
      return result.reverse()
    }
    return result
  }

  @Watch('selectedFlow', {immediate: true})
  public onSelectedFlowChange() {
    this.flowPage = 0
  }

  public changePage(step: number) {
    this.flowPage = step
  }

  public mounted() {
    this.getPlans()
    this.$log('playmooooooooooooooooove', 0)
  }

  public beforeDestroy() {
    EventBus.$off('dialogConfirm')
    EventBus.$off('qrScanned')
  }

  public biometricsAvailable() {
    const f = (window as any).Fingerprint
    if (f) {
      const fx = new Promise((resolve, reject) => {
        f.isAvailable(resolve, reject)
      })
      const alert = (r: any) => {
        alert(r)
      }
      fx.then(alert).catch(alert)
    } else {
      alert('not available')
    }
  }

  public biometricsShow() {
    return new Promise((resolve, reject) => {
      (window as any).Fingerprint.isAvailable(resolve, reject)
    })
  }

  public exitForward() {
    alert('exit forward')
  }

  public exitBack() {
    alert('exit back')
  }

  protected gotUploadResponse(a: Attachment) {
    this.cameraResultToken = a.token
    this.uploadResultUrl = a.url
  }

  protected openLink(url: string) {
    cordova.InAppBrowser.open(url, '_system')
  }

  private setChecklist(v: Record<string, boolean>) {
    this.checkListValues = v
  }

  private gotPhoto(r: string) {
    this.cameraResult = r
    this.$log('got string', 0)
  }

  private gotBlob(b: Blob) {
    this.$log('got blob', 0)
    blobToDataURL(b).then((r) => {
      this.cameraResult = (r as string)
    })
  }

  private resetCameraResults() {
    this.cameraResult = null
    this.cameraResultToken = null
  }

  private testLog() {
    this.$log('[test] all is fine.', 0)
    this.$log('[test] this is fine', 1)
    this.$log('[test] this is not fine', 2)
    this.$log('[test] EVERYTHING IS BURNING', 3)
  }

  private copyLogs() {
    navigator.clipboard.writeText(JSON.stringify(this.logs, undefined, 2))
  }

  private logColor(log: string) {
    const level = log.split('|')[0].trim()
    return (LogColorByLevel as { [key: string]: string })[level]
  }

  private loginFromToken(token: string) {
    this.tokenSwitching = true
    this.switchProfile(token).finally(() => {
      this.tokenSwitching = false
    }).then(() => {
      this.token = ''
      this.$router.push({name: 'home'})
    })
  }

  private openQRScanner() {
    this.openPopup(new Popup(QrScanner, {qrImage: ''}, null, true))
    EventBus.$on('qrScanned', (code: string) => {
      this.qrContent = code
    })
  }
}
