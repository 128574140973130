







































import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {Driver, Subscription, SubscriptionPlan} from '@/lib/kepler/interfaces'
import moment from 'moment'
import Utils from '@/utils'
import {PlanColorMixin} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    Alert: Utils.loadComponent('proxy/Alert'),
    Container: Utils.loadComponent('proxy/Container'),
    CardGrid: Utils.loadComponent('CardGrid'),
    Img: Utils.loadComponent('proxy/Image'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    CardSubscription: Utils.loadComponent('subscriptions/CardSubscription'),
  },
  name: 'MySubscriptions',
})
export default class MySubscriptions extends mixins<PlanColorMixin>(PlanColorMixin) {
  @State('profile') public profileState!: ProfileState
  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null
  @Getter('driversByPlan') public driversByPlan!: Record<string, string[]>

  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>
  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>

  protected showExpired: boolean = false

  protected get subscriptions() {
    const subscriptions = this.profileState.subscriptions || []

    if (this.defaultPlan) {
      const defaultSubs: Subscription = {
        auto_renew: false,
        drivers: [],
        end: '',
        end_timestamp: 0,
        id: 'default',
        next_due_date: '',
        next_due_date_timestamp: 0,
        plan: this.defaultPlan,
        start: '',
        start_timestamp: 0,
        subscribed_drivers: this.driversByPlan[this.defaultPlan.id].length,
        toc_id: '',
      }
      if (!subscriptions.find((s) => s.id === 'default')) {
        const idx = subscriptions.findIndex((sub) => (this.isActive(sub) || this.hasDefaultPlan(sub)))
        subscriptions.splice(idx, 0, defaultSubs)
      }
    }

    return subscriptions.filter((sub) => {
      return this.showExpired ? true : (this.isActive(sub) || this.hasDefaultPlan(sub))
    })
  }

  protected get hasExpired() {
    const subscriptions = this.profileState.subscriptions || []
    return !!subscriptions.find((sub) => {
      return !(this.isActive(sub) || this.hasDefaultPlan(sub))
    })
  }

  protected hasDefaultPlan(subscription: Subscription) {
    return this.defaultPlan?.id === subscription.plan.id
  }

  protected mounted() {
    this.getPlans()
    this.getSubscriptions()
  }

  protected openPlansPage() {
    this.$router.push({name: 'plans'})
  }

  protected get drivers(): Record<string, Driver[]> {
    const result: any = {}
    Object.entries(this.driversByPlan).map(([planId, drivers]) => {
      result[planId] = this.profileState.drivers.filter((driver) => drivers.includes(driver.id))
    })

    return result
  }

  protected isActive(s: Subscription) {
    const now = moment()
    const end = moment.unix(s.end_timestamp)
    return now.isBefore(end)
  }

  protected planColors(p: SubscriptionPlan) {
    return this.getPlanColors(p, this.defaultPlan?.id === p.id)
  }

  protected planCSS(p: SubscriptionPlan) {
    return this.getPlanCSS(this.defaultPlan?.id === p.id)
  }
}
