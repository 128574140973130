import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import Vue from 'vue'
import {RootState} from '@/store'

type SlotNames = 'icon_top_bar' | 'alert_bar' | 'profile' | 'pre_booking' | 'post_booking'
export type FlowPersistent = {
  [Property in SlotNames]?: {
    [flowName: string]: {
      icon: string | null,
      description: string | null,
      color: string | null,
    };
  }
}

class FlowPersistentState implements FlowPersistent {}

const mutations: MutationTree<FlowPersistentState> = {
  PURGE_PERSISTENT(stateF) {
    for (const v in stateF) {
      if (stateF.hasOwnProperty(v)) {
        Vue.delete(stateF, v)
      }
    }
  },
  SAVE_PERSISTENT(stateF, payload: FlowPersistentState) {
    Object.assign(stateF, payload)
  },
}

const actions: ActionTree<FlowPersistentState, RootState> = {
  init({dispatch}) {
    dispatch('getPersistent')
  },
  purge({dispatch}) {
    dispatch('purgePersistent')
  },
  purgePersistent({commit}) {
    commit('PURGE_PERSISTENT')
  },
  getPersistent({rootGetters, commit}) {
    if (rootGetters.isLogged) {
      return sdk.flows.getNotifications().then((r) => {
        commit('PURGE_PERSISTENT')
        commit('SAVE_PERSISTENT', r.data)
        return r.data
      })
    }
  },
}

export default {
  state: new FlowPersistentState() as FlowPersistent,
  mutations,
  actions,
}
