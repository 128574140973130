





























import {Vue, Component, Prop, VModel} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {VFlex, VIcon, VLayout} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Utils from '@/utils'
import moment from 'moment'

interface InnerValue {
  date: string | null,
  time: string | null
}

@Component({
  components: {
    VLayout,
    VFlex,
    VIcon,
    ValidationProvider,
    DateInput: Utils.loadComponent('fields/Date'),
    InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
    InputTimePicker: Utils.loadComponent('proxy/Inputs/InputTimePicker'),
  },
  name: 'DateTimeField',
})
export default class DateTimeField extends Vue {
  @State((state) => state.profile.language) public lang!: string
  @State((state) => state.configuration.appConfig?.time_slot_dimension || 30) public tsd!: number

  @VModel({
    required: false,
    default: null,
  }) public outerValue!: string | null

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | Record<string, any>
  @Prop({
    type: String,
    required: false,
  }) public readonly name?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public readonly disabled?: boolean
  @Prop({
    type: Number,
    default: null,
  }) public readonly min!: number | null
  @Prop({
    type: Number,
    default: null,
  }) public readonly max!: number | null
  @Prop({
    type: String,
    default: () => 'mdi-calendar',
  }) public readonly icon!: string
  @Prop({
    type: String,
    default: () => '',
  }) public readonly dateLabel!: string
  @Prop({
    type: String,
    default: () => '',
  }) public readonly timeLabel!: string

  public timeSlots: Array<{ text: string, value: string }> | string[] = []

  public get parsedOuter() {
    const m = moment(this.outerValue, 'YYYY-MM-DD HH:mm:ss')
    return m.isValid() ? m : null
  }

  public get bounds() {
    if (this.parsedOuter && !this.inBounds(this.parsedOuter)) {
      this.outerValue = null
    }
    this.calcTimeSlots()
    return {
      min: this.min ? this.checkSkipDay(moment.unix(this.min)).format('YYYY-MM-DD') : null,
      max: this.max ? moment.unix(this.max).format('YYYY-MM-DD') : null,
    }
  }

  public get innerValue() {
    const obj: { date: string | null; time: string | null } = {date: null, time: null}
    if (this.parsedOuter !== null) {
      obj.date = this.parsedOuter.format('YYYY-MM-DD')
      obj.time = this.parsedOuter.format('HH:mm:ss')
    }
    return obj
  }

  public set innerValue(v: InnerValue) {
    if (this.innerComplete(v)) {
      this.outerValue = `${v.date} ${v.time}`
    } else {
      this.outerValue = null
    }
  }

  public checkSkipDay(min: moment.Moment) {
    const nextSlot = min.clone().add(this.tsd, 'minutes')
    return !nextSlot.isSame(min, 'day') ? nextSlot : min
  }

  public innerComplete(v?: InnerValue) {
    const i = v || this.innerValue
    return !!i.date && !!i.time
  }

  public inBounds(m: moment.Moment) {
    const min = this.min ? moment.unix(this.min).isBefore(m) : true
    const max = this.max ? moment.unix(this.max).isAfter(m) : true

    return min && max
  }

  public calcTimeSlots() {
    if (!this.innerValue.date) {
      return []
    }

    const tsd = this.tsd
    const arr: number[] = []
    const day = moment(this.innerValue.date, 'YYYY-MM-DD').startOf('day')

    const cursor = day.clone()
    while (moment(cursor).isSame(day, 'day')) {
      if (this.inBounds(cursor)) {
        arr.push(cursor.unix())
      }
      cursor.add(tsd, 'minutes')
    }

    this.timeSlots = arr.map((ts) => {
      const u = moment.unix(ts)
      return {
        text: u.format('LT'),
        value: u.format('HH:mm:ss'),
      }
    })
  }

  public setInner(path: 'date' | 'time', val: string | null) {
    const i = JSON.parse(JSON.stringify(this.innerValue))
    i[path] = val
    Vue.set(this, 'innerValue', i)
    Vue.set(this.innerValue, path, val)
    if (path === 'date') {
      this.calcTimeSlots()
    }
  }
}
