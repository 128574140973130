import _Vue from 'vue'
import store from '@/store/index'
import moment from 'moment'
import 'moment/locale/it.js'
import 'moment/locale/es.js'
import 'moment/locale/pt.js'
import 'moment/locale/zh-cn.js'
import 'moment/locale/fr.js'
import 'moment/locale/de.js'
import 'moment/locale/ru.js'
import 'moment/locale/x-pseudo.js'
import {localize} from 'vee-validate'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {Lang, LangValue} from '@/lib/kepler/interfaces'

interface ValidatorLanguage {
  code: string;
  messages: { [key: string]: string };
}

const validatorLanguages: { [code: string]: ValidatorLanguage } = {
  it: require('vee-validate/dist/locale/it.json'),
  en: require('vee-validate/dist/locale/en.json'),
  es: require('vee-validate/dist/locale/es.json'),
  fr: require('vee-validate/dist/locale/fr.json'),
  de: require('vee-validate/dist/locale/de.json'),
  pt: require('vee-validate/dist/locale/pt_PT.json'),
  zh: require('vee-validate/dist/locale/zh_CN.json'),
  sc: require('../../../res/validator_lang/sc.json'),
}

const VeeLocale = (code: string) => {
  localize(code, validatorLanguages[code])
}

moment.defineLocale('sc', {
  parentLocale: 'it',
  months: 'ghennàrgiu_freàrgiu_martzu_abrile_maju_làmpadas_trìulas_austu_cabudanni_ledàmine_onniasantu_nadale'.split('_'),
  monthsShort: 'ghe_fre_mar_abr_maj_làm_trì_aus_cab_led_onn_nad'.split('_'),
  weekdays: 'domìniga_lunis_martis_mèrcuris_giòvia_chenàbura_sàbadu'.split('_'),
  weekdaysShort: 'dom_lun_mar_mèr_giò_che_sàb'.split('_'),
  weekdaysMin: 'do_lu_ma_me_gi_ve_sa'.split('_'),

  calendar: {
    sameDay: '[Oe a is] LT',
    nextDay: '[Cras a is] LT',
    nextWeek: 'dddd [a is] LT',
    lastDay: '[Aiseru a is] LT',

    lastWeek: function() {
      // @ts-ignore next-line
      if (this.day() === 0) {
        return '[sa passada] dddd [a is] LT'
      } else {
        return '[su passau] dddd [a is] LT'
      }
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: function(s) {
      return ((/^[0-9].+$/).test(s) ? 'tra' : 'in') + ' ' + s
    },
    past: '%s fa',
    s: 'unos cantos segundos',
    ss: '%d segundos',
    m: 'unu minutu',
    mm: '%d minutos',
    h: 'un\'ora',
    hh: '%d oras',
    d: 'una die',
    dd: '%d dies',
    M: 'unu mesi',
    MM: '%d mesis',
    y: 'un\'annu',
    yy: '%d annos',
  },
})

const languages: Record<string, Lang> = {
  it: require('../../../res/lang/it_IT/translations.json'),
  sc: require('../../../res/lang/sc/translations.json'),
  en: require('../../../res/lang/en_US/translations.json'),
  es: require('../../../res/lang/es_ES/translations.json'),
  pt: require('../../../res/lang/pt_PT/translations.json'),
  zh: require('../../../res/lang/zh_CN/translations.json'),
  fr: require('../../../res/lang/fr_FR/translations.json'),
  de: require('../../../res/lang/de_DE/translations.json'),
  ru: require('../../../res/lang/ru_RU/translations.json'),
  dbg: {},
}
/*
let lang = languages.en

// crudely set lang by browser setting
if (navigator && navigator.language) {
  if (navigator.language.startsWith('it')) {
    lang = languages.it
  }
}
*/
let loadedLang: string = 'en'
const _checkLangAvailable = (lang: string) => {
  const configLang = store.state.configuration.appConfig.default_language
  const available = _langAvailable()

  if (available.findIndex((l) => l.lang === lang) < 0) {
    return available.length > 1 ? configLang : available[0].lang
  }
  return lang
}
const _langLoader = (defaultLang?: string) => {
  const configLang = store.state.configuration.appConfig.default_language
  // TODO: do something with device preferred language
  const navLang = navigator.language
  // const navLangs = navigator.languages
  const userLang: string = store.state.profile.language
  const selected = userLang !== '' ? userLang : configLang
  if (selected) {
    loadedLang = selected
  } else if (defaultLang) {
    loadedLang = defaultLang
  } else {
    loadedLang = navLang.split('-')[0]
  }

  const potentiallyUnavailableLang = loadedLang
  loadedLang = _checkLangAvailable(loadedLang) as string // TODO: double check this

  if (!userLang || potentiallyUnavailableLang !== loadedLang) {
    store.commit('SET_LANG', loadedLang)
  }

  switch (loadedLang) {
    case 'dbg':
      moment.locale('x-pseudo')
      break
    case 'zh':
      moment.locale('zh-cn')
      VeeLocale('zh-CN')
      break
    case 'pt':
      moment.locale('pt')
      VeeLocale('pt_PT')
      break
    default:
      moment.locale(loadedLang)
      VeeLocale(loadedLang)
  }

  document.getElementsByTagName('html')[0].setAttribute('lang', loadedLang)
  document.getElementsByTagName('html')[0].setAttribute('xml:lang', loadedLang)
  EventBus.$emit('langLoaded', loadedLang)
}
const _t = (key: string | null, replacements: Record<string, string | number> = {}, def?: string): string => {
  if (store.getters.translationMode) {
    return (key) + (Object.keys(replacements).length ? ` (${Object.keys(replacements)})`: '')
  }

  let item: string | undefined
  if (!key) {
    console.warn('trying to translate null value')
    return ''
  } else if (languages[loadedLang]) {
    const trn = Utils.getProp(languages[loadedLang], key.split('.')) as LangValue
    if (typeof trn === 'string') {
      item = trn
      for (const [key, value] of Object.entries(replacements)) {
        item = item.replace(new RegExp(':' + key, 'gi'), value.toString())
      }
    }
  }

  return item || def || key
}
const _currentLang = () => {
  return loadedLang
}
const _langAvailable = () => {
  let allLanguages = Object.keys(languages)
  const langOverride: string | undefined = store.getters.fieldConfigs?.allowedLanguages

  if (langOverride) {
    const overrides = langOverride.split(',')
    allLanguages = allLanguages.filter((item) => overrides.includes(item))
  }

  const langObj: { [key: string]: { lang: string, name: string, code: string } } = {
    it: {lang: 'it', name: 'Italiano', code: 'it'},
    sc: {lang: 'sc', name: 'Sardu', code: 'srd'},
    en: {lang: 'en', name: 'English', code: 'us'},
    es: {lang: 'es', name: 'Español', code: 'es'},
    pt: {lang: 'pt', name: 'Português', code: 'pt'},
    zh: {lang: 'zh', name: '中文', code: 'zh'},
    fr: {lang: 'fr', name: 'Français', code: 'fr'},
    de: {lang: 'de', name: 'Deutsche', code: 'de'},
    ru: {lang: 'ru', name: 'pусский', code: 'ru'},
    dbg: {lang: 'dbg', name: 'DEBUG', code: 'aq'},
  }
  return allLanguages.map((item) => langObj[item])
}
const _langCount = () => {
  const count: Lang = {}
  Object.entries(languages).forEach(([curr, lang]) => {
    const result = {words: 0, letters: 0}

    const countWords = (str: string) => str.trim().split(/\s+/).length
    const countLetters = (str: string) => str.length

    const traverseVal = (obj: object) => {
      Object.values(obj).forEach((val) => {
        if (typeof val === 'string') {
          result.letters += countLetters(val)
          result.words += countWords(val)
        } else if (typeof val === 'object') {
          traverseVal(val)
        }
      })
    }
    traverseVal(lang)

    count[curr] = result
  })
  return count
}
const _currency = (value: number | string): string => {

  const config = store.state.configuration.appConfig
  const currency = config.currency
  const after = config.currency_sign_after
  const space = config.space_after_currency_sign ? ' ' : ''

  if (value === '') {
    return currency
  }
  value = typeof value === 'number' ? value : Number(value)
  if (Number.isNaN(value)) {
    return ''
  }

  if (config.round_price_values) {
    value = Math.round(value)
  }
  return after ? `${value}${space}${currency}` : `${currency}${space}${value}`
}
const _distance = (value?: number | string) => {
  const config = store.state.configuration.appConfig
  const unit = config?.distance_unit || 'km'
  const space = config?.space_before_distance_unit ? ' ' : ''
  return value !== undefined ? `${value}${space}${unit}` : unit
}
const _isAvailable = (str: string) => {
  if (store.getters.translationMode) {
    return `*${_t(str)}`
  }
  if (str) {
    const trn = _t(str)
    return trn && trn !== str ? trn : ''
  }
}
const _isLogged = () => {
  const token = store.state.userToken
  return !(typeof (token) === 'undefined' || token === null || token.trim() === '')
}
const _isBrowser = () => {
  return device.platform === 'browser'
}
const _minutes = (val?: number | string) => {
  if (!val || isNaN(Number(val))) {
    return val
  }
  val = Number(val)

  const shortMap: { [key: number]: string } = {
    1: _t('common.time_units.minute'),
    60: _t('common.time_units.hour'),
  }

  const longMap: { [key: number]: string } = {
    1: _t('common.time_units.day'),
    7: _t('common.time_units.week'),
    30: _t('common.time_units.month'),
    60: _t('common.time_units.two-month'),
    90: _t('common.time_units.quarter'),
    365: _t('common.time_units.year'),
  }

  const map = val < 1440 ? shortMap : longMap

  // TODO: finish refactoring
  if (val < 1440) {
    if (map.hasOwnProperty(val)) {
      return map[val]
    } else {
      if (!(val % 60)) {
        return _t('common.time_units.hours', {n: val / 60})
      } else {
        return _t('common.time_units.minutes', {n: val})
      }
    }
  } else {
    val = val / 1440
    if (map.hasOwnProperty(val)) {
      return map[val]
    } else {
      if (!(val % 30)) {
        return _t('common.time_units.months', {n: val / 30})
      } else if (!(val % 7)) {
        return _t('common.time_units.weeks', {n: val / 7})
      } else {
        return _t('common.time_units.days', {n: val})
      }
    }
  }
}

export type langLoader = typeof _langLoader
export type t = typeof _t
export type currentLang = typeof _currentLang
export type langAvailable = typeof _langAvailable
export type langCount = typeof _langCount
export type currency = typeof _currency
export type distance = typeof _distance
export type isAvailable = typeof _isAvailable
export type isLogged = typeof _isLogged
export type isBrowser = typeof _isBrowser
export type minutes = typeof _minutes

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$langLoader = _langLoader
    Vue.prototype.$t = _t
    Vue.prototype.$currentLang = _currentLang
    Vue.prototype.$langAvailable = _langAvailable
    Vue.prototype.$langCount = _langCount
    Vue.prototype.$currency = _currency
    Vue.prototype.$distance = _distance
    Vue.prototype.$isAvailable = _isAvailable
    Vue.prototype.$isLogged = _isLogged
    Vue.prototype.$isBrowser = _isBrowser
    Vue.prototype.$parseMinutes = _minutes
  },
}
