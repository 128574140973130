













import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import {ParkingLot} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class ParkingCardPopup extends Vue {
  @Prop({type: Object, required: true}) public lot!: ParkingLot

  protected get counter() {
    const length = this.lot.parking_slots_availability.total
    const available = this.lot.parking_slots_availability.available

    const arr: boolean[] = []
    for (let i = 0; i < length; i++) {
      arr.push(i < available)
    }

    return {
      length,
      arr,
    }
  }
  protected get availabilityText() {
    const total = this.lot.parking_slots_availability.total
    const available = this.lot.parking_slots_availability.available
    const vehicles = this.lot.parking_slots_availability.available_vehicles
    return this.$t('map.parking.availability', {available, total, vehicles})
  }
}
