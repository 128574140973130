export abstract class BleDevice {
  private address: string = ""
  private logger: any = null

  private isMocking: boolean = false

  constructor(logger: any) {
    this.logger = logger
  }

  log(what: string) {
    this.logger("[BLE] " + what)
  }

  logJson(what: any) {
    this.logger("[BLE] " + JSON.stringify(what, null, 2))
  }

  hexToBytes(hex: any) {
    for (var bytes = [], c = 0; c < hex.length; c += 2)
      bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
  }

  byteToBitArray(b: number) {
    var array: any = []
    for (var i = 7; i >= 0; i--) {
      array[i] = b & 1
      b = b >> 1
    }
    return array
  }

  intToHex(arr: any) {
    return arr.map((element: number) => {
      // BE POSITIVE
      if (element < 0) {
        element = 0xFF + element + 1;
      }
      // END BE POSITIVE
      let e = element.toString(16)
      if (e.length == 1) {
        e = "0" + e
      }
      return e
    })
  }

  intArrayToString(data: number[]) {
    let res = ''
    data.forEach((i: number) => {
      res += String.fromCharCode(i)
    })
    return res
  }

  stringToByte(str: string) {
    let d: number[] = []
    str.split('').forEach(function (letter) {
      d.push(letter.charCodeAt(0))
    });
    return d
  }

  base64ToByte(b64: string) {
    return this.stringToByte(atob(b64))
  }

  private ble() {
    // @ts-ignore
    return bluetoothle
  }

  protected setAddress(address: string) {
    this.log("ADDRESS SET TO: " + address)
    this.address = address
  }

  private bleInitialize() {
    this.log("bleInitializeCheck")
    return new Promise<void>((resolve, reject) => {
      this.ble().isInitialized((result) => {
        if (result.isInitialized) {
          this.log("alreadyInitialized")
          resolve()
        } else {
          this.log("initializing")
          this.ble().initialize(
            (initResult) => {
              if (initResult.status == "enabled") {
                this.log("initialized")
                resolve()
              } else {
                reject("CANNOT INIT " + JSON.stringify(initResult))
              }
            },
            {
              "request": true
            }
          )
        }
      })
    })
  }

  connect() {
    const deviceParams = {
      address: this.address,
    }
    return new Promise<void>((resolve, reject) => {
      this.bleInitialize().then(
        () => {
          let afterConnect = () => {
            this.log("bleConnected")
            this.ble().isDiscovered(
              (isDiscoveredSuccess) => {
                if (!isDiscoveredSuccess.isDiscovered) {
                  this.ble().discover(
                    (discoverSuccess) => {
                      this.log("bleDiscovered")
                      resolve()
                    },
                    (discoverError) => {
                      reject("CANNOT_DISCOVER")
                    },
                    deviceParams
                  )
                } else {
                  this.log("bleAlreadyDiscovered")
                  resolve()
                }
              },
              (isDiscoveredError) => {
                reject("CANNOT CHECK DISCOVER " + JSON.stringify(isDiscoveredError))
              },
              deviceParams
            )
          }

          let connect = () => {
            this.log("bleConnect")
            this.ble().connect((success) => {
              if (success.status == "connected") {
                afterConnect()
              } else {
                reject(success.status)
              }
            }, (error) => {
              reject("CANNOT CONNECT " + JSON.stringify(error))
            }, deviceParams)
          }

          this.ble().wasConnected((success) => {
            if (success.wasConnected) {
              this.log("bleClose")
              this.ble().close((success) => {
                this.log("CLOSED:" + JSON.stringify(success))
                connect()
              }, (error) => {
                this.log("CANNOTCLOSE:" + JSON.stringify(error))
                connect()
              }, deviceParams)
            } else {
              connect()
            }
          }, (error) => {
            reject(error)
          }, deviceParams)

        }
      ).catch(reject)
    })
  }

  subscribe(service: string, characteristic: string, onData: (data: number[]) => void) {
    this.log("subscribing...")
    return new Promise<void>((resolve, reject) => {
      this.ble().subscribe(
        (successSubscribe) => {
          //this.logJson(["success", successSubscribe])
          if (successSubscribe.status == "subscribedResult") {
            let dint: number[] = []
            this.ble().encodedStringToBytes(successSubscribe.value).forEach(element => {
              dint.push(element)
            });
            onData(dint)
          }

          if (successSubscribe.status == "subscribed") {
            resolve()
          }
        },
        (subscribeError) => {
          this.logJson(["error_subscribe", subscribeError])
          reject("CANNOT SUBSCRIVE " + JSON.stringify(subscribeError))
        },
        {
          address: this.address,
          service: service,
          characteristic: characteristic,
        }
      )
    })
  }

  unsubscribe(service: string, characteristic: string) {
    return new Promise<void>((resolve, reject) => {
      this.ble().unsubscribe(
        (successSubscribe) => {
            resolve()
        },
        (subscribeError) => {
          resolve()
        },
        {
          address: this.address,
          service: service,
          characteristic: characteristic,
        }
      )
    })
  }

  logWrite(what: string, service: string, characteristic: string, data: number[]) {
    this.log(what + ": " + this.intToHex(data).join(" "))
    return this.write(service, characteristic, data)
  }

  write(service: string, characteristic: string, data: number[], wType: string | undefined = undefined) {
    let res = ''
    data.forEach((i) => {
      res += String.fromCharCode(i)
    })
    let bytes = this.ble().stringToBytes(res)
    let convValue = this.ble().bytesToEncodedString(bytes)

    return new Promise<void>((resolve, reject) => {
      if (this.isMocking) {
        this.logJson({ "wrote": this.intToHex(data).join(" ") })
        resolve()
        return
      }

      this.ble().write(
        (successWrite) => {
          if (successWrite.status != "written") {
            reject("CANNOT WRITE " + JSON.stringify(successWrite))
          } else {
            //TODO: Remove
            //this.logJson({ "wrote": this.intToHex(data).join(" ") })
            resolve()
          }

        },
        (errorWrite) => {
          reject("CANNOT WRITE " + JSON.stringify(errorWrite))
        },
        {
          value: convValue,
          characteristic: characteristic,
          address: this.address,
          service: service,
          type: wType
        })
    })
  }

  writeChunks(service: string, characteristic: string, chunks: number[][]) {
    return new Promise<void>((resolve, reject) => {
      if (chunks.length == 0) {
        resolve()
      } else {
        let d = chunks.shift()
        this.write(service, characteristic, d!).then(() => {
          this.writeChunks(service, characteristic, chunks).then(() => {
            resolve()
          })
        }).catch(alert)
      }
    })
  }

  read(service: string, characteristic: string) {
    return new Promise<number[]>((resolve, reject) => {
      this.ble().read(
        (successRead) => {
          if (successRead.status == "read") {
            let dint: number[] = []
            this.ble().encodedStringToBytes(successRead.value).forEach(element => {
              dint.push(element)
            });
            resolve(dint)
          } else {
            // this.logJson(successRead)
          }
        },
        (errorRead) => {
          this.logJson(errorRead)
          reject("CANNOT READ " + JSON.stringify(errorRead))
        },
        {
          characteristic: characteristic,
          address: this.address,
          service: service,
        })
    })
  }

  parseAdvertisingData(bytes: any) {

    let asHexString = function (i: any) {
      let hex = i.toString(16)

      // zero padding
      if (hex.length === 1) {
        hex = '0' + hex
      }

      return '0x' + hex
    }

    let length, type, data, i = 0, advertisementData: any = {}

    while (length !== 0) {

      length = bytes[i] & 0xFF
      i++

      // decode type constants from https://www.bluetooth.org/en-us/specification/assigned-numbers/generic-access-profile
      type = bytes[i] & 0xFF
      i++

      data = bytes.slice(i, i + length - 1).buffer // length includes type byte, but not length byte
      i += length - 2  // move to end of data
      i++

      const inx = asHexString(type)

      advertisementData[inx] = {
        hex: this.intToHex(new Uint8Array(data)).join(""),
        // @ts-ignore
        string: String.fromCharCode.apply(null, new Uint8Array(data))
      }


    }

    return advertisementData
  }

  protected scan() {
    let deviceFound = false
    return new Promise<void>((resolve, reject) => {
      if (this.address != "") {
        resolve()
      } else {
        this.bleInitialize().then(() => {
          let t = setTimeout(() => {
            this.ble().stopScan(
              (stopScanSuccess) => {
                this.logJson(stopScanSuccess);
              },
              (stopScanError) => {
                this.logJson(stopScanError);
              }
            );
            if (!deviceFound) {
              reject("SCAN_STOPPED")
            }
          }, 5555)

          this.ble().startScan(
            (scanStatus) => {
              if (scanStatus.status == "scanResult") {
                let data: any = {}
                let mode: string = "none"

                if (scanStatus.advertisement !== null) {
                  // ANDROID
                  if (typeof scanStatus.advertisement === 'string') {
                    mode = "advertisement"
                    data = this.parseAdvertisingData(this.ble().encodedStringToBytes(scanStatus.advertisement as any))
                  }
                  // IOS
                  if (typeof scanStatus.advertisement === 'object' && (scanStatus.advertisement as any).manufacturerData) {
                    mode = "manifacturer"
                    let man = (scanStatus.advertisement as any).manufacturerData
                    let dint: number[] = []
                    this.ble().encodedStringToBytes(man).forEach(element => {
                      dint.push(element)
                    });
                    man = this.intToHex(dint)
                    data = man.join("")
                  }
                }

                if (this.isCorrectDevice(scanStatus.name, scanStatus.address, mode, data)) {
                  this.setAddress(scanStatus.address)
                  deviceFound = true
                  resolve()
                  clearTimeout(t)
                  this.ble().stopScan(
                    (stopScanSuccess) => {
                      this.logJson(stopScanSuccess);
                    },
                    (stopScanError) => {
                      this.logJson(stopScanError);
                    }
                  )
                }
              }

            },
            (scanError) => {
              this.logJson(scanError)
              if (scanError.error == "enable") {
                reject('BLUETOOTH_DISABLED')
              } else {
                reject(scanError.message)
              }
            }
          );
        }).catch(reject)
      }
    })
  }

  protected scanAndConnect() {
    return new Promise<void>((resolve, reject) => {
      if (this.isMocking) {
        resolve()
        return
      }
      this.scan().then(() => {
        this.connect().then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  protected init() {
    return this.scanAndConnect()
  }

  protected isConnected() {
    return new Promise<void>((resolve, reject) => {
      this.log("isConnected")
      this.ble().isConnected((success) => {
        if (success.isConnected) {
          this.log("isConnected:OK")
          resolve()
        } else {
          reject("NOT_CONNECTED")
          this.log("isConnected:NOT_CONNECTED")
        }
      }, (error) => {
        reject(error.message)
        this.log("isConnected:" + error.message)
      }, {
        address: this.address
      })
    })
  }

  public disconnect() {
    return new Promise<void>((resolve, reject) => {
      this.ble().disconnect(() => {
        this.ble().close(() => { resolve() }, () => { resolve() }, { address: this.address })
      }, () => {
        this.ble().close(() => { resolve() }, () => { resolve() }, { address: this.address })
      }, {
        address: this.address
      })
    })
  }

  abstract isCorrectDevice(name: string, address: string, mode: string, data: any): boolean
}
