









































































import {Component, Prop, Watch} from 'vue-property-decorator'
import {TocElement, TocResponse, TosResponse} from '@/lib/kepler/interfaces'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import {Action} from 'vuex-class'
import Validations from '@/lib/Validations'
import Utils from '@/utils'

import Contracts from '@/views/Contracts.vue'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'FlowToS',
})
export default class ToS extends Contracts {
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop() public acceptTosCallback!: () => void
  @Prop({
    type: Boolean,
    default: false,
  }) public downloadRequired!: boolean
  @Prop({
    type: Array,
    default: () => [],
  }) public filter!: string[]

  declare public termOfServices: TermOfServicesState
  declare public parseStatusClass: (status: string, optional: boolean) => 'error--text' | 'warning--text' | 'success--text' | 'grey--text' | undefined

  public internalValue: string[] = []

  public rules: any = Validations.rules
  public detailItem: null | TosResponse | TocElement = null
  public detailModal: boolean = false
  public downloadedTos: string[] = []

  public get requiredTocs() {
    const tocs: TosResponse[] = []
    this.termOfServices.tocs?.forEach((t) => {
      t.tocs.forEach((t2) => tocs.push(t2.toc))
    })
    return tocs.filter((t) => !t.optional).map((t) => t.id)
  }

  public get tocs(): TocResponse[] {
    return this.termOfServices.tocs || []
  }

  public get missingTocs() {
    const missing: string[] = []
    const tocGroups = this.tocs
    tocGroups.forEach((tocGroup) => {
      tocGroup.tocs.forEach((toc) => {
        if (toc.status === 'MISSING' && !toc.toc.optional) {
          missing.push(toc.toc.id)
        }
      })
    })
    if (this.internalValue) {
      return missing.filter((id) => !this.internalValue.includes(id))
    }
    return missing
  }

  public setDetail(item: TosResponse) {
    this.$set(this, 'detailItem', item)
  }

  @Watch('detailItem')
  public onDetailChange(d: TosResponse | null) {
    this.detailModal = d !== null
  }

  public hasDownloaded(id: string): boolean {
    if (this.downloadRequired) {
      return this.downloadedTos.includes(id)
    }
    return true
  }

  public downloadTos(el: TosResponse) {
    if (el.attachment) {
      cordova.InAppBrowser.open(el.attachment, '_system')
      if (!this.hasDownloaded(el.id)) {
        this.downloadedTos.push(el.id)
      }
    }
  }

  @Watch('internalValue')
  public onValueChange(val: string[]) {
    this.$emit('input', val)
    this.$nextTick(() => {
      (this.$refs.validationProvider as any).validate()
    })
  }

  public wasApproved(id: string): boolean {
    return this.internalValue.includes(id)
  }

  public canSign(detailItem: TocElement) {
    if (this.filter && this.wasApproved(detailItem.toc.id)) {
      return false
    } else {
      // 'SIGNED' | 'MISSING' | 'EXPIRED' | 'UPCOMING'
      return detailItem.status === 'MISSING' || detailItem.status === 'UPCOMING'
    }
  }

  public canUnsign(detailItem: TocElement) {
    const remoteApproved = detailItem.signed_on !== null
    const localApproved = !this.filter && this.wasApproved(detailItem.toc.id)

    return detailItem.toc.optional && (remoteApproved || localApproved)
  }

  protected areRules(currentId: string) {
    const arr = []
    if (this.termOfServices.tocs) {
      arr.push(this.rules.requiredCheckboxes(this.requiredTocs, currentId))
    }
    return arr
  }

  protected signAndCloseModal(id: string) {
    if (this.filter) {
      // @ts-ignore
      this.sign(id).then(() => {
        this.detailModal = false
      })
    } else if (!this.internalValue.includes(id)) {
      this.internalValue.push(id)
      this.detailItem = null
    }
  }

  protected unsignAndCloseModal(id: string) {
    if (this.filter) {
      // @ts-ignore
      this.unsign(id).then(() => {
        this.detailModal = false
      })
    } else if (this.internalValue.includes(id)) {
      const i = this.internalValue.findIndex((internalId: string) => internalId === id)
      this.internalValue.splice(i, 1)
      this.detailItem = null
    }
  }

  protected tocColor(item: TocElement) {
    if (this.filter) {
      return this.parseStatusClass(item.status, item.toc.optional)
    } else {
      if (item.toc.optional && !this.wasApproved(item.toc.id)) {
        return 'orange--text'
      }
      return this.wasApproved(item.toc.id) ? 'success--text' : 'error--text'
    }
  }

  protected checkBoxLabel(item: TocElement) {
    if (this.downloadRequired && !this.hasDownloaded(item.toc.id)) {
      return this.$t('profile.terms.download_to_accept')
    }
    return item.toc.acceptance_statement
  }

  protected mounted() {
    this.internalValue = []
    this.$nextTick(() => {
      (this.$refs.validationProvider as any).validate()
    })
  }
}
