














import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {WalletState} from '@/store/modules/wallet'
import {AppConfigState} from '@/store/modules/configuration'

import Utils from '@/utils'
import moment from 'moment'
import DateHelper from '@/lib/DateHelper'
import OverviewWallet from '@/components/wallet/OverviewWallet.vue'
import {RecordRequest, Wallet, Popup} from '@/lib/kepler/interfaces'

@Component({
  name: 'Overview',
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    OverviewWallet: Utils.loadComponent('OverviewWallet'),
  },
})
export default class Overview extends Vue {
  @State('wallet') public walletState!: WalletState
  @State('configuration') public configuration!: AppConfigState
  @Getter('defaultWallet') public defaultWallet?: Wallet
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('setDateOverview') public setDate!: (date: string) => void
  @Action('setDateInvoices') public setDateInvoices!: (date?: string) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('getRecords') public getRecords!: (req: RecordRequest) => any

  // @Prop({
  // }) private wallets?: Array
  protected selectedDate: string | null = null

  protected get wallet() {
    return this.defaultWallet
  }

  protected pos(n: number) {
    return !(n < 0)
  }

  protected formatDate(date: string) {
    return moment(date).format('LL')
  }

  protected created() {
    const thisMonth = DateHelper.now('YYYY-MM')
    if (this.walletState.dateOverview == null || this.walletState.dateInvoices == null) {
      this.setDate(thisMonth)
      this.setDateInvoices(thisMonth)
      this.selectedDate = thisMonth
    } else {
      this.selectedDate = this.walletState.dateOverview
    }
    if (this.walletState.wallets == null) {
      this.getWallets()
    }
  }

  protected mounted() {
    const thisMonth = DateHelper.now('YYYY-MM')
    if (this.wallet) {
      this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})
      this.getRecords({id: this.wallet.id, account_type: 'debit', month: thisMonth})
    }

    // this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})

    // if (this.walletState.dateOverview == null) {
    //   this.setDate(thisMonth)
    //   this.selectedDate = thisMonth
    // } else {
    //   this.selectedDate = this.walletState.dateOverview
    // }
    // if (this.walletState.wallets == null) {
    //   this.getWallets(thisMonth)
    // }

    if (this.walletState.dateOverview == null) {
      this.setDate(thisMonth)
      this.selectedDate = thisMonth
    } else {
      this.selectedDate = this.walletState.dateOverview
    }
    if (this.walletState.wallets == null && this.wallet) {
      this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})
    }
    // console.log('mounted', this.wallet.records)
  }

  protected openWallet() {
    this.openPopup(
      new Popup(OverviewWallet, {
        angle: 90,
        color1: 'overviewWalletTop',
        color2: 'overviewWalletBottom',
        customCSS: 'overviewWallet',
        name: this.$t('profile.wallet.title'),
        monthProp: null,
        type: 'credit',
      }, this.$t('profile.wallet.title'), false),
    )
  }

  protected openWalletDebit() {
    this.openPopup(
      new Popup(OverviewWallet, {
        angle: 200,
        color1: 'overviewPrepaidTop',
        color2: 'overviewPrepaidBottom',
        customCSS: 'overviewPrepaid',
        name: this.$t('profile.wallet.prepaid.title'),
        monthProp: null,
        type: 'debit',
      }, this.$t('profile.wallet.prepaid.title'), false),
    )
  }

  protected get prepaidEnabled() {
    // TODO: point to correct config key
    const l = this.configuration.appConfig.prepaid_wallet_enabled
    if (l === undefined) {
      return false
    }
    return l
  }
}
