















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import {VLayout, VBtn, VIcon, VProgressCircular, VDialog, VCard} from 'vuetify/lib'
import {Dialog, FlowInputField, FlowInputStep, SubscriptionPlan} from '@/lib/kepler/interfaces'
import CardPlan from '@/components/subscriptions/CardPlan.vue'
import sdk from '@/lib/kepler/sdk'
import PlanDetail from '@/views/PlanDetail.vue'
import PlanDetailDialog from '@/views/PlanDetailDialog.vue'

@Component({
  components: {
    PlanDetail,
    CardPlan,
    VProgressCircular,
    FlowField: Utils.loadComponent('flow/FlowField'),
    VLayout,
    VDialog,
    VBtn,
    VCard,
    VIcon,
  },
  name: 'FlowPlans',
})
export default class FlowPlans extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @State((state) => state.subscriptions.plans) public plans!: SubscriptionPlan[]

  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Prop() public output?: Array<{ [k: string]: any }> | { [k: string]: any }
  @Prop() public field?: FlowInputField
  @Prop() public step?: FlowInputStep

  public loading: boolean = true

  protected get context() {
    return this.step?.context
  }

  protected get availablePlans(): SubscriptionPlan[] {
    if (this.plans && this.plans.length && !this.loading) {
      return this.plans.filter((p) => {
        if (this.field?.value) {
          return p.id === this.field.value
        }
        const available = p.status === 'AVAILABLE'
        const valid = (p.valid_to_timestamp === null) || (p.valid_to_timestamp > Math.floor(Date.now() / 1000))
        return available && valid
      })
    }
    return []
  }

  protected get isLogged() {
    return sdk.people.isLogged()
  }

  public created() {
    this.loading = true
    this.getPlans().finally(() => {
      this.loading = false
    })
  }

  protected selectPlan(plan: SubscriptionPlan) {
    this.openDialog(new Dialog(PlanDetailDialog, {plan}))
  }
}
