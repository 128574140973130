



































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Dialog, Driver, Subscription, SubscriptionPlan} from '@/lib/kepler/interfaces'
import sdk from '@/lib/kepler/sdk'

import ConfirmDialog from '@/views/ConfirmDialog.vue'
import Utils from '@/utils'

interface CondensedDriver {
  driver: Driver
  hasThisSubscription: boolean
  hasSubscription: boolean
  active: boolean
}

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Alert: Utils.loadComponent('proxy/Alert'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    CardDriver: Utils.loadComponent('CardDriver'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class SubscriptionAssignDialog extends Vue {
  @State((state) => state.profile.drivers) public drivers!: Driver[]
  @State((state) => state.profile.subscriptions) public subscriptions!: Subscription[]
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null
  @Getter('driversByPlan') public driversByPlan!: Record<string, string[]>

  @Prop({required: true, type: Object}) public subscription!: Subscription

  private selectedDrivers: string[] = []
  private loading: boolean = false

  protected get changedDrivers() {
    const arr: string[] = []
    this.selectedDrivers.forEach((driverId) => {
      if (!this.subscription.drivers.find((d) => d.id === driverId)) {
        arr.push(driverId)
      }
    })
    return arr
  }

  protected get activeDrivers(): CondensedDriver[] {
    const drivers = this.drivers || []
    const planDrivers = this.driversByPlan[this.subscription.plan.id]
    const unassignedOrDefault: string[] = []
    const assignedDrivers: string[] = []
    Object.entries(this.driversByPlan).forEach(([planId, d]) => {
      if (planId === 'unassigned' || planId === this.defaultPlan?.id) {
        unassignedOrDefault.push(...d)
      } else {
        assignedDrivers.push(...d)
      }
    })

    return drivers.map((driver) => {
      const hasThisSubscription = !!planDrivers?.find((d) => d === driver.id)
      const hasSubscription = assignedDrivers.includes(driver.id)

      return {
        hasThisSubscription,
        hasSubscription,
        driver,
        active: unassignedOrDefault.includes(driver.id),
      }
    })
  }

  protected mounted() {
    this.init()
  }

  protected init() {
    this.loading = true
    this.getSubscriptions().then(() => {
        this.subscription.drivers.forEach((driver: Driver) => {
          this.selectedDrivers.push(driver.id)
        })
      },
    ).finally(() => {
      this.loading = false
    })
  }

  private contactSupport(d: CondensedDriver) {
    if (d.hasThisSubscription) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.cannot_remove_driver'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    } else if (d.hasSubscription) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.driver_already_have_a_subscription'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    }
  }

  private submitChanges() {
    this.loading = true
    this.changedDrivers.forEach((driverId, i) => {
      sdk.subscription.addDriver(this.subscription.id, driverId).then(
        () => {
          if (i === this.changedDrivers.length - 1) {
            const str = this.changedDrivers.length > 1 ? 'subscription.drivers_added' : 'subscription.driver_added'
            this.openDialog(new Dialog(ConfirmDialog, {
              singleAction: true,
              imageState: 'success.svg',
              code: '',
              title: '',
              subtitle: this.$t(str),
              confirmText: this.$t('action.close'),
            }, null, false, null))
            this.init()
            this.loading = false
          }
        },
      ).catch(() => (
        this.loading = false
      ))
    })
  }
}
