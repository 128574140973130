import OmniScooterHardware from '@/lib/hardware/classes/OmniScooterHardware'
import InversHardware from '@/lib/hardware/classes/InversHardware'
import RestHardware from '@/lib/hardware/classes/RestHardware'
import MibHardware from '@/lib/hardware/classes/MibHardware'
import AbstractHardware, {AlertHardware} from '@/lib/hardware/classes/AbstractHardware'
import B810BikeLockHardware from '@/lib/hardware/classes/B810BikeLockHardware'
import HufHardware from "@/lib/hardware/classes/HufHardware";
import GeotabHardware from "@/lib/hardware/classes/GeotabHardware";
import RuptelaHardware from "@/lib/hardware/classes/RuptelaHardware";
import DunasysHardware from "@/lib/hardware/classes/DunasysHardware";
import TeltonikaHardware from "@/lib/hardware/classes/TeltonikaHardware";
import OmniLockHardware from "@/lib/hardware/classes/OmniLockHardware";
import OmniPersonalLock from "@/lib/hardware/classes/OmniPersonalLockHardware";

export class HardwareManager {
  private hws: { [key: string]: AbstractHardware } = {}
  private readonly defaultPrioList: string[] = []
  private readonly hook: ((hookName: string, message: string) => Promise<void>) | null

  private readonly statusCB: (message: string, error: boolean) => void
  private initializedHw: string[] = []

  constructor(
    hwMap: Map<string, any>,
    hook: ((hookName: string, message: string) => Promise<void>) | null = null,
    statusCB: (message: string, error: boolean) => void,
  ) {
    this.statusCB = statusCB

    // Hook handler
    this.hook = hook
    // Initialize for every hardware set in the map

    for (const [key, par] of hwMap.entries()) {
      this.debugInfo('INITIALIZING ' + key)
      // Add hardware name to the prioList for command invocation
      this.defaultPrioList.push(key)
      // Set a copy of the hardware handler in the cache (this.hws)
      this.hws[key] = this.hardwareReturner(key, par)

      this.hws[key].setStatusLogger((x) => {
        this.statusCB('CM-' + key + ': ' + x, false)
      })
    }
  }

  public init(commandCB: (commands: any) => void) {
    let promises = []

    for (const key of Object.keys(this.hws)) {
      promises.push(this.hws[key].init((x) => {
        this.handleInitResponse(x, key, commandCB)
      }).then((i) => {
        this.initializedHw.push(key)
        this.debugInfo(key + '.init: ' + i, false)
        this.refreshActionMap(commandCB)
      }).catch((e) => {
        this.debugInfo(key + '.init: ' + e, true)
        throw e
      }))
    }

    return Promise.allSettled(promises)
  }

  // This proxy function allows to use a hook to lock access to a function
  public run(name: string): Promise<unknown> {
    if (!name) {
      alert('NAME NOT DEFINED')
      return Promise.reject()
    }
    return new Promise((resolve, reject) => {
      if (this.hook) {
        this.hook(name, '').then(() => {
          this.runAction(name).then(resolve).catch(reject)
        }).catch((e) => {
          reject(e)
        })
      } else {
        this.runAction(name).then(resolve).catch(reject)
      }
    })
  }

  public terminateCheck(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const arr: Promise<string | void>[] = []

      for (let hwsKey in this.hws) {
        if (this.hws.hasOwnProperty(hwsKey)) {
          arr.push(this.hws[hwsKey].terminateCheck())
        }
      }

      if (!arr.length) {
        resolve('OK')
      } else {
        return Promise.all(arr).then(() => {
          resolve('OK')
        }).catch((rejectError) => {
          reject(this.messageMap(rejectError))
        })
      }
    })
  }

  public cleanup(): void {
    for (let hwsKey in this.hws) {
      if (this.hws.hasOwnProperty(hwsKey)) {
        this.hws[hwsKey].cleanup()
      }
    }
  }

  private initialized(key: string, commandCB: (commands: any) => void) {
    if (!this.initializedHw.includes(key)) {
      this.initializedHw.push(key)
      this.refreshActionMap(commandCB)
    }
  }

  private notInitialized(key: string, commandCB: (commands: any) => void) {
    if (this.initializedHw.includes(key)) {
      this.initializedHw = this.initializedHw.filter((h: string) => {
        return h != key
      })
      this.refreshActionMap(commandCB)
    }
  }

  private handleInitResponse(x: string, key: string, commandCB: (commands: any) => void) {
    this.debugInfo('>' + key + ': ' + x)
    if (x.toUpperCase().startsWith('TRX_')) {
      this.statusCB('OUT_OF_RANGE', true)
      this.initialized(key, commandCB)
    }
    switch (x.toUpperCase()) {
      case 'READY':
        this.initialized(key, commandCB)
        break
      case 'NOT_READY':
        this.notInitialized(key, commandCB)
        break
    }
  }

  private refreshActionMap(commandCB: (commands: any) => void) {
    let actionMap: any = {}
    for (const kpl of this.defaultPrioList) {
      for (const [kk, par] of this.hws[kpl].getActions()) {
        if (this.initializedHw.includes(kpl)) {
          if (!actionMap[kk]) {
            actionMap[kk] = []
          }
          actionMap[kk].push({
            channel: par.channel,
            manager: kpl,
          })
        }
      }
    }

    commandCB(actionMap)
  }

  private debugInfo(message: string, error = false) {
    this.statusCB('HM: ' + message, error)
  }

  // Execute the action in the prio list
  private runAction(name: string, prioList?: string[], tries?: number) {
    return new Promise((resolve, reject) => {
        if (!prioList) {
          prioList = this.defaultPrioList.slice()
        }

        prioList = prioList.filter((el) => {
          return this.initializedHw.indexOf(el) >= 0 && this.hws[el].getActions().has(name)
        })
        // Extract "current" hardware (will be used for the action)
        let hw = prioList.shift()
        if (hw) {
          this.debugInfo(`RUNNING ${name}@${hw}`)
          // Call the "current" hardware specific method
          this.hws[hw].runAction(name).then((i) => {
            this.debugInfo(name + '@' + hw + ': ' + i, false)
            // Action has been completed successfully: Return peacefully
            resolve(i)
          }).catch((e) => {
            // Action has failed: Error handling (or let the war begin)
            this.debugInfo(name + '@' + hw + ': ' + e, true)

            // Check if current action should be retried (ble channel)
            if (typeof tries === 'undefined' && hw && (this.hws[hw].getActions().get(name)?.retry! > 0)) {
              tries = this.hws[hw].getActions().get(name)?.retry
            }

            const noRetryErrors = [
              'VEHICLE_STATUS_CHANGE_TIMEOUT',
            ]

            const isErrorRetryable = !noRetryErrors.includes(e)

            if (tries && isErrorRetryable) {
              tries--
              const ogRetry = this.hws[hw!].getActions().get(name)?.retry
              this.debugInfo(`RETRY ${name}@${hw} - ${Number(ogRetry) - tries}/${ogRetry}`)
              // Re-Launch the action
              setTimeout(() => {
                // RE-ADD "current" hardware to top of the prioList
                if (prioList && hw) {
                  prioList.unshift(hw)
                } else {
                  reject()
                }
                // If fails, restart the process
                this.runAction(name, prioList, tries).then(resolve).catch(reject)
              }, (tries + 1) * 1500)
            } else {
              // Tries is empty now

              // Check for hook failure (if i have some more hardware to try)
              if (isErrorRetryable && this.hook && prioList?.length && hw && e !== 'ABORTED') {
                const currentChannel = this.hws[hw].getActions().get(name)!.channel ?? 'rest'
                const futureChannel = this.hws[prioList[0]].getActions().get(name)!.channel ?? 'rest'

                const hookName = 'fallback|' + currentChannel + '|' + futureChannel
                this.hook(hookName, this.messageMap(e)).then(() => {
                  this.runAction(name, prioList).then(resolve).catch(reject)
                }).catch(() => {
                  reject('ABORTED')
                })
              } else {
                // No hook defined, just reject
                reject(this.messageMap(e))
              }
            }
          })
        } else {
          reject('no more hardwares to try')
        }
      },
    )
  }

  private messageMap(input: string) {
    if (Array.isArray(input)) {
      return '-' + input[1]
    }
    switch (input) {
      case 'KEY_NOT_DETECTED':
        return 'RETURN_KEY_TO_CONTINUE'
      case 'CMD_ALREADY_PROCESSING':
      case 'Device previously connected, reconnect or close for new connection':
      case 'Device previously connected, reconnect or close for new device':
      case 'NOT_READY':
      case 'ALREADY_INIT':
      case 'CANNOT_DISCOVER':
      case 'ALREADY_SCANNING': // B810
      case 'COMMAND_ALREADY_IN_PROGRESS':
      case 'INIT_ALREADY_IN_PROGRESS':
        return 'BUSY'
      case 'VEHICLE_STATUS_CHANGE_TIMEOUT':
      case 'TIMEOUT_RESPONSE':
        return 'PROCESSING_ERROR'
      case 'BLE_NOT_ENABLED_ON_DEVICE':
      case 'Bluetooth not enabled':
        return 'BLUETOOTH_DISABLED'
      case 'TRX_PACKET_RECEPTION_TIMEOUT':
      case 'IGNITION_UNKNOWN':
      case 'BLE_SCAN_NOT_RUNNING':
      case 'scan stopped':
      case 'VEHICLE_NOT_AT_PROXIMITY':
      case 'SCAN_STOPPED':  // B810
        return 'OUT_OF_RANGE'
      case 'KEY_NOT_YET_AVAILABLE':
        return 'KEY_NOT_AVAILABLE'
      case 'PIN_REQUEST_FAILED':
        return 'ABORTED'
      case 'TIMEOUT':
      case 'INVALID_PIN':
      case 'ABORTED':
      case 'BLUETOOTH_DISABLED':
      case 'MISSING_KEY':
      case 'IGNITION_ON':
      case 'DOOR_OPEN':
      case 'CANNOT_LOCK_GPS':
      case 'CANNOT_TERMINATE_WITH_LOCK_OPENED':
        return input
      default:
        return '>' + input
    }
  }

  private hardwareReturner(name: string, par: any) {
    switch (name) {
      case 'huf':
        return new HufHardware(par)
      case 'geotab':
        return new GeotabHardware(par)
      case 'mib':
        return new MibHardware(par)
      case 'invers':
        return new InversHardware(par)
      case 'rest':
        return new RestHardware(par)
      case 'ble-bike':
        return new B810BikeLockHardware(par)
      case 'omni-scooter':
        return new OmniScooterHardware(par)
      case 'omni-lock':
        return new OmniLockHardware(par)
      case 'omni-personal-lock':
        return new OmniPersonalLock(par)
      case 'ruptela':
        return new RuptelaHardware(par)
      case 'dunasys':
        return new DunasysHardware(par)
      case 'teltonika':
        return new TeltonikaHardware(par)
      default:
        return new AlertHardware(par)
    }
  }
}
