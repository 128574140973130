




































import {Vue, Component, Prop} from 'vue-property-decorator'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import DateHelper from '../../../lib/DateHelper'
import Utils from '@/utils'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import moment from 'moment'
import ServiceMesh from '@/lib/serviceMesh'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    CardList: Utils.loadComponent('CardList'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardButton: Utils.loadComponent('CardButton'),
  },
  name: 'CardReservation',
})
export default class CardReservation extends Vue {
  @Prop() protected reservation!: ReservationResponse
  @Prop() protected future!: boolean
  @Prop() protected showDriver!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) protected animated!: boolean

  protected getTime(reservation: ReservationResponse) {
    return DateHelper.getTimeOffset(reservation.start_timestamp, reservation.end_timestamp).formatTimeOffset('dhm')
  }

  protected getDate(isStart: boolean) {
    const start = this.reservation.start
    const end = this.reservation.end
    const now = moment().toISOString()

    function isSameDay(date1: string, date2: string) {
      return moment(date1).isSame(moment(date2), 'day')
    }

    if (start && end) {
      const timestamp = isStart ? start : end
      const m = moment(timestamp)
      if (isSameDay(now, timestamp)) {
        return m.calendar()
      } else {
        return m.format('L') + '\n ' + m.format('LT')
      }
    }
  }

  protected getCost(reservation: ReservationResponse) {
    const n = Number(reservation.cost)
    return !Number.isNaN(n) && reservation.status === 'CHARGED' ? `${this.$currency(reservation.cost as any)}` : ''
  }

  protected get primaryColor() {
    return VuetifyColorHelper.color('primary')
  }

  protected get vehicleTypeColor() {
    const vs = this.reservation.vehicle_slot
    const key = `${vs.reservation_type}${vs.vehicle.category.type}`.toUpperCase()
    return ServiceMesh.colors[key]
  }

  protected get isLate() {
    // TODO: make this work
    return false
  }

  protected get standardColor() {
    return this.isLate ? VuetifyColorHelper.color('error') : VuetifyColorHelper.color('success')
  }
}
