





















import {Component, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {Popup, Dialog, ResetPasswordRequest} from '@/lib/kepler/interfaces'
import ConfirmDialog from '../views/ConfirmDialog.vue'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {AxiosPromise} from 'axios'

@Component({
  name: 'ResetPassword',
  components: {
    Button: Utils.loadComponent('Button'),
    TopBar: Utils.loadComponent('TopBar'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
  },
})
export default class ResetPassword extends Vue {
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('resetPassword') public resetPassword!: (payload: ResetPasswordRequest) => AxiosPromise

  protected resetRequest: ResetPasswordRequest = {identification: null}
  protected loading: boolean = false

  protected mounted() {
    EventBus.$on('ResetPasswordSent', () => {
      this.closePopup()
      this.closeDialog(0)
    })
  }

  protected destroyed() {
    EventBus.$off('ResetPasswordSent')
  }

  protected sendResetPassword(e: any) {
    this.loading = true
    this.resetPassword(this.resetRequest).then(() => {
      this.openDialog(new Dialog(ConfirmDialog, {
        imageState: 'success.svg',
        code: '',
        subtitle: this.$t('login.confirm_reset'),
        singleAction: true,
        title: '',
        confirmText: this.$t('action.close'),
      }, null, false, 'ResetPasswordSent'))
    }).finally(() => {
      this.loading = false
    })
    e.preventDefault()
  }
}
