




























import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {Dialog, FieldConfigs, Popup} from '@/lib/kepler/interfaces'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'

interface MenuButton {
  name?: string,
  icon?: string,
  image?: string,
  url?: string,
  path?: string,
  flow?: string,
  color?: string,
  class?: string
}

interface MenuObj extends Partial<Record<string, string>> {
  class?: string
  src?: string
  icon?: string
  label?: string
}

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    BottomNav: Utils.loadComponent('proxy/BottomNav'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class HomeMenu extends Vue {
  @State('popupState') public popupState!: Popup[]
  @State('flowInputs') public flowInputs!: { [k: string]: any }

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  protected get homeMenu(): {name: string, obj?: MenuObj, buttons?: MenuButton[]} {
    const homeMenuStyle = this.fieldConfigs?.home_menu_style
    const homeMenuType = homeMenuStyle?.match(/^([^|]*)/)?.[0]
    let obj: MenuObj = {}
    switch (homeMenuType) {
      case 'logo':
        // example config string: logo|src=img/logo.png|class=accent--text
        obj = {src: 'img/icons/android-chrome-192x192.png', class: 'accent--text'}
        homeMenuStyle?.split('|').forEach((s) => {
          const prop = s.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        return {name: 'logo', obj}
      case 'labeled':
        // example config string: labeled|icon=mdi-arrow-right-bold|label=go
        obj = {label: 'menu.bottom_bar.activities', icon: 'mdi-shape', class: ''}
        homeMenuStyle?.split('|').forEach((s) => {
          const prop = s.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        return {name: 'labeled', obj}
      case 'buttons':
        // example config string:
        // buttons|icon=mdi-car-key&name=booking&flow=driver_booking_request
        //        |icon=mdi-view-list&name=history&path=history
        //        |icon=mdi-account&name=profile&path=profile&color=accent&class=accent--text
        // encode each button as a query string, like https://www.coderstool.com/querystring-encode
        const buttons: MenuButton[] = []
        homeMenuStyle?.split('|').slice(1).forEach((b) => {
          const params = new URLSearchParams(b)
          const entries = params.entries()
          const button: MenuButton = Object.fromEntries(entries)
          buttons.push(button)
        })
        return {name: 'buttons', buttons}
      default:
        return {name: 'hidden'}
    }
  }

  protected get buttonClass() {
    let cls = ''
    if (this.homeMenu.name === 'labeled') {
      cls += `text-button ${this.homeMenu.obj?.class}`
    } else if (this.homeMenu.name === 'logo') {
      cls += `logo-button ${this.homeMenu.obj?.class}`
    }
    return cls
  }

  protected buttonRoute(b: MenuButton) {
    if (b.path) {
      this.$router.push(b.path)
    } else if (b.flow) {
      const flowName = b.flow
      const flow = this.flowInputs[flowName || '']
      if (this.flowInputs[flowName]) {
        if (this.flowInputs[flowName].dialog) {
          this.openDialog(new Dialog(FlowDialog, {flowName}, flow))
        } else {
          this.openPopup(new Popup(FlowAutoload, {flowName}, flow, true))
        }
      }
    } else if (b.url) {
      cordova.InAppBrowser.open(b.url, '_system')
    }
  }
}
