

























import {Component, Prop, Vue} from 'vue-property-decorator'
import {Globalnote, ReservationFlag, ReservationResponse} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import DateHelper from '@/lib/DateHelper'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {BookingState} from '@/store/modules/booking'

@Component({
  components: {
    ExtraActions: Utils.loadComponent('ExtraActions'),
    VehicleActions: Utils.loadComponent('VehicleActions'),
    BookingActions: Utils.loadComponent('BookingActions'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Divider: Utils.loadComponent('proxy/Divider'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Container: Utils.loadComponent('proxy/Container'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ReservationVehicleAndDates: Utils.loadComponent('entities/reservation/ReservationVehicleAndDates'),
    ReservationHighlights: Utils.loadComponent('entities/reservation/Highlights'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingSummary',
})
export default class BookingSummary extends Vue {
  @State('booking') public booking!: BookingState

  @Action('setActionButtons') public setActionButtons: any
  @Action('globalNotes') public globalNotes!: () => Promise<Globalnote[]>
  @Action('bookingFlushFlags') public bookingFlushFlags: any

  @Prop({type: Object, required: true}) public reservation!: ReservationResponse

  public topUp: boolean | null = null
  public loader: boolean | number = false
  public elapsed: string = ''
  public notes: Globalnote[] = []

  public get bookingFlags() {
    let p: boolean = false
    this.booking.bookingFlags.forEach((flag: ReservationFlag) => {
      if (flag.reservation_number === this.reservation.number) {
        const now = DateHelper.parse(DateHelper.now('YYYY-MM-DD HH:mm'))
        const value = DateHelper.parse(flag.value)
        p = value.diff(now, 'minutes') > 0
      }
    })
    return p
  }

  public created() {
    if (!this.reservation) {
      this.$router.push('/')
    } else {
      this.$route.meta.topbar.title = this.$t('booking.reservation', {number: this.reservation.number})
      this.globalNotes().then((r) => {
        this.notes = r
      })

      this.setElapsed()
      EventBus.$on('cron1', () => {
        this.setElapsed()
      })
      if (!this.bookingFlags) {
        this.bookingFlushFlags()
      }
    }
  }

  public setElapsed() {
    const now = new Date()
    const start = this.reservation.start_timestamp
    this.elapsed = DateHelper.getTimeOffset(start, now).formatTimeOffset('dhm')
  }

  public destroyed() {
    EventBus.$off('cron1')
  }
}
