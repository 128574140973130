














import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import AddCredit from '@/views/AddCredit.vue'
import {Dialog} from '@/lib/kepler/interfaces'
import ListTileContent from '@/components/proxy/List/ListTileContent.vue'

@Component({
  components: {
    ListTileContent,
    GradientCard: Utils.loadComponent('GradientCard'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
  },
  name: 'BuyTopUp',
})

export default class BuyTopUp extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Prop({type: String, default: () => 'topUp'}) public theme!: string

  private openAddCredit() {
    this.openDialog(new Dialog(AddCredit, {
      confirmText: this.$t('activities.book_and_shop.buy'),
    }))
  }
}
