





import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Dialog, FieldConfigs, HomeModeEnum, Popup} from '@/lib/kepler/interfaces'

import Map from '@/views/Map.vue'
import CorporateActivities from '@/views/CorporateActivities.vue'
import ListingHomeMode from '@/views/ListingHomeMode.vue'
import LightActivities from '@/views/LightActivities.vue'
import HomeMenu from '@/components/HomeMenu.vue'

@Component({
  components: {
    HomeMenu,
  },
  name: 'HomeMode',
})
export default class HomeMode extends Vue {
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State('popupState') public popupState!: Popup[]
  @State('dialogState') public dialogState!: Dialog[]
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Action('setHomeMode') public setHomeMode!: (mode: HomeModeEnum) => void

  public get mode() {
    switch (this.homeMode) {
      case HomeModeEnum.mission:
        return {component: CorporateActivities, key: 'home_mission'}
      case HomeModeEnum.map:
        return {component: Map, key: 'home_map'}
      case HomeModeEnum.light:
        return {component: LightActivities, key: 'home_light'}
      case HomeModeEnum.listing:
      default:
        return {component: ListingHomeMode, key: 'home_listing'}
    }
  }

  public created() {
    const conf = this.fieldConfigs?.app_mode
    const env = this.$env.FORCE_MAP_MODE
    const empty = !(conf || env)
    if (empty && this.homeMode !== HomeModeEnum.map) {
      this.setHomeMode(HomeModeEnum.map)
    }
    if (env) {
      this.setHomeMode(env as HomeModeEnum)
      return
    }
    if (conf && conf !== this.homeMode) {
      this.setHomeMode(conf as HomeModeEnum)
    }
  }

  public switchHomeMode(mode?: HomeModeEnum) {
    this.setHomeMode(mode ? mode : HomeModeEnum.map)
  }
}
