







































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BookingRequest} from '@/lib/kepler/interfaces'
import DateHelper from '../../../lib/DateHelper'
import Utils from '@/utils'
import moment from 'moment'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardList: Utils.loadComponent('CardList'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
  },
})
export default class BookingRequestCard extends Vue {
  @Prop() protected request!: BookingRequest

  protected get time() {
    return DateHelper.getTimeOffset(this.request.start_timestamp, this.request.end_timestamp).formatTimeOffset('dhm')
  }

  protected get reservation() {
    return null
    // return this.request.reservation ?? null
  }

  protected get statusColor() {
    const s = this.request.status
    switch (s) {
      case 'ACCEPTED':
        return 'success'
      case 'REJECTED':
        return 'error'
      case 'NEW':
      case 'WAITING':
      default:
        return ''
    }
  }

  protected getDate(isStart: boolean) {
    const start = this.request.start
    const end = this.request.end
    const now = moment().toISOString()

    function isSameDay(date1: string, date2: string) {
      return moment(date1).isSame(moment(date2), 'day')
    }

    if (start && end) {
      const timestamp: string = String(isStart ? start : end)
      const m = moment(timestamp)
      if (isSameDay(now, timestamp)) {
        return m.calendar()
      } else {
        return m.format('L') + '\n ' + m.format('LT')
      }
    }
  }

}
