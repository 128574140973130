


































import {Vue, Component} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import Utils from '@/utils'
import {AccountsState} from '@/store/modules/accounts'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Button: Utils.loadComponent('Button'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    GradientCard: Utils.loadComponent('GradientCard'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Img: Utils.loadComponent('proxy/Image'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
  name: 'ProfileSwitcher',
})
export default class ProfileSwitcher extends Vue {
  @State('accounts') public accountsStore!: AccountsState

  @Action('switchAccount') public switchAccount!: (id: string) => Promise<void>
  @Action('removeAccount') public removeAccount!: (id: string) => Promise<void>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Getter('hasSavedAccounts') public hasSavedAccounts!: boolean

  protected loading: boolean = false

  protected get accounts() {
    return this.accountsStore
  }

  protected switcheroo(id: string) {
    this.loading = true
    this.switchAccount(id).then(() => {
      this.$emit('profile-changed')
    })
  }

  protected removeProfile(id: string) {
    // rimuovi account dalla lista
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      imageState: 'error.svg',
      confirmText: this.$t('common.confirm'),
      confirmColor: 'error',
      cancelText: this.$t('common.cancel'),
      code: '',
      title: this.$t('profile.switch.delete.title'),
      subtitle: this.$isAvailable('profile.switch.delete.subtitle'),
      showCloseButton: false,
      cancelCallback: () => {
        //
      },
      confirmCallback: () => {
        this.removeAccount(id).then(() => {
          this.$router.replace({name: 'profile'})
        })
      },
    }))

  }
}
