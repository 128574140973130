












































import {Component} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Button from './Button.vue'
import ConfirmDialogCallback from '../views/ConfirmDialogCallback.vue'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'
import AddDriver from '@/components/AddDriver.vue'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Divider: Utils.loadComponent('proxy/Divider'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Img: Utils.loadComponent('proxy/Image'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'AddClient',
})
export default class AddClient extends AddDriver {
  @Action('inviteClient') protected inviteClient: any

  protected get hasLongHelp() {
    return this.$isAvailable('profile.invite.help_long')
  }

  protected getToken() {
    return sdk.profile.get_invite_token().then((r) => r.data.driver_token).catch(() => null)
  }

  protected save() {
    if (this.valid) {
      this.loading = true
      this.inviteClient(this.inviteRequest).then(this.confirmInvite).finally(() => {
        this.loading = false
      })
    }
  }

  protected confirmInvite() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$t('common.great'),
      subtitle: this.$t('profile.invite_sent'),
      confirmText: this.$t('common.continue'),
      imageState: 'icons/invitedriver.svg',
      singleAction: true,
      confirmCallback: this.closeDialog,
    }))
  }
}
