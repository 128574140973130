












import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import Card from '@/components/proxy/Card/Card.vue'
import Layout from '@/components/proxy/Layout.vue'
import Icon from '@/components/proxy/Icon.vue'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'
import {dataURLToBlob} from '@/lib/BlobHelper'

@Component({
  components: {
    ProgressCircular,
    TextField,
    Icon,
    Layout,
    Card,
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'DocumentUploadDialog',
})
export default class DocumentUploadDialog extends Vue {
  @Action('closeDialog') public closeDialog!: () => void

  @Prop({type: Function}) public cancelCallback!: () => void
  @Prop({type: Function}) public successCallback!: (document: Blob, fileName?: string) => void
  @Prop({type: Boolean, default: true}) public showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) public closeOnCallback!: boolean
  @Prop({type: Object, required: true}) public cameraOptions!: CameraOptions

  protected loading: boolean = false
  protected fileName: string | null = null

  protected onPickFile() {
    const input = this.$refs.fileInput as HTMLElement
    input.click()
  }

  protected onFilePicked(inputEvent: Event) {
    const files = (inputEvent.target as HTMLInputElement)?.files
    if (files?.length) {
      this.loading = true
      const reader = new FileReader()
      reader.onload = () => {
        this.fileName = files[0].name
        if (reader.result && typeof reader.result === 'string') {
          this.loading = false
          this.gotDocument(reader.result)
        }
      }
      reader.readAsDataURL(files[0])
    }
  }

  private gotDocument(r: string) {
    this.successCallback(dataURLToBlob(r), this.fileName || undefined)
    this.closeDialog()
  }
}
