












































import {Component, Prop, Watch} from 'vue-property-decorator'

import {VBtn, VLayout, VProgressCircular, VProgressLinear} from 'vuetify/lib'
import {ValidationObserver} from 'vee-validate'
import Flow from '@/components/flow/Flow.vue'
import Utils from '@/utils'
import AlreadyHasAccount from '@/views/AlreadyHasAccount.vue'
import {AxiosError} from 'axios'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    VProgressLinear,
    VBtn,
    VProgressCircular,
    ValidationObserver,
    VLayout,
    Sheet: Utils.loadComponent('proxy/Sheet'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    FlowStep: Utils.loadComponent('flow/FlowStep'),
  },
})
export default class FlowTest extends Flow {
  @Prop({type: Number, default: 0}) public readonly page!: number

  @Watch('page')
  public onExtStepChange(step: number) {
    if (this.step !== step) {
      this.step = step
    }
  }

  @Watch('step')
  public onStepChange(step: number) {
    this.$router.replace({params: {step: step.toString()}}).catch((e) => e)
    this.$emit('stepChange', step)
  }

  public stepper(val: number, validate?: () => Promise<boolean>) {
    const startId = this.currentStep.id
    const steppr = () => {
      const step = () => {
        let pointer = this.steps.findIndex((s) => s.id === startId)
        let i = val
        while (i !== 0) {
          const newPointer = pointer + Math.sign(i) // decrease/increase pointer by 1
          if (newPointer < 0) {
            this.exitBack()
            return
          } else if (newPointer === this.steps.length) {
            this.exitForward()
            return
          }
          i = i - Math.sign(i)
          pointer = newPointer
        }
        this.step = Math.min(Math.max(pointer, 0), this.steps.length) // clamp value between 0 and steps length
      }

      if (val > 0) {
        this.evaluateOutput()
          .then(step)
          .catch((e: AxiosError) => {
            if (e.response?.data?.result_code === 'exceptions.system.already-has-account-exception') {
              this.openDialog(new Dialog(AlreadyHasAccount, {err: (e.response as any).data}, null, false, null))
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        step()
      }
    }

    if (validate === undefined) {
      steppr()
    } else {
      validate().then((v) => {
        if (v) {
          steppr()
        }
      })
    }
  }

  public sendOutput(success: () => void, failure: () => void, endpoint: string, payload: any) {
    const doStuffWithSuccess = () => {this.$log('send output success', 0)}
    const doStuffWithFailure = (e: AxiosError) => {this.$log(e.message, 3)}
    const stopLoading = () => {
      this.loading = false
    }
    this.loading = true

    this.send({endpoint, payload})
      .then(doStuffWithSuccess)
      .finally(stopLoading)
      .then(success)
      .catch((e: AxiosError) => {
        doStuffWithFailure(e)
        return failure()
      })
  }

  public init() {
    const flow = this.flowName
    if (flow) {
      this.initialLoading = this.flowInputs[flow]?.loading_message || ''
      const steps = this.flowInputs[flow]?.steps as FlowInputStep[] || []
      this.step = steps.findIndex((s) => !s.skip)
      this.initialLoading = null
    }
  }
}
