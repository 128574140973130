












import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {CheckItem} from '@/lib/kepler/interfaces'
import MultipleDialogCallback from '@/views/MultipleDialogCallback.vue'

interface ChecklistOption {
  title?: string
  text: string
  icon?: string
  value: string | number
  color?: string
}

@Component({
  components: {
    Checklist: Utils.loadComponent('Checklist'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'ChecklistDialogCallback',
})
export default class ChecklistDialogCallback extends MultipleDialogCallback {
  @Prop({
    type: Array,
    required: true,
    validator(options: any): boolean {
      return options.every((o: any) => {
        return typeof o.text === 'string' && (typeof o.value === 'string' || typeof o.value === 'number')
      })
    },
  }) declare protected options: ChecklistOption[]
  @Prop({type: String}) private confirmText!: string
  @Prop({type: String}) private confirmLabel!: string
  @Prop({type: String, default: 'success'}) private confirmColor!: string
  @Prop({type: Boolean, default: false}) private allRequired!: boolean

  private checkListValues: Record<string, boolean> = {}

  private get checklist() {
    return this.options.map((opt) => {
      const {text, value, ...rest} = opt
      const obj: Partial<CheckItem> = rest
      obj.description = obj.description || text
      obj.id = obj.id || String(value)
      return obj as CheckItem
    })
  }

  private get valid() {
    return this.allRequired ? Object.values(this.checkListValues).every((v) => v) : true
  }

  private setChecklist(v: Record<string, boolean>) {
    this.checkListValues = v
  }

  private confirmAndClose(callback?: (r?: string[]) => void) {
    this.loading = true
    if (this.closeOnCallback) {
      this.closeDialog()
    }
    if (callback !== undefined) {
      const isString = (x: any): x is string => typeof x === 'string'
      callback(Object.entries(this.checkListValues).map(([k, v]) => v ? k : null).filter(isString))
    }
  }
}
