








































































import {Component, Prop} from 'vue-property-decorator'
import Login from '@/views/Login.vue'
import {LoginResponse, Popup, Dialog} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import ResetPasswordView from '@/views/ResetPassword.vue'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CloseButton: Utils.loadComponent('CloseButton'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
  },
  name: 'LoginDialog',
})
export default class LoginDialog extends Login {
  @Prop({type: Function}) protected callback!: (p: LoginResponse) => void
  @Prop({type: Boolean, default: false}) protected leaveOpen!: boolean
  protected dialog = true

  protected loginRequest() {
    if ((this.$refs as any).loginForm.validate()) {
      this.loading = true
      sdk.people.login(this.credentials).then((r) => {
        this.callback(r.data)
        if (!this.leaveOpen) {
          this.closeDialog()
        }
      }).catch(() => {
        // this.valid = false
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected checkSMS(title?: string) {
    this.openDialog(new Dialog(PhoneVerificationView, {
      mobileNumber: this.phone, isDialog: true, quick: false, title, callback: () => {
        if (!this.leaveOpen) {
          this.closeDialog()
        }
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      },
    }))
  }

  protected openResetPasswordView() {
    this.closeDialog()
    this.openPopup(new Popup(ResetPasswordView, null, this.$t('login.reset_your_password'), false))
  }

  protected mounted() {
    this.$smoothReflow?.({
      property: ['height'],
      el: 'div.login-dialog',
      hideOverflow: true,
      transitionEvent: {
        selector: '.layout',
      },
    })
  }
}
