














import {Component, Vue} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {TopbarButton} from '@/store/modules/topbar'
import HistoryBookingDetail from './HistoryBookingDetail.vue'
import {Popup, ReservationResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import DateHelper from '@/lib/DateHelper'

@Component({
  components: {
    EmptyList: Utils.loadComponent('EmptyList'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CardReservation: Utils.loadComponent('entities/reservation/CardReservation'),
  },
})
export default class UpcomingListHistory extends Vue {
  @State((state) => state.booking.futureReservations) public future!: ReservationResponse[]
  @State((state) => state.booking.activeReservations) public active!: ReservationResponse[]
  @Action('futureReservations') public futureReservations!: () => Promise<void>
  @Action('refreshHistory') public refreshHistory!: () => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void

  protected isLoading: boolean = false

  protected mounted() {
    const buttons = [{
      icon: 'mdi-refresh',
      action: () => {
        this.reload()
      },
    },
    ]
    this.setActionButtons(buttons)
  }

  protected reload() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true
      this.futureReservations().then(() => {
        this.isLoading = false
        resolve()
      }).catch(reject)
    })
  }

  protected isActive(reservation: ReservationResponse): boolean {
    const active = this.active.find((activeRes) => activeRes.id === reservation.id)

    // checks for closed FF reservations, pretty much redundant but meh.
    if (reservation.type === 'FF' && reservation.status === 'CONFIRMED' && !active) {
      this.reload().then(() => active)
    }
    // reservation is in active reservations
    return !!active
  }

  protected openDetail(reservation: ReservationResponse) {
    const now = DateHelper.getTimestamp()
    const start = reservation.start_timestamp || 0
    const end = reservation.end_timestamp || 0
    let current: boolean = false
    if (start && end) {
      current = now > start && end >= now
    }
    if (!this.isActive(reservation) && !current) {
      this.openPopup(new Popup(
        HistoryBookingDetail,
        {state: 'future', resNum: reservation.number},
        this.$t('booking.reservation', {number: reservation.number}),
        ),
      )
    } else {
      this.$router.push({name: 'reservation', params: {id: reservation.id}})
    }
  }
}
