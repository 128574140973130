

























import {Vue, Component, Prop} from 'vue-property-decorator'
import {VContainer, VLayout, VTextField, VBtn, VIcon, VProgressCircular, VImg} from 'vuetify/lib'
import {Dialog} from '@/lib/kepler/interfaces'
import CameraStorageDialog from '@/views/uploader_flow/CameraStorageDialog.vue'
import {Action} from 'vuex-class'
import CloseButton from '@/components/CloseButton.vue'

class Sizer {
  public height: number
  public width: number
  public ratio: number
  public vertical: boolean
  public minSize: number
  public maxSize: number

  constructor(w: number, h: number) {
    this.width = w
    this.height = h
    this.ratio = w / h
    this.vertical = h > w
    this.minSize = Math.min(w, h)
    this.maxSize = Math.max(w, h)
  }
}

class CameraDefaultOptions implements CameraOptions {
  public allowEdit = false
  public cameraDirection = 0
  public correctOrientation = true
  public destinationType = 0
  public encodingType = 0
  public mediaType = 0
  public popoverOptions = undefined
  public quality = 80
  public saveToPhotoAlbum = false
  public sourceType = 1
  public targetHeight = -1
  public targetWidth = -1
}

@Component({
  components: {CloseButton, VIcon, VBtn, VContainer, VLayout, VTextField, VProgressCircular, VImg},
  name: 'BrowserCamera',
})
export default class BrowserCamera extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop({
    type: String || null,
    default: () => null,
  }) protected readonly placeholder!: string | null
  @Prop({
    type: Object,
    default: () => new CameraDefaultOptions(),
  }) protected readonly options!: CameraOptions
  @Prop({
    type: Boolean || String,
    default: () => false,
  }) protected readonly skipUpload!: boolean | string
  @Prop({
    type: String,
    default: () => '',
  }) protected readonly mask!: string

  protected localMediaStream: MediaStream | null = null
  protected fileUploadFlow: boolean = false
  protected loading: boolean = true

  protected fileName: string | null = null

  protected get hasStream() {
    return !!this.localMediaStream?.getTracks().length
  }

  protected takePicture() {
    return new Promise((resolve: (imageData: string) => void) => {
      // create a canvas and capture a frame from video stream
      const canvas = document.createElement('canvas')

      const video = this.$refs.video as HTMLVideoElement

      const camera = new Sizer(video.videoWidth, video.videoHeight)

      // TODO: implement more robust resizing
      // const target = new Sizer(this.options.targetWidth!, this.options.targetHeight!)
      // let w = target.width
      // let h = target.height

      let w
      let h

      if (camera.vertical) {
        h = camera.maxSize
        w = Math.floor((camera.width / camera.height) * h)
      } else {
        w = camera.maxSize
        h = Math.floor((camera.height / camera.width) * w)
      }

      canvas.width = w
      canvas.height = h

      const context2d = canvas.getContext('2d')
      if (context2d) {
        context2d.drawImage(video, 0, 0, w, h)
        // converts image stored in canvas to base64 encoded image
        const imageData = canvas.toDataURL('image/jpg')

        resolve(imageData)
      }
    }).then((result) => {
      this.success(result)
    }).catch(() => {
      this.startUploadFlow()
    })
  }

  protected prepCamera() {
    const mediaDevices = navigator.mediaDevices
    if (!mediaDevices) {
      return Promise.reject('no media devices available')
    }

    const video = this.$refs.video as HTMLVideoElement
    video.width = this.options.targetWidth!
    video.height = this.options.targetHeight!

    let facingMode = 'environment'
    if (this.options.cameraDirection === 1) {
      facingMode = 'user'
    }

    const constraints = {
      video: {
        width: this.options.targetWidth,
        height: this.options.targetHeight,
        facingMode,
      }, audio: false,
    }

    return mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        const global = window as unknown as { cameraStream: undefined | MediaStream } & Window
        global.cameraStream = stream
        this.$set(this, 'localMediaStream', stream)
        if ('srcObject' in video) {
          video.srcObject = this.localMediaStream
        }
        video.setAttribute('playsinline', 'true')
        video.play()
      })
      .finally(() => {
        this.loading = false
      })
  }

  // file upload methods, skippable if we use cropper as a file input
  protected onPickFile() {
    const input = this.$refs.fileInput as HTMLElement
    input.click()
  }

  protected onFilePicked(inputEvent: Event) {
    this.loading = true
    const reader = new FileReader() /* eslint no-undef : 0 */
    reader.onload = (readerEvent) => {
      // it used to hide button here, perharps a loading indicator would be nice

      if (readerEvent.target && typeof readerEvent.target.result === 'string') {
        const imageData = readerEvent.target.result
        this.loading = false
        this.success(imageData.substring(imageData.indexOf(',') + 1))
      }
    }
    if (inputEvent.target) {
      reader.readAsDataURL((inputEvent.target as any).files[0]) // shady shit here
    }
  }

  protected success(data?: string) {
    this.$emit('gotPhoto', data || '')
  }

  protected startUploadFlow() {
    if (this.skipUpload) {
      this.success()
    } else {
      this.closeDialog()
      this.openDialog(new Dialog(CameraStorageDialog, {
        cameraOptions: this.options,
        successCallback: (r: string) => {
          const img1 = new Image()
          img1.src = r
          this.success(r)
        },
      }))
    }
  }

  protected mounted() {
    const sourceFromCamera = this.options.sourceType === 1
    if (sourceFromCamera) {
      this.prepCamera().catch((e) => {
        this.$log(`prepCamera Error | ${e}`, 2)
        this.startUploadFlow()
      })
    } else {
      this.startUploadFlow()
    }
  }

  protected stopTracks() {
    const global = window as unknown as { cameraStream: undefined | MediaStream } & Window
    const stream = global.cameraStream || this.localMediaStream
    if (stream) {
      stream.getVideoTracks()[0].stop()
      stream.getTracks().forEach((track) => {
        track.stop()
      })
      delete global.cameraStream
    }
  }

  protected beforeDestroy() {
    this.stopTracks()
  }
}
