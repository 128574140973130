








































import {Component, Prop, Vue} from 'vue-property-decorator'
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VContainer,
  VDialog,
  VDivider,
  VIcon,
  VLayout,
  VTabItem,
  VTabsItems,
  VImg,
  VSpacer,
} from 'vuetify/lib'
import {ValidationObserver} from 'vee-validate'
import Layout from '@/components/proxy/Layout.vue'
import {FlowInputField, FlowInputStep, FlowInputValidation} from '@/lib/kepler/interfaces'
import FlowField from '@/components/flow/FlowField.vue'
import Button from '@/components/Button.vue'
import {State} from 'vuex-class'
import CloseButton from '@/components/CloseButton.vue'

@Component({
  components: {
    CloseButton,
    Button,
    FlowField,
    Layout,
    VIcon,
    VContainer,
    VDialog,
    VCardText,
    VCardTitle,
    VCard,
    VCardActions,
    VLayout,
    VDivider,
    ValidationObserver,
    VTabsItems,
    VTabItem,
    VBtn,
    VImg,
    VSpacer,
  },
  name: 'RegistrationDocumentAddDialog',
})
export default class RegistrationDocumentAddDialog extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Prop({
    type: Object,
    required: true,
  }) protected doc!: FlowInputStep
  @Prop({
    type: String || null,
    default: () => null,
  }) protected flowName!: string | null
  @Prop({
    type: Function,
  }) protected successCallback!: () => void
  @Prop({
    type: Function,
  }) protected cancelCallback!: () => void

  protected get attachmentFields() {
    return this.doc?.fields?.filter((f) => {
      return f.type === 'image'
    })
  }

  protected get docFields() {
    return this.doc?.fields?.filter((f) => {
      return f.type !== 'image'
    })
  }

  protected get output() {
    const atts = this.attachmentFields
    if (this.flowOutputs && this.flowName && this.doc?.id) {
      const docs = this.flowOutputs[this.flowName]?.documents
      if (docs) {
        const doc = docs[this.doc.id]
        if (doc) {
          const outputAtts = doc.attachments
          return atts?.map((att) => {
            return Object.keys(outputAtts).includes(att.name)
          })
        }
      }
    }
    return atts?.map(() => true)
  }

  protected saveAndClose(validate: () => Promise<boolean>) {
    validate().then((pass) => {
      if (pass) {
        this.successCallback()
      }
    })
    // TODO: something resets those fields on save
  }

  protected getMask(f: FlowInputField) {
    const v = f.validation
    let result
    if (v && (v.includes('mask') || (v as FlowInputValidation).mask)) {
      if (typeof v === 'string') {
        v.split('|').find((rule) => {
          const maskRegex = /mask:(.+)/
          const m = rule.match(maskRegex)
          if (m) {
            result = m[1]
          }
        })
      } else {
        result = v.mask
      }
    }
    return result
  }

  protected viewHasTranslation(view_name: string) {
    const str = view_name
      .replace('picture-', '')
    const trn = this.$isAvailable(
      `registration.driver_document.attachments_${str.replace(' ', '_')}`,
    )
    return trn || str
  }

}
