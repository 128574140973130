









import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {
  Popup,
  Dialog,
  BookTimeSelectRequest,
  ReservationResponse,
  ReservationEditRequest,
  AvailabilityRequest,
  AvailabilityResponse,
  ReservationExtendRequest,
  ExtraActionRequest,
  ExtraActionResponse,
} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import DateHelper from '@/lib/DateHelper'

import QuickExtendDialog from '@/components/QuickExtendDialog.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import BookingEdit from '@/views/Booking/BookingEdit.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import FuelPinDialog from '@/views/FuelPinDialog.vue'
import ReservationHelper from '@/lib/reservation'

interface BookingActionButton {
  clickAction: () => void,
  text: string
}

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingActions',
})

export default class BookingActions extends Vue {
  @State((state) => state.configuration.appConfig.parking_report_id) public parkingReportId!: string | null

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void

  @Action('editReservation') public editReservation!: (p: ReservationEditRequest) => Promise<ReservationResponse>
  @Action('deleteReservation') public deleteReservation!: (p: ReservationResponse) => Promise<void>
  @Action('vehicleAvailability') public vehicleAvailability!: (p: AvailabilityRequest) => Promise<AvailabilityResponse[]>
  @Action('extendReservation') public extendReservation!: (p: ReservationExtendRequest) => Promise<ReservationResponse>
  @Action('sendExtraAction') public sendExtraAction!: (p: ExtraActionRequest) => Promise<ExtraActionResponse>
  @Action('current') public current!: () => Promise<ReservationResponse[]>
  @Action('futureReservations') public futureReservations!: () => Promise<void>

  @Prop({type: Object, required: true}) public readonly reservation!: ReservationResponse

  public loading: boolean = false
  public availability: AvailabilityResponse[] = []

  protected get bookingActions() {
    return this.reservation.extra?.actions || null
  }

  protected get buttons() {
    const arr: BookingActionButton[] = []
    const list: Record<string, () => void> = {
      extend: this.extendAction,
      edit: this.edit,
      cancel: this.cancelReservation,
      refuel: this.getFuelPin,
      report_unavailable_parking: this.reportParkUnavailable,
    }

    const textString = (action: string): string => {
      const text = this.$isAvailable('booking.action.' + action) || null
      const nestedText = this.$isAvailable('booking.action.' + action + '.action') || null
      return text || nestedText || action
    }

    this.bookingActions?.forEach((operation) => {
      const extraAction = () => this.sendExtra(operation)
      const text = textString(operation)
      const clickAction = !!list[operation] ? list[operation] : extraAction
      arr.push({text, clickAction})
    })
    return arr
  }

  // actions
  protected getFuelPin() {
    let text = this.$t('vehicle.refuel.not_necessary')
    let callback: (() => void) | undefined
    if (this.reservation.vehicle_slot.vehicle.fuel_level < 30) {
      text = this.$t('vehicle.refuel.necessary')
      callback = () => {
        this.closeDialog()
        this.openPopup(new Popup(VehicleAddReport, {
          vehicleSlot: this.reservation.vehicle_slot,
          vehicle: this.reservation.vehicle_slot.vehicle,
        }, this.$t('vehicle.report.diary_report')))
      }
    }

    this.loading = true
    this.$store.dispatch('getFuelPin', this.reservation.number).then(
      (r) => {
        this.openDialog(new Dialog(FuelPinDialog, {
          imageState: 'success.svg',
          confirmText: this.$t('action.close'),
          pin: r.fuel_card_pin,
          odometer: r.vehicle_odometer,
          callback,
          data: text,
          singleAction: true,
          emitConfirm: false,
        }))
      },
    ).finally(() => {
      this.loading = false
    })
  }

  protected reportParkUnavailable() {
    this.openPopup(new Popup(VehicleAddReport, {
      vehicleSlot: this.reservation.vehicle_slot,
      vehicle: this.reservation.vehicle_slot.vehicle,
      reservation: this.reservation,
      reportType: this.parkingReportId,
      description: this.$t('vehicle.report.park_occupied'),
    }, this.$t('vehicle.report.diary_report')))
  }

  protected extendAction() {
    this.loading = true
    this.getAvailability()
      .then(this.openExtend)
      .finally(() => {
        this.loading = false
      })
  }

  protected getAvailability(): Promise<void> {
    const reservation = this.reservation
    if (reservation.end) {
      return this.vehicleAvailability({
        vehicle_id: reservation.vehicle_slot.vehicle.id,
        start: DateHelper.parse(reservation.end).unix(),
        end: DateHelper.parse(reservation.end).add(2, 'days').unix(),
      }).then((availability: AvailabilityResponse[]) => {
        availability[0].selected = true
        this.availability = availability
      })
    }
    return Promise.reject('no start or end dates in selected reservation')
  }

  protected sendExtra(operation: string) {
    const reservationNumber = this.reservation.number
    this.loading = true
    this.sendExtraAction({reservationNumber, operation}).then((r) => {
      if (r.result) {
        this.openDialog(new Dialog(ConfirmDialog, {
          showCloseButton: true,
          imageState: '',
          code: '',
          title: r.operation,
          subtitle: r.result,
          singleAction: true,
        }))
      }
    }).finally(() => {
      this.loading = false
    })
  }

  protected edit() {
    // TODO: i hate how it's modifying the prop, need to find a better alternative
    const reservation = this.reservation
    this.openDialog(new Dialog(BookingEdit, {
      reservation,
      confirmCallback: () => {
        this.loading = true
        const start = DateHelper.parse(reservation.start!).unix()
        const end = DateHelper.parse(reservation.end!).unix()

        const startUx = reservation.start_timestamp
        const endUx = reservation.end_timestamp

        if ((startUx && endUx) && ((start !== startUx) || (end !== endUx))) {
          this.editReservation({
            reservation_number: reservation.number,
            start: startUx,
            end: endUx,
          })
            .then((res) => {
              const isFuture = ReservationHelper.isFuture(res)
              const isCurrent = ReservationHelper.isCurrent(res)
              if (isFuture) {
                this.futureReservations().then(this.confirmEdit)
              } else if (isCurrent) {
                this.current().then(this.confirmEdit)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      cancelCallback: () => {
        reservation.start_timestamp = DateHelper.parse(reservation.start!).unix()
        reservation.end_timestamp = DateHelper.parse(reservation.end!).unix()
      },
    }))
  }

  protected confirmEdit() {
    this.closeDialog()
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'success.svg',
      confirmText: this.$t('common.ok'),
      code: '',
      subtitle: '',
      title: this.$t('booking.action.edit_confirm'),
      singleAction: true,
      emitConfirm: false,
    }))
  }

  protected openExtend() {
    this.openDialog(new Dialog(QuickExtendDialog, {
      reservation: this.reservation,
      availability: this.availability,
      onConfirm: this.confirmEdit,
    }, null, false, 'quickBookingDateSelected'))
  }

  protected extend(obj: BookTimeSelectRequest) {
    if (obj.end === null) {
      return
    }
    this.loading = true
    this.extendReservation({
      reservation_number: this.reservation.number,
      end: obj.end,
    }).then((r: ReservationResponse) => {
      this.closeDialog()
      this.reservation.end = r.end
      this.getAvailability()
      this.confirmEdit()
    }).finally(() => {
      this.loading = false
    })
  }

  protected cancelReservation() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$t('booking.action.cancel_confirm'),
      subtitle: '',
      confirmText: this.$t('booking.action.cancel'),
      cancelText: this.$t('booking.action.keep'),
      confirmColor: 'error',
      imageState: 'warn.svg',
      confirmCallback: () => {
        this.loading = true
        this.deleteReservation(this.reservation).then(() => {
          this.closePopup()
        }).finally(() => {
          this.loading = false
        })
      },
      cancelCallback: () => {
        return 1
      },
    }))
  }

  protected get vehicleTypeColor() {
    const vs = this.reservation.vehicle_slot
    return `${this.reservation.type}${vs.vehicle.category.type}`.toUpperCase()
  }
}
